import * as React from "react";
import { SVGProps } from "react";

const SvgIconLink = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={17}
        height={16}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M7.99992 4.66634L10.2115 2.41708C11.3758 1.2329 13.2822 1.22484 14.4565 2.39914V2.39914C15.6238 3.56647 15.6238 5.45908 14.4565 6.6264L12.5139 8.56898C11.094 9.98889 8.80551 10.0319 7.33325 8.66634V8.66634"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.90364 11.8747L6.69209 14.1239C5.52775 15.3081 3.62135 15.3162 2.44705 14.1419V14.1419C1.27972 12.9745 1.27972 11.0819 2.44705 9.91462L4.38962 7.97204C5.80954 6.55213 8.09805 6.50912 9.57031 7.87467V7.87467"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconLink;
