import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { AuthAppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { AuthMain as Main } from "@components/Main";
import { ModalContainer } from "@components/Modal";
import SvgIconLockUnlocked from "@components/svg/IconLockUnlocked";
import { Text } from "@components/Text";
import { AppPath } from "@pages/paths";
import { AuthActions, isLoggedIn } from "@redux/authSlice";
import { RootState } from "@redux/store";
import { Validator, ValidatorMessage } from "@utils/Validator";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router-dom";

interface State {
    email: string;
    emailError: ValidatorMessage | null;
    password: string;
    passwordError: ValidatorMessage | null;
    isSubmitted: boolean;
}

class LoginPageComponent extends React.Component<ReduxProps & WithLoctoolProps, State> {
    public state: State = {
        email: "",
        emailError: null,
        password: "",
        passwordError: null,
        isSubmitted: false,
    };

    private readonly onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            email: e.currentTarget.value,
            emailError: Validator.validateEmail(e.currentTarget.value),
        });
    };

    private readonly onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            password: e.currentTarget.value,
            passwordError: Validator.required(e.currentTarget.value),
        });
    };

    private readonly validateAll = () => {
        const emailError = Validator.validateEmail(this.state.email);
        const passwordError = Validator.required(this.state.password);

        this.setState({
            emailError,
            passwordError,
        });

        return !emailError && !passwordError;
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.setState({ isSubmitted: true });

        if (!this.validateAll()) {
            return;
        }

        this.props.login({
            email: this.state.email,
            password: this.state.password,
        });
    };

    public render(): React.ReactElement {
        const { email, password, emailError, passwordError, isSubmitted } = this.state;
        const { isLoading, Loctool } = this.props;

        if (this.props.isLoggedIn) {
            return <Redirect to={AppPath.Home} />;
        }

        return (
            <>
                <AuthAppBar />
                <Main>
                    <ModalContainer $style={{ padding: 50, boxShadow: "none" }}>
                        <Text as="h2" variant="displayXSmall" $style={{ marginBottom: 35, textAlign: "center" }}>
                            <LoctoolMessage id="components.loginPage.title" />
                        </Text>
                        <form onSubmit={this.onSubmit}>
                            <Input
                                type="email"
                                label={Loctool.formatMessage({ id: "components.loginPage.form.email" })}
                                value={email}
                                onChange={this.onEmailChange}
                                error={
                                    isSubmitted && emailError
                                        ? Loctool.formatMessage({ id: `error.validation.${emailError}` })
                                        : false
                                }
                            />
                            <Input
                                type="password"
                                label={Loctool.formatMessage({ id: "components.loginPage.form.password" })}
                                value={password}
                                onChange={this.onPasswordChange}
                                error={
                                    isSubmitted && passwordError
                                        ? Loctool.formatMessage({ id: `error.validation.${passwordError}` })
                                        : false
                                }
                                $style={{ marginTop: 24 }}
                            />
                            <Button.Primary
                                type="submit"
                                label={Loctool.formatMessage({ id: "components.loginPage.login" })}
                                loading={isLoading}
                                disabled={!email || !password}
                                fullWidth
                                $style={{ marginTop: 24 }}
                            />
                        </form>
                        <Button.Text
                            href={AppPath.InitiateRecovery}
                            label={Loctool.formatMessage({ id: "components.loginPage.recoverMyAccount" })}
                            startIcon={<SvgIconLockUnlocked width={16} height={16} />}
                            $style={{ marginTop: 24 }}
                            fullWidth
                        />
                    </ModalContainer>
                </Main>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
    isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
    login: AuthActions.login,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const LoginPage = withLoctool(connector(LoginPageComponent));
