import { Api } from "@api/Api";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { NewAlert } from "@components/Alert/NewAlert";
import { AuthAppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Input } from "@components/Input";
import { AuthMain as Main } from "@components/Main";
import { ModalContainer } from "@components/Modal";
import { Text } from "@components/Text";
import { AppPath } from "@pages/paths";
import { AlertActions } from "@redux/alertSlice";
import { AuthActions, isLoggedIn } from "@redux/authSlice";
import { RootState } from "@redux/store";
import { Validator, ValidatorMessage } from "@utils/Validator";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

type Props = {} & RouteComponentProps<{
    emailWithToken?: string;
}> &
    ReduxProps &
    WithLoctoolProps;

interface State {
    email: string;
    emailError: ValidatorMessage | null;
    password: string;
    passwordError: ValidatorMessage | null;
    token: string;
    isTokenValid: boolean;
    isLoading: boolean;
    isSubmitted: boolean;
    emailSent: boolean;
}

class AccountRecoveryPageComponent extends React.Component<Props, State> {
    public state: State = {
        email: "",
        emailError: null,
        password: "",
        passwordError: null,
        token: "",
        isTokenValid: false,
        isLoading: false,
        isSubmitted: false,
        emailSent: false,
    };

    public componentDidMount() {
        const { emailWithToken } = this.props.match.params;
        const Loctool = this.props.Loctool;

        if (!emailWithToken) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            const lastColonIndex = emailWithToken.lastIndexOf(":");
            const email = decodeURIComponent(emailWithToken.slice(0, lastColonIndex) ?? "");
            const token = emailWithToken.slice(lastColonIndex + 1);
            let isTokenValid = this.state.isTokenValid;

            try {
                isTokenValid = await Api.checkAccountRecoveryTokenValidity(email, token);
            } catch (error) {
                isTokenValid = false;
            } finally {
                this.setState({ email, token, isTokenValid, isLoading: false });
                if (token && !isTokenValid) {
                    this.props.alertInfo({
                        message: Loctool.formatMessage({ id: "components.accountRecoveryPage.expiredToken" }),
                    });
                }
            }
        });
    }

    private onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            email: e.currentTarget.value,
            emailError: Validator.validateEmail(e.currentTarget.value),
        });
    };

    private onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: e.currentTarget.value,
            passwordError: Validator.validatePassword(e.currentTarget.value),
        });
    };

    private initRecovery = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const emailError = Validator.validateEmail(this.state.email);

        if (emailError) {
            this.setState({ isSubmitted: true, emailError });
            return;
        }

        this.setState({ isLoading: true, isSubmitted: true, emailError }, async () => {
            try {
                await Api.initiateAccountRecovery(this.state.email);
                this.setState({ isLoading: false, emailSent: true });
            } catch (error) {
                this.props.alertParseError(error as Error);
            }
        });
    };

    private finishRecovery = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const passwordError = Validator.validatePassword(this.state.password);

        if (passwordError) {
            this.setState({ isSubmitted: true, passwordError });
            return;
        }

        this.setState({ isLoading: true, isSubmitted: true, passwordError }, async () => {
            try {
                const { email, password, token } = this.state;
                await Api.finishAccountRecovery(email, token, password);
                this.props.login({ email, password });
            } catch (error) {
                this.props.alertParseError(error as Error);
            }
        });
    };

    private renderInitRecoveryForm = () => {
        const { isLoading, isSubmitted, emailSent, email, emailError } = this.state;
        const { Loctool } = this.props;

        return emailSent ? (
            <>
                <NewAlert type="success" message={<LoctoolMessage id="components.accountRecoveryPage.emailSent" />} />
                <Button.Primary
                    label={Loctool.formatMessage({ id: "common.close" })}
                    href={AppPath.Login}
                    $style={{ marginTop: 34 }}
                    fullWidth
                />
            </>
        ) : (
            <form onSubmit={this.initRecovery}>
                <Text as="p" variant="textXSmall" $style={{ marginBottom: 34, textAlign: "center" }}>
                    <LoctoolMessage id="components.accountRecoveryPage.lead" />
                </Text>
                <Input
                    type="email"
                    formNoValidate
                    label={Loctool.formatMessage({ id: "components.accountRecoveryPage.form.email" })}
                    value={email}
                    onChange={this.onEmailChange}
                    error={
                        isSubmitted && emailError
                            ? Loctool.formatMessage({ id: `error.validation.${emailError}` })
                            : false
                    }
                />
                <Element $style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 10, marginTop: 24 }}>
                    <Button.Primary
                        type="submit"
                        label={Loctool.formatMessage({ id: "components.accountRecoveryPage.submit" })}
                        loading={isLoading}
                        disabled={!email}
                        fullWidth
                    />
                    <Button.Secondary
                        label={Loctool.formatMessage({ id: "common.cancel" })}
                        href={AppPath.Login}
                        fullWidth
                    />
                </Element>
            </form>
        );
    };

    private renderFinishRecoveryForm = () => {
        const { isLoading, isSubmitted, email, password, passwordError } = this.state;
        const { Loctool } = this.props;

        if (this.props.isLoggedIn) {
            return <Redirect to={AppPath.Home} />;
        }

        return (
            <form onSubmit={this.finishRecovery}>
                <Input
                    type="password"
                    label={Loctool.formatMessage({
                        id: "components.accountRecoveryPage.form.password",
                    })}
                    value={password}
                    onChange={this.onPasswordChange}
                    error={
                        isSubmitted && passwordError
                            ? Loctool.formatMessage({ id: `error.validation.${passwordError}` })
                            : false
                    }
                />
                <Button.Primary
                    type="submit"
                    label={Loctool.formatMessage({ id: "components.accountRecoveryPage.submit" })}
                    loading={isLoading}
                    disabled={!email || !password}
                    fullWidth
                    $style={{ marginTop: 24 }}
                />
            </form>
        );
    };

    public render(): React.ReactElement {
        return (
            <>
                <AuthAppBar />
                <Main>
                    <ModalContainer>
                        <Text as="h2" variant="displayXSmall" $style={{ marginBottom: 34, textAlign: "center" }}>
                            <LoctoolMessage id="components.accountRecoveryPage.title" />
                        </Text>
                        {this.state.isTokenValid ? this.renderFinishRecoveryForm() : this.renderInitRecoveryForm()}
                    </ModalContainer>
                </Main>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = {
    login: AuthActions.login,
    alertParseError: AlertActions.parseError,
    alertInfo: AlertActions.info,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const AccountRecoveryPage = withLoctool(withRouter(connector(AccountRecoveryPageComponent)));
