import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAssetFolder } from "@api/ApiTypes";
import { FolderUtils } from "@utils/FolderUtils";
import { AppThunk, RootState } from "./store";
import { Api } from "@api/Api";
import { AppStateActions } from "./appStateSlice";
import { batch } from "react-redux";
import { AlertActions } from "./alertSlice";

export interface FolderState {
    currentFolder: string | null;
    folders: AnyAssetFolder[];
    sidebarFolders: AnyAssetFolder[];
    isSidebarLoading: boolean;
    breadcrumbs: AnyAssetFolder[];
    isBreadcrumbsLoading: boolean;
}

const initialState: FolderState = {
    currentFolder: FolderUtils.getCurrentFolder(),
    folders: [],
    sidebarFolders: [],
    isSidebarLoading: true,
    breadcrumbs: [],
    isBreadcrumbsLoading: true,
};

const folderSlice = createSlice({
    name: "folders",
    initialState,
    reducers: {
        setCurrentFolder(state, { payload: folder = null }: PayloadAction<string | null | undefined>) {
            state.currentFolder = folder;
        },
        setFolders(state, { payload: folders }: PayloadAction<AnyAssetFolder[]>) {
            state.folders = folders;
        },
        setSidebarFolders(state, { payload: folders }: PayloadAction<AnyAssetFolder[]>) {
            state.sidebarFolders = folders;
        },
        setIsSidebarLoading(state, { payload: isLoading }: PayloadAction<boolean>) {
            state.isSidebarLoading = isLoading;
        },
        setBreadCrumbs(state, { payload: breadCrumbs }: PayloadAction<AnyAssetFolder[]>) {
            state.breadcrumbs = breadCrumbs;
        },
        setIsBreadcrumbsLoading(state, { payload: isLoading }: PayloadAction<boolean>) {
            state.isBreadcrumbsLoading = isLoading;
        },
    },
});

const fetchFolders = (): AppThunk => async (dispatch, getState) => {
    const { currentFolder } = getState().folders;

    dispatch(AppStateActions.toggleLoadingBar(true));

    try {
        if (currentFolder) {
            const response = await Api.getAssetDirectoryById(currentFolder);
            dispatch(folderSlice.actions.setFolders(response.children ?? []));
        } else {
            const response = await Api.getAssetDirectoriesByParentId();
            dispatch(folderSlice.actions.setFolders(response));
        }
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const fetchSidebarFolders = (): AppThunk => async dispatch => {
    dispatch(folderSlice.actions.setIsSidebarLoading(true));

    try {
        const newFolders = await Api.getAssetDirectoriesByParentId();
        dispatch(folderSlice.actions.setSidebarFolders(newFolders));
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(folderSlice.actions.setIsSidebarLoading(false));
    }
};

const fetchSidebarSubFolders = ({ folderId }: { folderId: string }): AppThunk => async (dispatch, getState) => {
    let newFolders: AnyAssetFolder[];
    const { currentFolder, sidebarFolders } = getState().folders;
    dispatch(folderSlice.actions.setIsSidebarLoading(true));

    try {
        const folder = await Api.getAssetDirectoryById(folderId ?? currentFolder!);
        newFolders = nestFolder(sidebarFolders, folder);
        dispatch(folderSlice.actions.setSidebarFolders(newFolders));
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(folderSlice.actions.setIsSidebarLoading(false));
    }
};

const nestFolder = (items: AnyAssetFolder[], findItem: AnyAssetFolder): AnyAssetFolder[] =>
    items.map(item => {
        let children = item.children;
        let name = item.name;

        if (item.id === findItem.id) {
            children = findItem.children;
            name = findItem.name;
        } else if (Array.isArray(item.children)) {
            children = nestFolder(item.children, findItem);
        }

        return {
            ...item,
            name,
            children,
        };
    });

const fetchBreadcrumbs = (id?: string): AppThunk => async dispatch => {
    if (!id) {
        batch(() => {
            dispatch(FolderActions.setBreadCrumbs([]));
            dispatch(FolderActions.setIsBreadcrumbsLoading(false));
        });
        return;
    }

    dispatch(FolderActions.setIsBreadcrumbsLoading(true));

    try {
        const breadcrumbs = await Api.getBreadcrumDataForAssetDirectoryById(id);
        dispatch(FolderActions.setBreadCrumbs(breadcrumbs.reverse()));
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(FolderActions.setIsBreadcrumbsLoading(false));
    }
};

export const FolderActions = {
    ...folderSlice.actions,
    fetchFolders,
    fetchSidebarFolders,
    fetchSidebarSubFolders,
    fetchBreadcrumbs,
};

export const getCurrentFolderName = (state: RootState) =>
    state.folders.breadcrumbs[state.folders.breadcrumbs.length - 1]?.name ?? "home";

export const FolderReducer = folderSlice.reducer;
