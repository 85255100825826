/* eslint-disable prettier/prettier */

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeEN = {
    "common.cancel": null,
    "common.close": "Close",
    "common.delete": null,
    "common.done": "Done",
    "common.loading": "Loading...",
    "common.move": null,
    "common.save": "Save",
    "common.search": null,
    "common.unknown": null,
    "common.upload": "Upload",
    "components.accountRecoveryPage.emailSent": null,
    "components.accountRecoveryPage.expiredToken": null,
    "components.accountRecoveryPage.form.email": null,
    "components.accountRecoveryPage.form.password": null,
    "components.accountRecoveryPage.lead": null,
    "components.accountRecoveryPage.submit": null,
    "components.accountRecoveryPage.title": null,
    "components.actionBar.clearAll": null,
    "components.actionBar.delete": null,
    "components.actionBar.download": null,
    "components.actionBar.insert": null,
    "components.actionBar.move": null,
    "components.actionBar.selectAll": null,
    "components.actionBar.selected": null,
    "components.appBar.closeAppButton": null,
    "components.appBar.createFolderButton": null,
    "components.appBar.menu.changePassword": null,
    "components.appBar.menu.greeting": null,
    "components.appBar.menu.logOut": null,
    "components.appBar.menu.title": null,
    "components.appBar.search": null,
    "components.appBar.searchButton": null,
    "components.appBar.tabs.folders": null,
    "components.appBar.tabs.search": null,
    "components.assetDeleteModal.alert": null,
    "components.assetDeleteModal.deleteSuccess": null,
    "components.assetDeleteModal.deleteSuccessMultiple": null,
    "components.assetDeleteModal.description": null,
    "components.assetDeleteModal.descriptionMultiple": null,
    "components.assetDeleteModal.title": null,
    "components.assetExport.close": null,
    "components.assetExport.download": null,
    "components.assetExport.error": null,
    "components.assetExport.title": null,
    "components.assetExport.waitingForDownload": null,
    "components.assetMoveModal.back": null,
    "components.assetMoveModal.moveButtonLabel": "Move here: {folderName}",
    "components.assetMoveModal.moveSuccess": null,
    "components.assetMoveModal.title": null,
    "components.assetMovePopup.back": "Back to parent folder",
    "components.assetMovePopup.moveButtonLabel": "Move here: {folderName}",
    "components.assetMovePopup.success": "Move successful",
    "components.assetMovePopup.triggerLabel": "Move",
    "components.assetPreviewModal.copy": "Copy URL",
    "components.assetPreviewModal.copyError": "Failed to copy",
    "components.assetPreviewModal.copySuccess": "URL copied to clipboard",
    "components.assetPreviewModal.createdAt": "Upload date",
    "components.assetPreviewModal.download": "Download",
    "components.assetPreviewModal.fileSize": "File size",
    "components.assetPreviewModal.height": "Height",
    "components.assetPreviewModal.open": "Open image",
    "components.assetPreviewModal.title": "File preview",
    "components.assetPreviewModal.width": "Width",
    "components.assetRenameModal.form.title": null,
    "components.assetRenameModal.title": null,
    "components.assetRenameModal.updateSuccess": null,
    "components.assetRenamePopup.inputLabel": "New filename",
    "components.assetRenamePopup.submitLabel": "Save",
    "components.assetRenamePopup.success": "Rename successful",
    "components.assetRenamePopup.triggerLabel": "Rename",
    "components.assets.listTable.columns.fileSize": null,
    "components.assets.listTable.columns.image": null,
    "components.assets.listTable.columns.imageSize": null,
    "components.assets.listTable.columns.name": null,
    "components.assets.listTable.columns.options": null,
    "components.assets.listTable.columns.select": null,
    "components.assets.listTable.columns.type": null,
    "components.assets.onCopySuccess": null,
    "components.assets.options.copyUrl": null,
    "components.assets.options.delete": null,
    "components.assets.options.download": null,
    "components.assets.options.move": "Move",
    "components.assets.options.rename": null,
    "components.assets.searchInLibrary": null,
    "components.assets.title.files": null,
    "components.assets.title.images": null,
    "components.assets.title.videos": null,
    "components.changePasswordModal.confirmPassword": null,
    "components.changePasswordModal.currentPassword": null,
    "components.changePasswordModal.newPassword": null,
    "components.changePasswordModal.success": null,
    "components.changePasswordModal.title": null,
    "components.changePasswordModal.validationError": null,
    "components.dropzone.placeholder": "Drag 'n' drop some files here, or click to select files",
    "components.emptyfolder.title": "This folder is still empty",
    "components.folderDeleteModal.deleteSuccess": null,
    "components.folderDeleteModal.description": null,
    "components.folderDeleteModal.title": null,
    "components.folderModal.createTitle": "Create folder",
    "components.folderModal.form.name": "Folder name",
    "components.folderModal.messages.createSuccess": "The folder successfully created!",
    "components.folderModal.messages.updateSuccess": "The folder successfully modified!",
    "components.folderModal.updateTitle": "Modify folder",
    "components.folderPage.assetsMaybeFiltered": null,
    "components.folderPage.clearAssetFilter": null,
    "components.folderPage.dropzone": null,
    "components.folderRenameModal.form.title": null,
    "components.folderRenameModal.title": null,
    "components.folderRenameModal.updateSuccess": null,
    "components.folders.options.delete": null,
    "components.folders.options.move": "Move",
    "components.folders.options.rename": null,
    "components.folders.title": null,
    "components.loginPage.form.email": null,
    "components.loginPage.form.password": null,
    "components.loginPage.login": null,
    "components.loginPage.recoverMyAccount": null,
    "components.loginPage.title": null,
    "components.optionsBar.folders": null,
    "components.optionsBar.options.createdAtASC": null,
    "components.optionsBar.options.createdAtDESC": null,
    "components.optionsBar.options.fileSizeASC": null,
    "components.optionsBar.options.fileSizeDESC": null,
    "components.optionsBar.options.titleASC": null,
    "components.optionsBar.options.titleDESC": null,
    "components.optionsBar.options.updatedAtASC": null,
    "components.optionsBar.options.updatedAtDESC": null,
    "components.optionsBar.refresh": null,
    "components.optionsBar.toggleView": null,
    "components.optionsBar.types.all": null,
    "components.optionsBar.types.file": null,
    "components.optionsBar.types.image": null,
    "components.optionsBar.types.video": null,
    "components.searchPage.noResults": null,
    "components.searchPage.search": null,
    "components.uploadModal.error": "Error",
    "components.uploadModal.title": "File upload",
    "components.uploadModal.uploaded": "Uploaded",
    "error.api.ASSET_DIRECTORY_ALREADY_EXISTS": null,
    "error.api.ASSET_DIRECTORY_INVALID_PARENT": null,
    "error.api.ASSET_DIRECTORY_NOT_EMPTY": null,
    "error.api.ASSET_DIRECTORY_NOT_FOUND": null,
    "error.api.ASSET_DIRECTORY_TOO_DEEP": null,
    "error.api.ASSET_EXPORT_JOB_IN_INVALID_STATUS": null,
    "error.api.ASSET_EXPORT_JOB_NOT_FOUND": null,
    "error.api.ASSET_NOT_FOUND": null,
    "error.api.EMAIL_SERVICE_ERROR": null,
    "error.api.FILE_TOO_BIG": null,
    "error.api.INTERNAL_SERVER_ERROR": "Internal server error.",
    "error.api.INVALID_EMBED_CONFIG": "The given embed config is invalid!",
    "error.api.INVALID_RESPONSE": null,
    "error.api.NETWORK_ERROR": null,
    "error.api.PROJECT_ID_MISSING_OR_NOT_ENOUGH_PERMISSION": null,
    "error.api.PROJECT_NAMESPACE_IS_MISSING": null,
    "error.api.RECOVERY_TOKEN_INVALID_OR_EXPIRED": null,
    "error.api.REQUEST_TIMEOUT": null,
    "error.api.STORAGE_SIZE_LIMIT_EXCEEDED": null,
    "error.api.UNAUTHENTICATED": "Unauthenticated.",
    "error.api.UNAUTHORIZED": null,
    "error.api.UNKNOWN": "Unknown error.",
    "error.api.VALIDATION": null,
    "error.api.WEAK_PASSWORD": null,
    "error.auth.description": "The media library will be unusable until you don't provide an authentication key",
    "error.auth.login": null,
    "error.auth.loginWithSignature": null,
    "error.auth.title": "You didn't provide any authentication info",
    "error.validation.invalidConfirmPassword": null,
    "error.validation.invalidEmail": null,
    "error.validation.invalidPassword": null,
    "error.validation.required": null,
    "warning.folderNotDeletable": null
};
