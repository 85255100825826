import { Api } from "@api/Api";
import { batch } from "react-redux";
import { AlertActions } from "./alertSlice";
import { AppStateActions } from "./appStateSlice";
import { AssetActions } from "./assetSlice";
import { FolderActions } from "./folderSlice";
import { SearchActions } from "./searchSlice";
import { AppThunk } from "./store";

const fetchInitialContents = ({
    setSidebarFolders = false,
    clearSelectedAssets = false,
    resetSearch = false,
} = {}): AppThunk => async (dispatch, getState) => {
    const { currentFolder } = getState().folders;

    batch(() => {
        dispatch(AssetActions.clearPosition());
        dispatch(AppStateActions.toggleLoadingBar(true));
        if (clearSelectedAssets) {
            dispatch(AssetActions.clearSelectedAssets());
        }
        if (resetSearch) {
            dispatch(AssetActions.setAssetSearch(null));
        }
    });

    try {
        const { assetOptions } = getState().assets;
        const response = await Api.getFolderContents(currentFolder, assetOptions);

        batch(() => {
            dispatch(AssetActions.setAssets(response.assets));
            dispatch(AssetActions.setCount(response.count));
            dispatch(FolderActions.setFolders(response.folders ?? []));

            if (setSidebarFolders) {
                dispatch(FolderActions.setSidebarFolders(response.folders ?? []));
            }
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const setMaximumSelectableAsset = (maximumSelectableAsset: number): AppThunk => dispatch => {
    batch(() => {
        dispatch(AssetActions.setMaximumSelectableAsset(maximumSelectableAsset));
        dispatch(SearchActions.setMaximumSelectableAsset(maximumSelectableAsset));
    });
};

export const ContentActions = { fetchInitialContents, setMaximumSelectableAsset };
