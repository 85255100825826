import { betaBanner, prodBanner, renderAppBanner } from "@utils/Helpers";
import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "./AppContainer";

const translationVersionNr = "N/A";
/* TODO: Uncomment this after Loctool integration
try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const translationDataJSON = require("./i18n/locales/.loctool.cache.json");
    translationVersionNr = translationDataJSON.systemVersion ?? translationDataJSON.draftVersion ?? null;
} catch (e) {}
*/

const translationVersion = process.env.REACT_APP_LOCTOOL_PRODUCT_ID
    ? `${process.env.REACT_APP_LOCTOOL_PRODUCT_ID}:${(
          process.env.REACT_APP_LOCTOOL_TRANSLATION_FLAG ?? ""
      ).toLowerCase()}, version: ${translationVersionNr}`
    : process.env.REACT_APP_LOCTOOL_DRAFT_ID
    ? `Draft version: ${process.env.REACT_APP_LOCTOOL_DRAFT_ID}`
    : undefined;
renderAppBanner({
    banner: process.env.REACT_APP_DEPLOYMENT_ENV === "beta" ? betaBanner : prodBanner,
    buildSHA: process.env.REACT_APP_BUILD_SHA,
    lastBuildTime: process.env.REACT_APP_LAST_BUILD_TIME,
    translationVersion,
});

ReactDOM.render(
    <React.StrictMode>
        <AppContainer />
    </React.StrictMode>,
    document.getElementById("root")
);
