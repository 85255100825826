import { Element } from "@components/Element";
import { Text } from "@components/Text";
import React from "react";

type Props = {
    title?: string;
};

class Section extends React.PureComponent<React.PropsWithChildren<Props>> {
    public render(): React.ReactNode {
        const { title, children } = this.props;
        return (
            <Element $style={{ padding: "20px 20px 5px" }}>
                {title && (
                    <Text as="h2" variant="linkSmall" $style={{ marginBottom: 25 }}>
                        {title}
                    </Text>
                )}
                {children}
            </Element>
        );
    }
}

export { Section };
