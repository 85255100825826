enum ValidatorMessage {
    required = "required",
    invalidEmail = "invalidEmail",
    invalidPassword = "invalidPassword",
    invalidConfirmPassword = "invalidConfirmPassword",
}

enum ValidatorConstants {
    email = "^[a-zA-Z0-9\\.!#$%&\\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
    minPasswordLength = 8,
}

/**
 * Class for validator functions, returns null or a ValidatorMessage
 * Return value can processed by IntlHelper
 */
class Validator {
    public static required(value: string): ValidatorMessage | null {
        if (value.length === 0) {
            return ValidatorMessage.required;
        }
        return null;
    }

    public static validateEmail(email: string): ValidatorMessage | null {
        const requiredError = Validator.required(email);
        if (requiredError) {
            return requiredError;
        }
        if (!email.match(ValidatorConstants.email)) {
            return ValidatorMessage.invalidEmail;
        }
        return null;
    }

    public static validatePassword(password: string): ValidatorMessage | null {
        const requiredError = Validator.required(password);
        if (requiredError) {
            return requiredError;
        }
        if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)) {
            return ValidatorMessage.invalidPassword;
        }
        return null;
    }

    public static validateConfirmPassword(password: string, confirmPassword: string): ValidatorMessage | null {
        if (password !== confirmPassword) {
            return ValidatorMessage.invalidConfirmPassword;
        }

        return null;
    }
}

export { Validator, ValidatorConstants, ValidatorMessage };
