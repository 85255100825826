import * as React from "react";
import { SVGProps } from "react";

const SvgIconActionPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={10}
        height={10}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path d="M5 1.25V8.75" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.25 5L8.75 5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SvgIconActionPlus;
