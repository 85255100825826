import { AssetType, SearchListSortField, SortOrder } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { Menu, MenuItem } from "@components/Menu";
import { Separator } from "@components/Separator";
import SvgIconArrowSelect from "@components/svg/IconArrowSelect";
import SvgIconBullets from "@components/svg/IconBullets";
import SvgIconFilledAlertInfo from "@components/svg/IconFilledAlertInfo";
import SvgIconFolder from "@components/svg/IconFolder";
import SvgIconGridFeedCards from "@components/svg/IconGridFeedCards";
import SvgIconRefresh from "@components/svg/IconRefresh";
import { AppStateActions } from "@redux/appStateSlice";
import { AssetActions } from "@redux/assetSlice";
import { ContentActions } from "@redux/contentActions";
import { FolderActions } from "@redux/folderSlice";
import { RootState } from "@redux/store";
import { Color, zIndex } from "@theme/Theme";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";

class OptionsBarComponent extends React.Component<ReduxProps & WithLoctoolProps> {
    private readonly onViewToggle = (): void => {
        this.props.toggleAssetListDisplay(!this.props.assetListDisplay);
    };

    private readonly toggleSidebar = (): void => {
        this.props.toggleSidebar(!this.props.sidebarOpen);
    };

    private readonly onSortChange = (field: SearchListSortField, direction: SortOrder): void => {
        this.props.setAssetSort({ field, direction });
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <StyledOptionsBar>
                {!this.props.sidebarOpen && (
                    <Button.Icon
                        label={Loctool.formatMessage({ id: "components.optionsBar.folders" })}
                        icon={<SvgIconFolder width={16} height={16} />}
                        inverse
                        $style={{ marginRight: 15 }}
                        onClick={() => this.toggleSidebar()}
                    />
                )}

                <RotatingButton
                    label={Loctool.formatMessage({ id: "components.optionsBar.refresh" })}
                    icon={<SvgIconRefresh width={16} height={16} />}
                    inverse
                    onClick={() => {
                        this.props.fetchInitialContents({ clearSelectedAssets: true });
                        document.documentElement.scrollTop = 0;
                    }}
                />

                <Menu
                    transition
                    menuButton={
                        <Element $style={{ marginLeft: "auto" }}>
                            <Button.Text
                                label={Loctool.formatMessage({
                                    id: `components.optionsBar.types.${this.props.typeFilter ?? "all"}`,
                                })}
                                startIcon={
                                    this.props.typeFilter ? (
                                        <Element
                                            as={SvgIconFilledAlertInfo}
                                            $style={{ minWidth: 16, width: 16, height: 16, color: Color.errorDefault }}
                                        />
                                    ) : undefined
                                }
                                endIcon={<SvgIconArrowSelect width={16} height={16} />}
                                title={
                                    this.props.typeFilter
                                        ? `Csak a ${Loctool.formatMessage({
                                              id: `components.optionsBar.types.${this.props.typeFilter ?? "all"}`,
                                          }).toLowerCase()} megjelenítve`
                                        : undefined
                                }
                                adjust
                                inverse
                            />
                        </Element>
                    }
                >
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.types.all" })}
                            onClick={() => {
                                this.props.setTypeFilter(null);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.types.image" })}
                            onClick={() => {
                                this.props.setTypeFilter(AssetType.image);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.types.file" })}
                            onClick={() => {
                                this.props.setTypeFilter(AssetType.file);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.types.video" })}
                            onClick={() => {
                                this.props.setTypeFilter(AssetType.video);
                            }}
                        />
                    </MenuItem>
                </Menu>

                <Separator vertical />

                <Menu
                    transition
                    menuButton={
                        <div>
                            <Button.Text
                                label={Loctool.formatMessage({
                                    id: `components.optionsBar.options.${this.props.assetListOptions.sortField}${this.props.assetListOptions.control?.sortOrder}`,
                                })}
                                endIcon={<SvgIconArrowSelect width={16} height={16} />}
                                adjust
                                inverse
                            />
                        </div>
                    }
                >
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.titleASC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.title, SortOrder.ASC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.titleDESC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.title, SortOrder.DESC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.fileSizeASC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.fileSize, SortOrder.ASC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.fileSizeDESC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.fileSize, SortOrder.DESC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.createdAtASC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.createdAt, SortOrder.ASC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.createdAtDESC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.createdAt, SortOrder.DESC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.updatedAtASC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.updatedAt, SortOrder.ASC);
                            }}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button.Menu
                            label={Loctool.formatMessage({ id: "components.optionsBar.options.updatedAtDESC" })}
                            onClick={() => {
                                this.onSortChange(SearchListSortField.updatedAt, SortOrder.DESC);
                            }}
                        />
                    </MenuItem>
                </Menu>

                <Separator vertical />

                <Button.Icon
                    label={Loctool.formatMessage({ id: "components.optionsBar.toggleView" })}
                    inverse
                    icon={
                        this.props.assetListDisplay ? (
                            <SvgIconGridFeedCards width={20} height={20} />
                        ) : (
                            <SvgIconBullets width={20} height={20} />
                        )
                    }
                    onClick={() => this.onViewToggle()}
                />
            </StyledOptionsBar>
        );
    }
}

const StyledOptionsBar = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    position: sticky;
    top: 84px;
    right: 0;
    width: 100%;
    height: 38px;
    padding: 0 20px;
    color: ${props => props.theme.optionsBar.color};
    background: ${props => props.theme.optionsBar.background};
    border-bottom: 1px solid ${props => props.theme.optionsBar.borderColor};
    z-index: ${zIndex.optionsBar};
`;

const RotatingButton = styled(Button.Icon)`
    transition: transform 0.25s ease-in-out;

    &:hover {
        transform: rotate(180deg);
    }
`;

const mapStateToProps = (state: RootState) => ({
    assetListDisplay: state.assets.assetListDisplay,
    sidebarOpen: state.appState.sidebarOpen,
    assetListOptions: state.assets.assetOptions,
    typeFilter: state.assets.typeFilter,
});

const mapDispatchToProps = {
    fetchInitialContents: ContentActions.fetchInitialContents,
    toggleSidebar: AppStateActions.toggleSidebar,
    refreshAssets: AssetActions.refreshAssets,
    toggleAssetListDisplay: AssetActions.toggleAssetListDisplay,
    setAssetSort: AssetActions.setAssetSort,
    fetchFolders: FolderActions.fetchFolders,
    setTypeFilter: AssetActions.setTypeFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const OptionsBar = withLoctool(connector(OptionsBarComponent));
