import { Asset, AssetType } from "@api/graphql/types";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { AssetDeleteModal } from "@components/Assets/AssetDeleteModal";
import { AssetPreviewModal } from "@components/Assets/AssetPreviewModal";
import { AssetRenameModal } from "@components/Assets/AssetRenameModal";
import { DocumentTile } from "@components/Assets/DocumentTile";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { Section } from "@components/Section";
import { AlertActions } from "@redux/alertSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import { showForSr } from "@theme/GlobalStyle";
import clipboardCopy from "clipboard-copy/index";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { AssetMoveModal } from "./AssetMoveModal";
import { DocumentRow } from "./DocumentRow";
import { ImageRow } from "./ImageRow";
import { ImageTile } from "./ImageTile";

type Props = {
    assets: Asset[];
    assetListDisplay: boolean;
    selectedAssets: Asset[];
    refreshAssets: () => void;
    toggleSelectedAsset: (asset: Asset) => void;
    onShiftSelectAsset?: (asset: Asset) => void;
    typeFilter?: AssetType | null;
} & ReduxProps &
    WithLoctoolProps;

class AssetsComponent extends React.Component<Props> {
    private readonly isAssetSelected = (asset: Asset): boolean => {
        return this.props.selectedAssets.some((selectedAsset: Asset) => selectedAsset.id === asset.id);
    };

    private readonly onUrlCopy = (url: string): void => {
        clipboardCopy(url).catch(() => {});
        this.props.alertSuccess({
            message: this.props.Loctool.formatMessage({ id: "components.assets.onCopySuccess" }),
        });
    };

    private readonly renderAssets = (assets: Asset[]): React.ReactElement[] => {
        return assets.map(
            (asset: Asset): React.ReactElement => {
                switch (asset.assetType) {
                    case AssetType.image: {
                        return (
                            <ImageTile
                                key={asset.id}
                                asset={asset}
                                selected={this.isAssetSelected(asset)}
                                onSelect={this.props.toggleSelectedAsset}
                                onShiftSelect={this.props.onShiftSelectAsset}
                                onUrlCopy={this.onUrlCopy}
                                onRename={this.props.toggleRenameModal}
                                onMove={asset => this.props.toggleMoveModal([asset])}
                                onDelete={asset => this.props.toggleDeleteModal([asset])}
                                onShow={this.props.toggleShowModal}
                            />
                        );
                    }
                    default: {
                        return (
                            <DocumentTile
                                key={asset.id}
                                asset={asset}
                                selected={this.isAssetSelected(asset)}
                                onSelect={this.props.toggleSelectedAsset}
                                onShiftSelect={this.props.onShiftSelectAsset}
                                onUrlCopy={this.onUrlCopy}
                                onRename={this.props.toggleRenameModal}
                                onMove={asset => this.props.toggleMoveModal([asset])}
                                onDelete={asset => this.props.toggleDeleteModal([asset])}
                                onShow={this.props.toggleShowModal}
                            />
                        );
                    }
                }
            }
        );
    };

    private readonly renderAssetRows = (assets: Asset[]): React.ReactElement[] => {
        return assets.map(
            (asset: Asset): React.ReactElement => {
                switch (asset.assetType) {
                    case AssetType.image: {
                        return (
                            <ImageRow
                                key={asset.id}
                                asset={asset}
                                selected={this.isAssetSelected(asset)}
                                onSelect={this.props.toggleSelectedAsset}
                                onShiftSelect={this.props.onShiftSelectAsset}
                                onUrlCopy={this.onUrlCopy}
                                onRename={this.props.toggleRenameModal}
                                onMove={asset => this.props.toggleMoveModal([asset])}
                                onDelete={asset => this.props.toggleDeleteModal([asset])}
                            />
                        );
                    }
                    default: {
                        return (
                            <DocumentRow
                                key={asset.id}
                                asset={asset}
                                selected={this.isAssetSelected(asset)}
                                onSelect={this.props.toggleSelectedAsset}
                                onShiftSelect={this.props.onShiftSelectAsset}
                                onUrlCopy={this.onUrlCopy}
                                onRename={this.props.toggleRenameModal}
                                onMove={asset => this.props.toggleMoveModal([asset])}
                                onDelete={asset => this.props.toggleDeleteModal([asset])}
                            />
                        );
                    }
                }
            }
        );
    };

    public render() {
        const {
            assets,
            showModal,
            toggleShowModal,
            renameModal,
            toggleRenameModal,
            moveModal,
            toggleMoveModal,
            deleteModal,
            toggleDeleteModal,
            refreshAssets,
            typeFilter = null,
            Loctool,
        } = this.props;

        if (assets.length === 0) {
            return null;
        }

        const filteredAssets = typeFilter ? assets.filter(asset => asset.assetType === typeFilter) : assets;

        return (
            <>
                <Section>
                    {this.props.assetListDisplay ? (
                        <AssetsTable>
                            <thead>
                                <tr>
                                    <Element as="th" $style={{ width: 40 }}>
                                        <AssetsTableHeading>
                                            <LoctoolMessage id="components.assets.listTable.columns.select" />
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" $style={{ width: 40 }}>
                                        <AssetsTableHeading>
                                            <LoctoolMessage id="components.assets.listTable.columns.options" />
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" style={{ width: 50 }}>
                                        <AssetsTableHeading>
                                            <LoctoolMessage id="components.assets.listTable.columns.image" />
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" $style={{ width: "100%" }}>
                                        <AssetsTableHeading>
                                            <LoctoolMessage id="components.assets.listTable.columns.name" />
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" $style={{ width: 95 }}>
                                        <AssetsTableHeading>
                                            <LoctoolMessage id="components.assets.listTable.columns.type" />
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" $style={{ width: 150 }}>
                                        <AssetsTableHeading>
                                            {Loctool.formatMessage({
                                                id: "components.assets.listTable.columns.imageSize",
                                            })}
                                        </AssetsTableHeading>
                                    </Element>
                                    <Element as="th" $style={{ width: 110 }}>
                                        <AssetsTableHeading>
                                            {Loctool.formatMessage({
                                                id: "components.assets.listTable.columns.fileSize",
                                            })}
                                        </AssetsTableHeading>
                                    </Element>
                                </tr>
                            </thead>
                            <tbody>{filteredAssets.length > 0 && this.renderAssetRows(filteredAssets)}</tbody>
                        </AssetsTable>
                    ) : (
                        <AssetsGrid>{filteredAssets.length > 0 && this.renderAssets(filteredAssets)}</AssetsGrid>
                    )}
                </Section>
                {renameModal.show && renameModal.asset && (
                    <AssetRenameModal
                        mounted={renameModal.show}
                        onModalClose={toggleRenameModal}
                        asset={renameModal.asset}
                        refreshAssets={refreshAssets}
                    />
                )}
                {deleteModal.show && deleteModal.assets && (
                    <AssetDeleteModal
                        mounted={deleteModal.show}
                        onModalClose={toggleDeleteModal}
                        assets={deleteModal.assets}
                        refreshAssets={refreshAssets}
                    />
                )}
                {showModal.show && showModal.asset && (
                    <AssetPreviewModal
                        mounted={showModal.show}
                        onModalClose={() => {
                            refreshAssets();
                            toggleShowModal();
                        }}
                        asset={showModal.asset}
                    />
                )}
                {moveModal.show && moveModal.assets && (
                    <AssetMoveModal
                        mounted={moveModal.show}
                        onModalClose={toggleMoveModal}
                        selectedAssets={moveModal.assets}
                    />
                )}
            </>
        );
    }
}

const AssetsGrid = styled(Flex.Container).attrs(() => ({
    $flexWrap: "wrap",
}))`
    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
        grid-gap: 20px;
    }
`;

const AssetsTable = styled.table`
    table-layout: fixed;
    min-width: 630px;
    width: 100%;
    border-collapse: collapse;
`;

const AssetsTableHeading = styled.span`
    ${showForSr};
`;

const mapStateToProps = (state: RootState) => ({
    showModal: state.modals.showModal,
    renameModal: state.modals.renameModal,
    deleteModal: state.modals.deleteModal,
    moveModal: state.modals.moveModal,
});

const mapDispatchToProps = {
    toggleShowModal: ModalActions.toggleShowModal,
    toggleRenameModal: ModalActions.toggleRenameModal,
    toggleDeleteModal: ModalActions.toggleDeleteModal,
    toggleMoveModal: ModalActions.toggleMoveModal,
    alertSuccess: AlertActions.success,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const Assets = withLoctool(connector(AssetsComponent));
