import React from "react";
import SvgFileDoc from "@components/svg/FileDoc";
import SvgFileDocx from "@components/svg/FileDocx";
import SvgFilePdf from "@components/svg/FilePdf";
import SvgFilePpt from "@components/svg/FilePpt";
import SvgFilePptx from "@components/svg/FilePptx";
import SvgFileTxt from "@components/svg/FileTxt";
import SvgFileXls from "@components/svg/FileXls";
import SvgFileXlsx from "@components/svg/FileXlsx";
import SvgFileUnknown from "@components/svg/FileUnknown";
import { MimeType } from "@utils/MimeType";

export type DocTypeDef = { icon: JSX.Element; iconRow: JSX.Element; color: string };

export const documentTypes: { [key: string]: DocTypeDef } = {
    doc: { icon: <SvgFileDoc />, iconRow: <SvgFileDoc width={"100%"} height="30" />, color: "#063EF9" },
    docx: { icon: <SvgFileDocx />, iconRow: <SvgFileDocx width={"100%"} height="30" />, color: "#063EF9" },
    pdf: { icon: <SvgFilePdf />, iconRow: <SvgFilePdf width={"100%"} height="30" />, color: "#6308F7" },
    xls: { icon: <SvgFileXls />, iconRow: <SvgFileXls width={"100%"} height="30" />, color: "#00CC67" },
    xlsx: { icon: <SvgFileXlsx />, iconRow: <SvgFileXlsx width={"100%"} height="30" />, color: "#00CC67" },
    ppt: { icon: <SvgFilePpt />, iconRow: <SvgFilePpt width={"100%"} height="30" />, color: "#FE017F" },
    pptx: { icon: <SvgFilePptx />, iconRow: <SvgFilePptx width={"100%"} height="30" />, color: "#FE017F" },
    txt: { icon: <SvgFileTxt />, iconRow: <SvgFileTxt width={"100%"} height="30" />, color: "#00CC67" },
};

export const getDocumentType = (mimeType: string): DocTypeDef => {
    const extensionByMimetype = MimeType.extension(mimeType);
    let documentType: DocTypeDef | undefined = {
        icon: <SvgFileUnknown />,
        iconRow: <SvgFileUnknown width={"100%"} height="30" />,
        color: "#00BAFF",
    };
    if (extensionByMimetype && typeof documentTypes[extensionByMimetype] !== "undefined") {
        documentType = documentTypes[extensionByMimetype];
    }
    return documentType;
};
