import * as React from "react";
import { SVGProps } from "react";

const SvgIconFileStructure = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <g clipPath="url(#clip0_5608_1277)">
            <path
                d="M8.75 2.66699C8.75 1.97664 9.30964 1.41699 10 1.41699H10.1438C10.5173 1.41699 10.8713 1.58406 11.1087 1.87244L12.2983 3.31715C12.4826 3.54094 12.5833 3.82181 12.5833 4.1117V5.33366C12.5833 6.02401 12.0237 6.58366 11.3333 6.58366H10C9.30964 6.58366 8.75 6.02401 8.75 5.33366V2.66699Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path
                d="M8.75 11.333C8.75 10.6427 9.30964 10.083 10 10.083H10.1438C10.5173 10.083 10.8713 10.2501 11.1087 10.5385L12.2983 11.9832C12.4826 12.207 12.5833 12.4878 12.5833 12.7777V13.9997C12.5833 14.69 12.0237 15.2497 11.3333 15.2497H10C9.30964 15.2497 8.75 14.69 8.75 13.9997V11.333Z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path
                d="M2.66675 0.666992V3.66699M5.66675 12.3337H2.66675V3.66699M2.66675 3.66699H5.66675"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_5608_1277">
                <rect width={16} height={16} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgIconFileStructure;
