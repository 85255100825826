import * as React from "react";
import { SVGProps } from "react";

const SvgIconLogOutCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M12.2729 13.773C9.63689 16.409 5.36303 16.409 2.72699 13.773C0.0909473 11.1369 0.0909473 6.86307 2.72699 4.22703C5.36303 1.59099 9.63689 1.59099 12.2729 4.22703"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 6.75L17 9L15 11.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M16.875 9L7.5 9" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
);

export default SvgIconLogOutCircle;
