import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { getDocumentType } from "@components/Assets/DocTypes";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import Modal, { ModalList } from "@components/Modal";
import { ProgressBar } from "@components/ProgressBar";
import SvgIconCircleActionsClose from "@components/svg/IconCircleActionsClose";
import SvgIconCircleActionsSuccess from "@components/svg/IconCircleActionsSuccess";
import SvgIconLoading from "@components/svg/IconLoading";
import SvgIconUpload from "@components/svg/IconUpload";
import { Text } from "@components/Text";
import { AssetActions } from "@redux/assetSlice";
import { AssetUploadActions } from "@redux/assetUploadSlice";
import { RootState } from "@redux/store";
import { keyframesRotate360 } from "@theme/GlobalStyle";
import { Color } from "@theme/Theme";
import React from "react";
import Dropzone from "react-dropzone";
import { batch, connect, ConnectedProps } from "react-redux";
import styled from "styled-components";

type Props = {
    mounted: boolean;
    onToggleModal: () => void;
} & ReduxProps &
    WithLoctoolProps;

class UploadModalComponent extends React.Component<Props> {
    private readonly handleFileDrop = async (files: File[]) => {
        this.props.uploadAssets(files);
    };

    public componentDidUpdate() {
        const { uploads } = this.props;
        const completedUploads = uploads.filter(u => u.isUploaded);

        if (uploads.length > 0 && completedUploads.length === uploads.length) {
            setTimeout(() => {
                batch(() => {
                    this.props.clearUploads();
                    this.props.onToggleModal();
                    this.props.refreshAssets();
                });
            }, 1000);
        }
    }

    private onCloseClick = () => {
        batch(() => {
            this.props.clearUploads();
            this.props.onToggleModal();
            this.props.refreshAssets();
        });
    };

    private renderDropzone = () => (
        <Dropzone onDropAccepted={this.handleFileDrop}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <DropzoneContainer {...getRootProps()}>
                        <input {...getInputProps()} />
                        <SvgIconUpload width={38} height={38} />
                        <Text
                            as="p"
                            variant="textXSmall"
                            $style={{ maxWidth: 330, marginTop: 16, color: "inherit", textAlign: "center" }}
                        >
                            <LoctoolMessage id="components.dropzone.placeholder" />
                        </Text>
                    </DropzoneContainer>
                </section>
            )}
        </Dropzone>
    );

    private renderProgress = () => {
        const { uploads } = this.props;
        const completedUploads = uploads.filter(u => u.isUploaded);
        const failedUploads = uploads.filter(u => u.error);
        const progressPercent = ((completedUploads.length + failedUploads.length) / uploads.length) * 100;

        return (
            <>
                <Element $style={{ display: "flex", alignItems: "center", marginBottom: 30 }}>
                    <Text as="span" variant="linkXSmall" $style={{ marginRight: 5 }}>
                        {completedUploads.length + failedUploads.length} / {uploads.length}
                    </Text>
                    <Flex.Item $shrink="auto">
                        <ProgressBar progressPercent={progressPercent} />
                    </Flex.Item>
                </Element>

                <ModalList>
                    {uploads.map(upload => (
                        <Asset key={upload.key}>
                            {upload.type.startsWith("image") ? (
                                <Element
                                    as="img"
                                    src={upload.src}
                                    alt={upload.fileName}
                                    $style={{
                                        minWidth: 30,
                                        width: 30,
                                        height: 30,
                                        marginRight: 10,
                                        borderRadius: 6,
                                        objectFit: "cover",
                                    }}
                                />
                            ) : (
                                <Element $style={{ minWidth: 30, width: 30, height: 30, marginRight: 10 }}>
                                    {getDocumentType(upload.type)?.iconRow}
                                </Element>
                            )}
                            <Text
                                as="span"
                                variant="linkXSmall"
                                lineClamp={1}
                                $style={{
                                    paddingRight: 5,
                                    marginRight: "auto",
                                    color: upload.error ? Color.chili500 : Color.sky500,
                                }}
                            >
                                {upload.fileName}
                            </Text>
                            {upload.isUploaded && <CompletedIcon width={18} height={18} />}
                            {upload.error && <ErrorIcon width={18} height={18} />}
                            {!upload.isUploaded && !upload.error && <LoadingIcon width={18} height={18} />}
                        </Asset>
                    ))}
                </ModalList>

                {progressPercent === 100 && failedUploads.length > 0 && (
                    <Button.Primary
                        label={this.props.Loctool.formatMessage({ id: "common.close" })}
                        onClick={this.onCloseClick}
                        $style={{ marginTop: 30 }}
                        fullWidth
                    />
                )}
            </>
        );
    };

    public render(): React.ReactElement {
        const { uploads } = this.props;
        const uploadInProgress = uploads.length > 0;

        return (
            <Modal
                heading={this.props.Loctool.formatMessage({ id: "components.uploadModal.title" })}
                mounted={this.props.mounted}
                onModalClose={this.props.onToggleModal}
                hideCloseButton={uploadInProgress}
            >
                {uploadInProgress ? this.renderProgress() : this.renderDropzone()}
            </Modal>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropzoneContainer: any = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $alignItems: "center",
})`
    width: 100%;
    padding: 20px;
    color: ${props => props.theme.dropzone.color};
    background: ${props => props.theme.dropzone.background};
    border: 1px dashed ${props => props.theme.dropzone.borderColor};
    border-radius: 16px;

    &:hover {
        cursor: pointer;
    }
`;

const CompletedIcon = styled(SvgIconCircleActionsSuccess)`
    circle {
        stroke: ${Color.sky500};
    }

    g {
        opacity: 1;
        fill: ${Color.sky500};
    }

    path {
        color: ${Color.white};
    }
`;

const ErrorIcon = styled(SvgIconCircleActionsClose)`
    color: ${Color.chili500};
    fill: ${Color.chili500};

    path {
        color: ${Color.white};
    }
`;

const LoadingIcon = styled(SvgIconLoading)`
    color: ${Color.ocean500};
    animation: ${keyframesRotate360} 1s linear infinite;
`;

const Asset = styled(Flex.Container).attrs({
    $alignItems: "center",
})`
    margin: 5px 0;

    &:not(:nth-last-of-type(1)) {
        padding-bottom: 5px;
        border-bottom: 1px solid ${Color.grayscaleLine};
    }
`;

const mapStateToProps = (state: RootState) => ({
    uploads: state.assetUpload.uploads,
});

const mapDispatchToProps = {
    uploadAssets: AssetUploadActions.uploadAssets,
    clearUploads: AssetUploadActions.clearUploads,
    refreshAssets: AssetActions.refreshAssets,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const UploadModal = withLoctool(connector(UploadModalComponent));
