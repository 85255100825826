import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { AlertActions } from "@redux/alertSlice";
import { RootState } from "@redux/store";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Alert, AlertType } from "./Alert";
import { AlertOptions } from "./AlertTypes";

interface State {
    lastAlertOptions: AlertOptions | null;
    currentAlertOptions: AlertOptions | null;
    isShowing: boolean;
}

type Props = ReduxProps & WithLoctoolProps;

class AlertContainerComponent extends Component<Props, State> {
    private static readonly HIDE_ANIMATION_LENGTH: number = 1000;
    private static readonly DEFAULT_SHOW_TIMEOUT: number = 4000;

    public readonly state: State = {
        lastAlertOptions: null,
        currentAlertOptions: null,
        isShowing: false,
    };

    private showingTimeout: number | null = null;
    private removeTimeout: number | null = null;

    public componentWillUnmount(): void {
        if (this.showingTimeout) {
            clearTimeout(this.showingTimeout);
        }

        if (this.removeTimeout) {
            clearTimeout(this.removeTimeout);
        }
    }

    public componentDidMount() {
        this.loadQueue(this.props);
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (prevProps.alerts !== this.props.alerts && !prevState.isShowing && prevState.currentAlertOptions === null) {
            this.loadQueue(this.props);
        }
    }

    private loadQueue(props: Props): void {
        if (props.alerts.length > 0) {
            const currentAlertOptions: AlertOptions = props.alerts[0];
            this.setState({ currentAlertOptions, isShowing: true }, (): void => {
                this.setShowingTimeout(currentAlertOptions.timeout || AlertContainerComponent.DEFAULT_SHOW_TIMEOUT);
            });
        }
    }

    private setShowingTimeout(timeout: number): void {
        this.showingTimeout = window.setTimeout(() => {
            this.setState({ isShowing: false }, () => {
                this.setRemoveTimeout();
            });
        }, timeout);
    }

    private setRemoveTimeout(): void {
        this.removeTimeout = window.setTimeout(() => {
            const { currentAlertOptions } = this.state;
            this.setState({ currentAlertOptions: null, lastAlertOptions: currentAlertOptions }, () => {
                this.props.hideAlert();

                if (currentAlertOptions && currentAlertOptions.callback) {
                    currentAlertOptions.callback();
                }
            });
        }, AlertContainerComponent.HIDE_ANIMATION_LENGTH);
    }

    public onClose = (): void => {
        if (this.showingTimeout) {
            clearTimeout(this.showingTimeout);
        }

        if (this.removeTimeout) {
            clearTimeout(this.removeTimeout);
        }

        const { currentAlertOptions } = this.state;
        this.setState({ currentAlertOptions: null, lastAlertOptions: currentAlertOptions, isShowing: false }, () => {
            this.props.hideAlert();
            if (currentAlertOptions && currentAlertOptions.callback) {
                currentAlertOptions.callback();
            }
        });
    };

    public render() {
        const { currentAlertOptions, lastAlertOptions } = this.state;
        const message: string = currentAlertOptions
            ? currentAlertOptions.message
            : lastAlertOptions
            ? lastAlertOptions.message
            : "";
        const type: AlertType = currentAlertOptions
            ? currentAlertOptions.type
            : lastAlertOptions
            ? lastAlertOptions.type
            : AlertType.info;
        const translate = currentAlertOptions
            ? currentAlertOptions.translate
            : lastAlertOptions
            ? lastAlertOptions.translate
            : false;

        return (
            <Alert
                message={message}
                visible={!!currentAlertOptions}
                type={type}
                onClose={this.onClose}
                translate={translate}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    alerts: state.alerts,
});

const mapDispatchToProps = {
    hideAlert: AlertActions.hide,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const AlertContainer = withLoctool(connector(AlertContainerComponent));
