import * as React from "react";
import { SVGProps } from "react";

const SvgIconFolderPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={16}
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M1.66663 5.66699C1.66663 4.01014 3.00977 2.66699 4.66663 2.66699H6.22352C6.93965 2.66699 7.63216 2.92317 8.17589 3.38922L8.82402 3.94476C9.36775 4.41082 10.0603 4.66699 10.7764 4.66699H13.3333C14.9901 4.66699 16.3333 6.01014 16.3333 7.66699V11.0003C16.3333 12.6572 14.9901 14.0003 13.3333 14.0003H4.66663C3.00977 14.0003 1.66663 12.6572 1.66663 11.0003V5.66699Z"
            stroke="currentColor"
            strokeWidth={1.5}
        />
        <path d="M8.66663 7.66699V11.0003" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        <path d="M7 9.33301L10.3333 9.33301" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
);

export default SvgIconFolderPlus;
