import React from "react";
import styled from "styled-components";

export const ProgressBar: React.FC<{ progressPercent: number }> = props => (
    <StyledProgressBar role="progressbar" aria-valuemax={100} aria-valuemin={0} aria-valuenow={props.progressPercent}>
        <span className="show-for-sr">{props.progressPercent} %</span>
        <Progress style={{ width: `${props.progressPercent}%` }} aria-hidden="true" />
    </StyledProgressBar>
);

const StyledProgressBar = styled.div`
    position: relative;
    width: 100%;
    height: 8px;
    background: ${props => props.theme.progressBar.background};
    border-radius: 4px;
    pointer-events: none;
    z-index: 1;
`;

const Progress = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 8px;
    background: ${props => props.theme.progressBar.progressColor};
    border-radius: 4px;
    z-index: 1;
`;
