import { AuthActions } from "@redux/authSlice";
import { store } from "@redux/store";
import { ApolloClient, ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloReducerConfig, InMemoryCache } from "apollo-cache-inmemory";
import { ErrorResponse, onError } from "apollo-link-error";
import { ApolloLink, ExecutionResult, FetchResult, GraphQLRequest, Observable } from "apollo-link";
import { Log } from "@utils/Log";
import { ObjectUtils } from "@utils/ObjectUtils";
import { ApiError, ApiErrorCode } from "../ApiError";
import { Mutations } from "./queries/Mutations";
import * as GqlTypes from "@api/graphql/types";

export type OnProgress = (progress: number) => void;

export class GraphQLClient {
    private static refreshTokenCache: {
        promise: Promise<FetchResult<GqlTypes.refreshAccessToken>> | null;
    } = {
        promise: null,
    };

    private static readonly uploadLink = createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_API_URL,
        credentials: "include",
    });

    private static readonly authLink: ApolloLink = setContext((_: GraphQLRequest, prevContext) => {
        const { authToken, projectId } = store.getState().auth;

        return {
            headers: {
                ...prevContext.headers,
                Authorization: authToken ? `Bearer ${authToken}` : "",
                Projectid: projectId ?? "",
            },
        };
    });

    private static readonly errorLink: ApolloLink = onError((errorResponse: ErrorResponse) => {
        const apiErrorCode: ApiErrorCode = GraphQLClient.getApiErrorCode(errorResponse);
        const refreshToken = store.getState().auth.refreshToken;
        const { operation, forward } = errorResponse;

        if ([ApiErrorCode.UNAUTHENTICATED, ApiErrorCode.UNAUTHORIZED].includes(apiErrorCode) && refreshToken) {
            GraphQLClient.refreshTokenCache.promise =
                GraphQLClient.refreshTokenCache.promise ??
                new ApolloClient({
                    link: (GraphQLClient.uploadLink as unknown) as ApolloLink,
                    cache: new InMemoryCache({ resultCaching: false }),
                }).mutate<GqlTypes.refreshAccessToken, GqlTypes.refreshAccessTokenVariables>({
                    mutation: Mutations.refreshAccessToken,
                    variables: {
                        refreshToken,
                    },
                });

            return new Observable(observer => {
                GraphQLClient.refreshTokenCache
                    .promise!.then(response => {
                        const accessToken = response.data?.refreshAccessToken;
                        if (accessToken) {
                            store.dispatch(AuthActions.setAuthToken(accessToken));
                            const subscriber = {
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer),
                            };
                            operation.setContext({
                                headers: {
                                    ...operation.getContext().headers,
                                    Authorization: accessToken ? `Bearer ${accessToken}` : "",
                                },
                            });
                            forward(operation).subscribe(subscriber);
                        } else {
                            throw new ApiError(ApiErrorCode.UNAUTHENTICATED);
                        }
                    })
                    .catch(error => {
                        console.warn("Token refresh and retry failed", error);
                        store.dispatch(AuthActions.destroy());
                        document.location.reload();
                    })
                    .finally(() => (GraphQLClient.refreshTokenCache.promise = null));
            });
        }
    });

    private static client: ApolloClient<ApolloReducerConfig> = new ApolloClient({
        link: GraphQLClient.authLink
            .concat(GraphQLClient.errorLink)
            .concat((GraphQLClient.uploadLink as unknown) as ApolloLink),
        cache: new InMemoryCache({
            resultCaching: false,
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    /**
     * GraphQLClient mutation
     * Throws error if response.data is empty
     * @param options MutationOptions<R, V>
     */
    public static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response: ExecutionResult<R> = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(GraphQLClient.getApiErrorCode(error as ErrorResponse));
        }
    }

    /**
     * GraphQLClient query
     * Throws error if response.data is empty
     * @param options QueryOptions<R>
     */
    public static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(
                options as QueryOptions<OperationVariables>
            );
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(GraphQLClient.getApiErrorCode(error as ErrorResponse));
        }
    }

    private static getResult<R>(response: ApolloQueryResult<R> | ExecutionResult<R>): R {
        if (response.errors && response.errors.length > 0) {
            throw new ApiError(ApiErrorCode[GraphQLClient.getApiErrorCode({ graphQLErrors: response.errors })]);
        }

        if (!response.data) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.data;
    }

    private static getApiErrorCode(error: Partial<ErrorResponse>): ApiErrorCode {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            const errorMessage: string = error.graphQLErrors[0].message.replace(/^E_/, "");
            const errorCode: string = (error.graphQLErrors[0].extensions?.code ?? "").replace(/^E_/, "");

            if (ObjectUtils.isEnumContains<ApiErrorCode>(ApiErrorCode, errorMessage)) {
                return errorMessage;
            } else if (ObjectUtils.isEnumContains<ApiErrorCode>(ApiErrorCode, errorCode)) {
                return errorCode;
            } else {
                Log.warning("Unknown error code from GraphQL response", errorMessage, errorCode);
            }
        }
        if (error.networkError) {
            Log.debug("Network error occurred", error);
            return ApiErrorCode.NETWORK_ERROR;
        }
        Log.warning("Unknown error code from GraphQL response", error);
        return ApiErrorCode.UNKNOWN;
    }
}
