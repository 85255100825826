import * as React from "react";
import { SVGProps } from "react";

const SvgIconGridFeedCards = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect x={1.66669} y={1.6665} width={5.33333} height={5.33333} rx={1} stroke="currentColor" strokeWidth={2} />
        <rect x={11} y={1.6665} width={5.33333} height={5.33333} rx={1} stroke="currentColor" strokeWidth={2} />
        <rect x={1.66669} y={11} width={5.33333} height={5.33333} rx={1} stroke="currentColor" strokeWidth={2} />
        <rect x={11} y={11} width={5.33333} height={5.33333} rx={1} stroke="currentColor" strokeWidth={2} />
    </svg>
);

export default SvgIconGridFeedCards;
