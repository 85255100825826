import * as React from "react";
import { SVGProps } from "react";

const SvgIconProfile = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M10.3787 9.81712C9.47596 9.4237 8.27224 9.04199 7 9.04199C5.72776 9.04199 4.52404 9.4237 3.62126 9.81712C2.57356 10.2737 1.96462 11.3164 1.82997 12.4513L1.75 13.1253H12.25L12.17 12.4513C12.0354 11.3164 11.4264 10.2737 10.3787 9.81712Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7 6.41699C8.44975 6.41699 9.625 5.24174 9.625 3.79199C9.625 2.34224 8.44975 1.16699 7 1.16699C5.55025 1.16699 4.375 2.34224 4.375 3.79199C4.375 5.24174 5.55025 6.41699 7 6.41699Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconProfile;
