import { Flex } from "@components/Flex";
import React from "react";
import styled from "styled-components";

type BreadcrumbProps = {
    ariaLabel: string;
};

export class Breadcrumb extends React.Component<BreadcrumbProps> {
    render() {
        const { ariaLabel, children } = this.props;

        return (
            <nav aria-label={ariaLabel}>
                <BreadCrumbList>{children}</BreadCrumbList>
            </nav>
        );
    }
}

const BreadCrumbList = styled(Flex.Container).attrs(() => ({ as: "ol" }))`
    margin: 0;
    padding: 0;
    color: ${props => props.theme.breadcrumb.color};
    list-style-type: none;

    li {
        display: flex;
        position: relative;
        margin-right: 10px;
    }

    a {
        display: inline-flex;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;

        svg {
            margin: auto;
        }
    }
`;
