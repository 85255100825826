import * as React from "react";
import { SVGProps } from "react";

const SvgIconDownload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={17}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M1.66659 8.99984C1.33325 7.99984 1.33325 6.99984 1.33325 6.30408C1.33325 3.98529 3.40957 1.6665 5.97083 1.6665C7.68739 1.6665 9.18612 2.59912 9.98798 3.98529H10.6084C12.5293 3.98529 14.3333 5.33317 14.3333 6.99984C14.3333 7.6665 14.3333 8.33317 13.9999 8.99984"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.94434 13.2212L7.66656 15.8334L10.3888 13.2212"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M7.66675 15.146L7.66675 7.74981" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    </svg>
);

export default SvgIconDownload;
