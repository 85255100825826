import * as React from "react";
import { SVGProps } from "react";

const SvgIconLoading = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01808 6.3459 3.2612 8.17317C2.50433 10.0004 2.30629 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42893 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8079C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconLoading;
