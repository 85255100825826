export const random = (min: number, max: number) => {
    return Math.floor(min + Math.random() * (max - min));
};

export const isEqual = (a: object, b: object): boolean => {
    return JSON.stringify(a) === JSON.stringify(b);
};

export const download = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.dispatchEvent(new MouseEvent("click"));
};

export type AppBannerConfig = {
    banner: string;
    lastBuildTime?: string;
    buildSHA?: string;
    translationVersion?: string;
};

export const renderAppBanner = (config: AppBannerConfig) => {
    const padLineToLength = (line: string, padding: number, length: number) => {
        return "".concat(...[" ".repeat(padding), line.trimEnd().padEnd(length, " "), " ".repeat(padding)]);
    };

    const padding = 4;
    const bannerLength = Math.max(43, Math.max(...config.banner.split("\n").map(x => x.trimEnd().length)));
    const paddedBannerLines = config.banner.split("\n").map(x => padLineToLength(x, padding, bannerLength));
    paddedBannerLines.push(padLineToLength("", padding, bannerLength));
    config.lastBuildTime &&
        paddedBannerLines.push(padLineToLength(`Build date: ${config.lastBuildTime}`, padding, bannerLength));
    config.buildSHA && paddedBannerLines.push(padLineToLength(`Build SHA: ${config.buildSHA}`, padding, bannerLength));
    config.translationVersion &&
        paddedBannerLines.push(padLineToLength(`I18N: ${config.translationVersion ?? ""}`, padding, bannerLength));
    paddedBannerLines.push(padLineToLength("", padding, bannerLength));
    console.log(
        `%c
${paddedBannerLines.join("\n")}`,
        "background-color:#445591;color:white;font-family:monospace;white-space:pre"
    );
};

export const betaBanner = `
        _ _    _ _ _               _            
       (_) |  | (_) |             | |           
  _ __  _| | _| |_| |__         __| | _____   __
 | '_ \\| | |/ / | | '_ \\       / _\` |/ _ \\ \\ / /
 | |_) | |   <| | | |_) |  _  | (_| |  __/\\ V / 
 | .__/|_|_|\\_\\_|_|_.__/  (_)  \\__,_|\\___| \\_/  
 | |                                            
 |_|                                            
`;

export const prodBanner = `
        _ _    _ _ _                               
       (_) |  | (_) |                              
  _ __  _| | _| |_| |__         ___ ___  _ __ ___  
 | '_ \\| | |/ / | | '_ \\       / __/ _ \\| '_ \` _ \\ 
 | |_) | |   <| | | |_) |  _  | (_| (_) | | | | | |
 | .__/|_|_|\\_\\_|_|_.__/  (_)  \\___\\___/|_| |_| |_|
 | |                                               
 |_|                                               `;
