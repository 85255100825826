import * as React from "react";
import { SVGProps } from "react";

const SvgIconMoreVert = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <circle cx={12} cy={7} r={2} fill="currentColor" />
        <circle cx={12} cy={17} r={2} fill="currentColor" />
    </svg>
);

export default SvgIconMoreVert;
