import { Flex } from "@components/Flex";
import styled from "styled-components";
import { Element } from "@components/Element";

export const Main = styled(Element).attrs({
    as: "main",
})`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh - 84px);
    margin-top: 84px;
`;

export const AuthMain = styled(Flex.Container).attrs({
    as: "main",
    $alignItems: "center",
    $justifyContent: "center",
})`
    min-height: calc(100vh - 46px);
    padding: 20px;
    background: ${props => props.theme.auth.background};
`;

export const SearchMain = styled.main`
    width: 100%;
    max-width: 1600px;
    min-height: calc(100vh - 46px);
    padding: 50px 0;
    margin: 46px auto 0;
`;
