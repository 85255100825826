import React from "react";
import { AppPath, Paths } from "@pages/paths";
import { connect, batch, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LoadingOverlay from "@components/LoadingOverlay";
import { AuthActions } from "@redux/authSlice";
import { IncomingEvent, OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import { AppStateActions } from "@redux/appStateSlice";
import { AppConfiguration } from "@redux/appStateSlice";
import { Api } from "@api/Api";
import { Asset, AssetType, loginWithSignatureVariables } from "@api/graphql/types";
import { Log } from "@utils/Log";
import { FolderActions } from "@redux/folderSlice";
import { ApiError } from "@api/ApiError";
import { ContentActions } from "@redux/contentActions";
import { ObjectUtils } from "@utils/ObjectUtils";
import { AssetActions } from "@redux/assetSlice";

enum PossibleInitError {
    NoTokenProvided,
}

type StateType = {
    errorType: PossibleInitError | null;
};

type Props = ReduxProps & RouteComponentProps;

type InitOptionsEvent = {
    type: string;
    event: IncomingEvent;
    credentials: loginWithSignatureVariables;
    options: Partial<AppConfiguration>;
};

class EmbeddedStartPageComponent extends React.Component<Props, StateType> {
    state: StateType = {
        errorType: null,
    };

    componentDidMount() {
        window.addEventListener("message", this.embeddedMessageListener);
        sendPMEvent(OutgoingEvent.LoadComplete);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.embeddedMessageListener);
    }

    private readonly fetchSelectedAsset = async (fileName: string): Promise<Asset | null> => {
        try {
            return await Api.getAssetByFileName(fileName);
        } catch (error) {
            Log.error(error as ApiError);
            return null;
        }
    };

    private embeddedMessageListener = (messageEvent: MessageEvent) => {
        const receivedMessage = messageEvent.data || "{}";
        if (process.env.NODE_ENV === "development") {
            console.dir(messageEvent);
            console.log(receivedMessage);
        }
        try {
            let parsedMessage: InitOptionsEvent | null;
            try {
                parsedMessage = JSON.parse(receivedMessage) as InitOptionsEvent;
            } catch (e) {
                return;
            }
            const { type, event, credentials, options } = parsedMessage;
            if (type !== "bfmlmsg") {
                return;
            }
            switch (event) {
                case IncomingEvent.InitOptions: {
                    this.props.loginWithSignature(credentials).then(() => this.setupInitialOptions(options));
                    break;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    private setupInitialOptions = async (options: Partial<AppConfiguration>) => {
        const asset = options.selectedFile ? await this.fetchSelectedAsset(options.selectedFile) : null;
        const { maximumSelectableAsset, ...appConfig } = options;
        const assetTypeFilter = ObjectUtils.isEnumContains<AssetType>(AssetType, options.initialAssetTypeFilter)
            ? options.initialAssetTypeFilter
            : null;

        batch(() => {
            this.props.setAppConfiguration(appConfig);
            this.props.setMaximumSelectableAsset(maximumSelectableAsset ?? 0);
            asset && this.props.setCurrentFolder(asset.directory?.id);
            assetTypeFilter && this.props.setAssetTypeFilter(assetTypeFilter);
        });

        this.props.history.replace(asset && asset.directory ? Paths.Folder(asset.directory.id) : AppPath.Home);
    };

    public render(): JSX.Element {
        switch (this.state.errorType) {
            default: {
                return <LoadingOverlay />;
            }
        }
    }
}

const mapDispatchToProps = {
    loginWithSignature: AuthActions.loginWithSignature,
    setAppConfiguration: AppStateActions.setAppConfiguration,
    setCurrentFolder: FolderActions.setCurrentFolder,
    setMaximumSelectableAsset: ContentActions.setMaximumSelectableAsset,
    setAssetTypeFilter: AssetActions.setTypeFilter,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const EmbeddedStartPage = withRouter(connector(EmbeddedStartPageComponent));
