import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import Modal from "@components/Modal";
import { AppPath } from "@pages/paths";
import { AuthActions, isLoggedIn } from "@redux/authSlice";
import { RootState } from "@redux/store";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

interface RouteProps {
    authToken?: string;
}

type Props = ReduxProps & RouteComponentProps<RouteProps> & WithLoctoolProps;

class LoginAuthTokenPageComponent extends React.Component<Props> {
    private authToken: string | null = new URLSearchParams(this.props.location.search).get("authToken");

    public componentDidMount(): void {
        if (this.authToken) {
            this.props.setAuthToken(this.authToken);
        }
    }

    public render(): React.ReactElement {
        if (this.props.isLoggedIn) {
            return <Redirect to={AppPath.Home} />;
        }

        if (this.authToken === null) {
            return (
                <Modal
                    heading={this.props.Loctool.formatMessage({ id: "error.auth.title" })}
                    mounted={!this.props.isLoggedIn}
                >
                    <div>
                        <p>
                            <LoctoolMessage id="error.auth.description" />
                        </p>
                    </div>
                </Modal>
            );
        }
        return <>helo</>;
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = {
    setAuthToken: AuthActions.setAuthToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const LoginAuthTokenPage = withLoctool(connector(LoginAuthTokenPageComponent));
