import * as React from "react";
import { SVGProps } from "react";

const SvgIconCopy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect x={8.33325} y={7.6665} width={8} height={8.66667} rx={3} stroke="currentColor" strokeWidth={2} />
        <path
            d="M9.66675 4.14269V4.14269C9.66675 2.77513 8.55812 1.6665 7.19056 1.6665H4.66675C3.00989 1.6665 1.66675 3.00965 1.66675 4.6665V7.33317C1.66675 8.99002 3.00989 10.3332 4.66675 10.3332H5.00008"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconCopy;
