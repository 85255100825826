import { AnyAssetFolder } from "@api/ApiTypes";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Breadcrumb } from "@components/Breadcrumb";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Menu, MenuItem } from "@components/Menu";
import SvgIconArrowChevronDown from "@components/svg/IconArrowChevronDown";
import SvgIconArrowRightSmall from "@components/svg/IconArrowRightSmall";
import SvgIconHome from "@components/svg/IconHome";
import { Text } from "@components/Text";
import { Paths } from "@pages/paths";
import { AlertActions } from "@redux/alertSlice";
import { FolderActions } from "@redux/folderSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

type PropType = RouteComponentProps<{ id: string | undefined }> & ReduxProps & WithLoctoolProps;

class BreadcrumbBar extends React.Component<PropType> {
    componentDidMount() {
        this.props.fetchBreadcrumbs(this.props.match.params.id);
    }

    componentDidUpdate(prevProps: PropType) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.fetchBreadcrumbs(this.props.match.params.id);
        }
    }

    renderItems = () => {
        const { Loctool } = this.props;

        return this.props.breadcrumbs.map(
            (folder: AnyAssetFolder, i): React.ReactElement => {
                return (
                    <React.Fragment key={folder.id}>
                        {i + 1 === this.props.breadcrumbs.length ? (
                            <BreadcrumbItem isActive>
                                <Menu
                                    transition
                                    menuButton={
                                        <a
                                            onClick={() => {
                                                this.props.history.push(Paths.Folder(folder.id));
                                            }}
                                        >
                                            <SvgIconArrowRightSmall width={14} height={14} />
                                            <Text
                                                as="span"
                                                variant="common"
                                                lineClamp={1}
                                                $style={{ maxWidth: 160, margin: "0 5px", fontWeight: 500 }}
                                                title={folder.name}
                                            >
                                                {folder.name}
                                            </Text>
                                            <Element
                                                as={SvgIconArrowChevronDown}
                                                $style={{ minWidth: 10, width: 10, height: 7 }}
                                            />
                                        </a>
                                    }
                                >
                                    <MenuItem>
                                        <Button.Menu
                                            label={Loctool.formatMessage({ id: "components.folders.options.rename" })}
                                            onClick={() => {
                                                this.props.toggleRenameFolderModal(folder);
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <Button.Menu
                                            label={Loctool.formatMessage({ id: "components.folders.options.move" })}
                                            onClick={() => {
                                                this.props.toggleMoveFolderModal(folder);
                                            }}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <Button.Menu
                                            label={Loctool.formatMessage({ id: "components.folders.options.delete" })}
                                            onClick={() => {
                                                if (folder.deletable) {
                                                    this.props.toggleDeleteFolderModal(folder);
                                                } else {
                                                    this.props.alertInfo({
                                                        message: Loctool.formatMessage({
                                                            id: "warning.folderNotDeletable",
                                                        }),
                                                    });
                                                }
                                            }}
                                        />
                                    </MenuItem>
                                </Menu>
                            </BreadcrumbItem>
                        ) : (
                            <BreadcrumbItem>
                                <a
                                    onClick={() => {
                                        this.props.history.push(Paths.Folder(folder.id));
                                    }}
                                >
                                    <SvgIconArrowRightSmall width={14} height={14} />
                                    <Text
                                        as="span"
                                        variant="common"
                                        lineClamp={1}
                                        $style={{ maxWidth: 160, marginLeft: 5, fontWeight: 500 }}
                                    >
                                        {folder.name}
                                    </Text>
                                </a>
                            </BreadcrumbItem>
                        )}
                    </React.Fragment>
                );
            }
        );
    };

    render() {
        return (
            <Breadcrumb ariaLabel="Breadcrumb">
                <li>
                    <Button.Icon
                        label={this.props.Loctool.formatMessage({ id: "components.appBar.tabs.folders" })}
                        inverse
                        icon={<SvgIconHome width={16} height={16} />}
                        onClick={() => this.props.history.push(Paths.Home)}
                    />
                </li>
                {this.props.isLoading ? (
                    <Text as="li" variant="common" lineClamp={1} $style={{ maxWidth: 160, margin: "0 5px" }}>
                        <LoctoolMessage id="common.loading" />
                    </Text>
                ) : (
                    this.renderItems()
                )}
            </Breadcrumb>
        );
    }
}

const BreadcrumbItem = styled.li<{ isActive?: boolean }>`
    color: ${props => (props.isActive ? props.theme.breadcrumb.active.color : props.theme.breadcrumb.color)};
`;

const mapStateToProps = (state: RootState) => ({
    breadcrumbs: state.folders.breadcrumbs,
    isLoading: state.folders.isBreadcrumbsLoading,
});

const mapDispatchToProps = {
    fetchBreadcrumbs: FolderActions.fetchBreadcrumbs,
    toggleRenameFolderModal: ModalActions.toggleRenameFolderModal,
    toggleDeleteFolderModal: ModalActions.toggleDeleteFolderModal,
    toggleMoveFolderModal: ModalActions.toggleMoveFolderModal,
    alertInfo: AlertActions.info,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default withLoctool(withRouter(connector(BreadcrumbBar)));
