import { ApiError } from "@api/ApiError";
import { LoctoolTranslation, Language } from "@bigfish/react-loctool";

export enum Locale {
    hu = "hu",
    en = "en",
}

export const messages: LoctoolTranslation = {
    [Language.HU]: require("./locales/hu").localeHU,
    [Language.EN]: require("./locales/en").localeEN,
};

export const localeToLang = (locale: Locale): Language => {
    switch (locale) {
        case Locale.hu:
            return Language.HU;
        case Locale.en:
            return Language.EN;
        default:
            return Language.HU;
    }
};

export const getMessageIdFromError = (error: ApiError | Error) =>
    error instanceof ApiError ? error.intlKey : error.message;
