import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertOptions, AlertPayload, AlertType } from "@components/Alert/AlertTypes";
import { ApiError } from "@api/ApiError";
import { getMessageIdFromError } from "@i18n/messages";

export type AlertState = AlertOptions[];

const initialState: AlertState = [];

const alertDefaults: Partial<AlertPayload> = {
    timeout: undefined,
    callback: undefined,
    translate: false,
};

const alertSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        show(state, { payload: alert }: PayloadAction<AlertOptions>) {
            state.push(alert);
        },
        hide(state) {
            state.shift();
        },
        success(state, { payload: alert }: PayloadAction<AlertPayload>) {
            state.push({
                ...alertDefaults,
                ...alert,
                type: AlertType.success,
            });
        },
        info(state, { payload: alert }: PayloadAction<AlertPayload>) {
            state.push({
                ...alertDefaults,
                ...alert,
                type: AlertType.info,
            });
        },
        error(state, { payload: alert }: PayloadAction<AlertPayload>) {
            state.push({
                ...alertDefaults,
                ...alert,
                type: AlertType.error,
            });
        },
        parseError(state, { payload: error }: PayloadAction<ApiError | Error>) {
            state.push({
                ...alertDefaults,
                message: getMessageIdFromError(error),
                translate: true,
                type: AlertType.error,
            });
        },
    },
});

export const AlertActions = alertSlice.actions;

export const AlertReducer = alertSlice.reducer;
