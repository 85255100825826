import set from "lodash/set";
import { produce } from "immer";
import { AnyAssetFolder } from "@api/ApiTypes";
import { withLoctool, WithLoctoolProps, useLoctool } from "@bigfish/react-loctool";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import SvgIconActionMinus from "@components/svg/IconActionMinus";
import SvgIconActionPlus from "@components/svg/IconActionPlus";
import SvgIconFilledFolder from "@components/svg/IconFilledFolder";
import { Text } from "@components/Text";
import { hexToRGBA, Transition } from "@theme/Theme";
import React from "react";
import styled from "styled-components";

type Props = {
    items: AnyAssetFolder[];
    isLoading?: boolean;
    onOpenMenu?: (item: AnyAssetFolder) => void;
    onClick?: (item: AnyAssetFolder) => void;
    activeOnInit?: string;
} & WithLoctoolProps;

type TreeItem = {
    isOpened: boolean;
    isActive: boolean;
    id: string;
    label: string;
    children: TreeItem[];
    path: number[];
    __assetFolder?: AnyAssetFolder;
};

interface State {
    items: TreeItem[];
    isLoading: boolean;
}

class FolderTreeComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: FolderTreeComponent.getTreeItemsFromAssetFolders(props.items, undefined, this.props.Loctool),
            isLoading: false,
        };
    }

    static getDerivedStateFromProps = (props: Props, state: State) => {
        return {
            items: FolderTreeComponent.getTreeItemsFromAssetFolders(props.items, state?.items, props.Loctool),
            isLoading: props.isLoading || false,
        };
    };

    private readonly toggleMenuOpen = (item: TreeItem): void => {
        if (item.children.length === 0 || item.path.length === 0) {
            return;
        }
        const producer = produce(items => set(items, `[${item.path.join("].children[")}].isOpened`, !item.isOpened));
        this.setState({ items: producer(this.state.items) });
        this.props.onOpenMenu && this.props.onOpenMenu(item.__assetFolder!);
    };

    private static readonly getTreeItemsFromAssetFolders = (
        items: AnyAssetFolder[],
        prevItems?: TreeItem[],
        Loctool?: ReturnType<typeof useLoctool>,
        path: number[] = []
    ): TreeItem[] => {
        return items.map((item: AnyAssetFolder, key: number) => {
            let children: TreeItem[] = [];
            const itemPath = [...path, key];

            if (Array.isArray(item.children)) {
                children = FolderTreeComponent.getTreeItemsFromAssetFolders(
                    item.children,
                    prevItems ? prevItems[key]?.children : undefined,
                    Loctool,
                    itemPath
                );
            } else if (item.children === undefined) {
                if (item.childrenCount === 0) {
                    children = [];
                } else {
                    children = [
                        {
                            id: "",
                            label: Loctool?.formatMessage({ id: "common.loading" }) ?? "",
                            children: [],
                            isOpened: false,
                            isActive: false,
                            path: [],
                        },
                    ];
                }
            }

            return {
                isOpened: prevItems ? (prevItems[key]?.isActive ? false : prevItems[key]?.isOpened) : false,
                isActive: false,
                id: item.id,
                label: item.name,
                children,
                path: itemPath,
                __assetFolder: item,
            };
        });
    };

    private readonly renderTreeItems = (items: TreeItem[], level: number = 1): React.ReactElement[] => {
        return items.map(
            (item: TreeItem): React.ReactElement => {
                const hasChild = item.children.length > 0;
                return (
                    <React.Fragment key={`lv_${level}_${item.id}`}>
                        <StyledFolderTreeItem
                            level={level - 1}
                            className={this.props.activeOnInit === item.id ? "active" : ""}
                            opened={item.isOpened}
                            onClick={() => {
                                if (!item.isOpened) {
                                    this.toggleMenuOpen(item);
                                }
                                if (this.props.onClick) {
                                    this.props.onClick(item.__assetFolder!);
                                }
                            }}
                            onDoubleClick={() => this.toggleMenuOpen(item)}
                        >
                            {hasChild && (
                                <FolderTreeItemFoldable hasChildren={true} onClick={() => this.toggleMenuOpen(item)}>
                                    {item.isOpened ? (
                                        <Element
                                            as={SvgIconActionMinus}
                                            $style={{ minWidth: 10, width: 10, height: 10 }}
                                        />
                                    ) : (
                                        <Element
                                            as={SvgIconActionPlus}
                                            $style={{ minWidth: 10, width: 10, height: 10 }}
                                        />
                                    )}
                                </FolderTreeItemFoldable>
                            )}
                            <FolderTreeItemLabel>
                                {!hasChild && (
                                    <Element
                                        as={SvgIconFilledFolder}
                                        $style={{ minWidth: 14, width: 14, height: 14 }}
                                    />
                                )}
                                <Text
                                    as="span"
                                    variant="common"
                                    lineClamp={1}
                                    $style={{ width: "100%", marginTop: 2, marginLeft: hasChild ? 1 : 5 }}
                                    title={item.label}
                                >
                                    {item.label}
                                </Text>
                            </FolderTreeItemLabel>
                        </StyledFolderTreeItem>
                        {item.isOpened && item.children.length > 0 && this.renderTreeItems(item.children, level + 1)}
                    </React.Fragment>
                );
            }
        );
    };

    public render(): React.ReactElement {
        return (
            <Element as="ul" $style={{ width: "100%" }}>
                {this.renderTreeItems(this.state.items)}
            </Element>
        );
    }
}

const StyledFolderTreeItem = styled(Flex.Container).attrs({
    as: "li",
    $alignItems: "center",
})<{ level: number; opened: boolean }>`
    width: 100%;
    height: 42px;
    padding-right: 22px;
    padding-left: ${props => props.level * 20 + 20}px;
    transition: color ${Transition.common}, background ${Transition.common};
    cursor: pointer;

    &.active {
        background: ${props => props.theme.sidebar.folder.active.background};
        color: ${props => props.theme.sidebar.folder.active.color};

        ${Text} {
            color: ${props => props.theme.sidebar.folder.active.color};
        }

        &:hover {
            background: ${props => props.theme.sidebar.folder.active.background};
        }
    }

    &:hover {
        background: ${props => hexToRGBA(props.theme.sidebar.folder.active.background, 0.1)};
    }
`;
const FolderTreeItemFoldable = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})<{ hasChildren?: boolean }>`
    width: 18px;
    height: 12px;
    padding: 0px 8px 0 4px;
    z-index: 10;
`;

const FolderTreeItemLabel = styled(Flex.Container).attrs({
    $alignItems: "center",
})`
    max-width: calc(100% - 22px);
    user-select: none;
`;

export const FolderTree = withLoctool(FolderTreeComponent);
