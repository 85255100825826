class ApiError extends Error {
    public readonly intlKey: string;
    public readonly code: ApiErrorCode;

    constructor(message: ApiErrorCode) {
        super(message);
        this.intlKey = `error.api.${message}`;
        this.code = message;
    }
}

/**
 * Error messages from Api excluded 'E_' started codes
 */
enum ApiErrorCode {
    UNKNOWN = "UNKNOWN",
    UNAUTHENTICATED = "UNAUTHENTICATED",
    UNAUTHORIZED = "UNAUTHORIZED",
    NETWORK_ERROR = "NETWORK_ERROR",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    INVALID_RESPONSE = "INVALID_RESPONSE",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    INVALID_EMBED_CONFIG = "INVALID_EMBED_CONFIG",
    ASSET_NOT_FOUND = "ASSET_NOT_FOUND",
    ASSET_DIRECTORY_NOT_FOUND = "ASSET_DIRECTORY_NOT_FOUND",
    ASSET_DIRECTORY_INVALID_PARENT = "ASSET_DIRECTORY_INVALID_PARENT",
    ASSET_DIRECTORY_NOT_EMPTY = "ASSET_DIRECTORY_NOT_EMPTY",
    ASSET_DIRECTORY_TOO_DEEP = "ASSET_DIRECTORY_TOO_DEEP",
    ASSET_DIRECTORY_ALREADY_EXISTS = "ASSET_DIRECTORY_ALREADY_EXISTS",
    FILE_TOO_BIG = "FILE_TOO_BIG",
    STORAGE_SIZE_LIMIT_EXCEEDED = "STORAGE_SIZE_LIMIT_EXCEEDED",
    PROJECT_ID_MISSING_OR_NOT_ENOUGH_PERMISSION = "PROJECT_ID_MISSING_OR_NOT_ENOUGH_PERMISSION",
    PROJECT_NAMESPACE_IS_MISSING = "PROJECT_NAMESPACE_IS_MISSING",
    RECOVERY_TOKEN_INVALID_OR_EXPIRED = "RECOVERY_TOKEN_INVALID_OR_EXPIRED",
    EMAIL_SERVICE_ERROR = "EMAIL_SERVICE_ERROR",
    ASSET_EXPORT_JOB_NOT_FOUND = "ASSET_EXPORT_JOB_NOT_FOUND",
    ASSET_EXPORT_JOB_IN_INVALID_STATUS = "ASSET_EXPORT_JOB_IN_INVALID_STATUS",
    VALIDATION = "VALIDATION",
    WEAK_PASSWORD = "WEAK_PASSWORD",
}

export { ApiError, ApiErrorCode };
