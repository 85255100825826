import * as React from "react";
import { SVGProps } from "react";

const SvgIconSettings = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={26}
        height={26}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <circle cx={13} cy={13} r={4} stroke="currentColor" strokeWidth={2} />
        <path
            d="M11.2844 2.86293C12.061 1.56696 13.939 1.56696 14.7156 2.86293L15.4213 4.0407C15.8742 4.79649 16.7684 5.16686 17.6231 4.95268L18.9549 4.61892C20.4204 4.25166 21.7483 5.57958 21.3811 7.0451L21.0473 8.37695C20.8331 9.23162 21.2035 10.1258 21.9593 10.5787L23.1371 11.2844C24.433 12.061 24.433 13.939 23.1371 14.7156L21.9593 15.4213C21.2035 15.8742 20.8331 16.7684 21.0473 17.6231L21.3811 18.9549C21.7483 20.4204 20.4204 21.7483 18.9549 21.3811L17.6231 21.0473C16.7684 20.8331 15.8742 21.2035 15.4213 21.9593L14.7156 23.1371C13.939 24.433 12.061 24.433 11.2844 23.1371L10.5787 21.9593C10.1258 21.2035 9.23162 20.8331 8.37695 21.0473L7.04509 21.3811C5.57958 21.7483 4.25166 20.4204 4.61892 18.9549L4.95268 17.6231C5.16686 16.7684 4.79649 15.8742 4.04069 15.4213L2.86293 14.7156C1.56696 13.939 1.56696 12.061 2.86293 11.2844L4.0407 10.5787C4.79649 10.1258 5.16686 9.23162 4.95268 8.37695L4.61892 7.04509C4.25166 5.57958 5.57958 4.25166 7.0451 4.61892L8.37695 4.95268C9.23162 5.16686 10.1258 4.79649 10.5787 4.04069L11.2844 2.86293Z"
            stroke="currentColor"
            strokeWidth={2}
        />
    </svg>
);

export default SvgIconSettings;
