import { Asset } from "@api/graphql/types";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import SvgIconCircleActionsClose from "@components/svg/IconCircleActionsClose";
import SvgIconCopy from "@components/svg/IconCopy";
import SvgIconDelete from "@components/svg/IconDelete";
import SvgIconDownload from "@components/svg/IconDownload";
import SvgIconGridFeedCards from "@components/svg/IconGridFeedCards";
import { Text } from "@components/Text";
import { isEmbeddedView } from "@redux/appStateSlice";
import { AssetExportActions } from "@redux/assetExportSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import { zIndex } from "@theme/Theme";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import { download } from "@utils/Helpers";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";

type Props = {
    selectedAssets: Asset[];
    isEmbeddedView: boolean;
    maxSelectableAsset: number;
    insertButtonEnabled: boolean;
    refreshAssets: () => void;
    selectAllAssets: () => void;
    clearSelectedAssets: () => void;
} & ReduxProps &
    WithLoctoolProps;

class ActionBarComponent extends React.Component<Props> {
    private async downloadAssets(assets: Asset[]) {
        if (assets.length > 1) {
            this.props.createJob(assets.map(a => a.id));
        } else {
            download(`${assets[0].url}?download=1`);
        }
    }

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <StyledActionBar>
                <Text
                    as="span"
                    variant="linkXSmall"
                    $style={{
                        marginRight: 10,
                        color: "inherit",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                    }}
                >
                    <LoctoolMessage
                        id="components.actionBar.selected"
                        values={{ number: this.props.selectedAssets.length }}
                    />
                </Text>
                <Flex.Container $flexWrap="wrap">
                    {this.props.appConfiguration.deleteButtonEnabled && (
                        <Button.Action
                            label={Loctool.formatMessage({ id: "components.actionBar.delete" })}
                            startIcon={<SvgIconDelete />}
                            onClick={() => this.props.toggleDeleteModal(this.props.selectedAssets)}
                        />
                    )}
                    <Button.Action
                        label={Loctool.formatMessage({ id: "components.actionBar.move" })}
                        startIcon={<SvgIconCopy />}
                        onClick={() => this.props.toggleMoveModal(this.props.selectedAssets)}
                    />
                    <Button.Action
                        label={Loctool.formatMessage({ id: "components.actionBar.download" })}
                        startIcon={<SvgIconDownload />}
                        onClick={() => this.downloadAssets(this.props.selectedAssets)}
                    />
                    {this.props.maxSelectableAsset === 0 && (
                        <Button.Action
                            label={Loctool.formatMessage({ id: "components.actionBar.selectAll" })}
                            startIcon={<SvgIconGridFeedCards />}
                            onClick={this.props.selectAllAssets}
                        />
                    )}
                    <Button.Action
                        label={Loctool.formatMessage({ id: "components.actionBar.clearAll" })}
                        startIcon={<SvgIconCircleActionsClose />}
                        onClick={() => this.props.clearSelectedAssets()}
                    />
                </Flex.Container>
                {this.props.isEmbeddedView && this.props.insertButtonEnabled && (
                    <Button.PrimaryAction
                        label={
                            this.props.selectButtonTitle ?? Loctool.formatMessage({ id: "components.actionBar.insert" })
                        }
                        onClick={() => {
                            sendPMEvent(OutgoingEvent.ItemsSelected, {
                                assets: this.props.selectedAssets.map(({ __typename, directory, ...asset }) => asset),
                            });
                        }}
                    />
                )}
            </StyledActionBar>
        );
    }
}
const StyledActionBar = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
    $justifyContent: "space-between",
}))`
    position: sticky;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 5px 20px 10px;
    margin-top: auto;
    color: ${props => props.theme.actionBar.color};
    background: ${props => props.theme.actionBar.background};
    box-shadow: ${props => props.theme.actionBar.boxShadow};
    overflow: hidden;
    z-index: ${zIndex.actionBar};
`;

const mapStateToProps = (state: RootState) => ({
    isEmbeddedView: isEmbeddedView(state),
    insertButtonEnabled: !!state.appState.appConfiguration.insertButtonEnabled,
    selectButtonTitle: state.appState.appConfiguration.selectButtonTitle,
    appConfiguration: state.appState.appConfiguration,
});

const mapDispatchToProps = {
    toggleMoveModal: ModalActions.toggleMoveModal,
    toggleDeleteModal: ModalActions.toggleDeleteModal,
    createJob: AssetExportActions.createJob,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const ActionBar = withLoctool(connector(ActionBarComponent));
