import { configureStore, AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AlertReducer } from "./alertSlice";
import { AppStateReducer } from "./appStateSlice";
import { AssetExportReducer } from "./assetExportSlice";
import { AssetReducer } from "./assetSlice";
import { AssetUploadReducer } from "./assetUploadSlice";
import { AuthReducer } from "./authSlice";
import { FolderReducer } from "./folderSlice";
import { ModalReducer } from "./modalSlice";
import { SearchReducer } from "./searchSlice";

export const store = configureStore({
    reducer: {
        appState: AppStateReducer,
        auth: AuthReducer,
        assets: AssetReducer,
        search: SearchReducer,
        folders: FolderReducer,
        alerts: AlertReducer,
        modals: ModalReducer,
        assetExport: AssetExportReducer,
        assetUpload: AssetUploadReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
