import { Api } from "@api/Api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import { PromisePool } from "@supercharge/promise-pool";

export type UploadStatus = {
    key: string;
    fileName: string;
    src: string;
    type: string;
    progress: number;
    isUploaded: boolean;
    error?: boolean;
};

type AssetUpload = {
    uploads: UploadStatus[];
};

const initialState: AssetUpload = {
    uploads: [],
};

const assetUploadSlice = createSlice({
    name: "assetUpload",
    initialState,
    reducers: {
        setUploads(state, { payload: uploads }: PayloadAction<UploadStatus[]>) {
            state.uploads = uploads;
        },
        clearUploads(state) {
            state.uploads = [];
        },
        setProgress(state, { payload }: PayloadAction<{ progress: number; key: string }>) {
            const upload = state.uploads.find(u => u.key === payload.key);
            if (upload) {
                upload.progress = payload.progress;
            }
        },
        setUploaded(state, { payload: key }: PayloadAction<string>) {
            const upload = state.uploads.find(u => u.key === key);
            if (upload) {
                upload.isUploaded = true;
            }
        },
        setError(state, { payload }: PayloadAction<{ key: string; error?: boolean }>) {
            const upload = state.uploads.find(u => u.key === payload.key);
            if (upload) {
                upload.error = !!payload.error;
            }
        },
    },
});

const uploadAssets = (files: File[]): AppThunk => async (dispatch, getState) => {
    const { currentFolder } = getState().folders;

    const uploads = files.map(file => ({
        key: `${file.name}_${file.size}`,
        fileName: file.name,
        src: URL.createObjectURL(file),
        type: file.type,
        progress: 0,
        isUploaded: false,
    }));

    dispatch(AssetUploadActions.setUploads(uploads));

    PromisePool.for(files)
        .withConcurrency(4)
        .handleError((_, file) => {
            const key = `${file.name}_${file.size}`;
            dispatch(AssetUploadActions.setError({ key, error: true }));
        })
        .process(async file => {
            const key = `${file.name}_${file.size}`;
            await Api.uploadAsset(file.name, file, currentFolder || null);
            dispatch(AssetUploadActions.setUploaded(key));
        });
};

export const AssetUploadActions = { ...assetUploadSlice.actions, uploadAssets };

export const AssetUploadReducer = assetUploadSlice.reducer;
