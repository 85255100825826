import * as React from "react";
import { SVGProps } from "react";

const SvgIconLockUnlocked = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect x={2.25} y={8.25} width={13.5} height={9} rx={2.25} stroke="currentColor" strokeWidth={1.5} />
        <path
            d="M4.5 4.5C4.5 4.91421 4.83579 5.25 5.25 5.25C5.66421 5.25 6 4.91421 6 4.5H4.5ZM9 0.75V0V0.75ZM6 4.5C6 3.70435 6.31607 2.94129 6.87868 2.37868L5.81802 1.31802C4.97411 2.16193 4.5 3.30653 4.5 4.5H6ZM6.87868 2.37868C7.44129 1.81607 8.20435 1.5 9 1.5V0C7.80653 0 6.66193 0.474106 5.81802 1.31802L6.87868 2.37868ZM9 1.5C9.79565 1.5 10.5587 1.81607 11.1213 2.37868L12.182 1.31802C11.3381 0.474106 10.1935 0 9 0V1.5ZM11.1213 2.37868C11.6839 2.94129 12 3.70435 12 4.5H13.5C13.5 3.30653 13.0259 2.16193 12.182 1.31802L11.1213 2.37868ZM12 4.5V8.25009H13.5V4.5H12Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconLockUnlocked;
