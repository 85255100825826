import * as React from "react";
import { SVGProps } from "react";

const SvgIconCircleActionsSuccess = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <g opacity={0.5}>
            <circle cx={12} cy={12} r={11} stroke="currentColor" strokeWidth={2} />
            <path
                d="M9 11.9497L11.1213 14.071L15.364 9.82839"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </g>
    </svg>
);

export default SvgIconCircleActionsSuccess;
