import * as React from "react";
import { SVGProps } from "react";

const SvgIconArrowChevronForward = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={16}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M6.25 3.625L10.602 7.97698L6.25 12.329"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconArrowChevronForward;
