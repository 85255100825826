import { LoctoolProvider } from "@bigfish/react-loctool";
import { Locale, localeToLang, messages } from "@i18n/messages";
import { store } from "@redux/store";
import { GlobalStyle } from "@theme/GlobalStyle";
import { DarkTheme, Theme } from "@theme/Theme";
import { Cookie } from "@utils/Cookie";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { App } from "./App";

interface Props {}

interface State {
    theme: "light" | "dark";
}

class AppContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            theme: "light",
        };
    }

    componentDidMount() {
        const prefersDarkScheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
        const betaEnvironment = process.env.REACT_APP_DEPLOYMENT_ENV === "beta";

        if (prefersDarkScheme && betaEnvironment) {
            this.setState({ theme: "dark" });
        }
    }

    private getTheme = () => {
        if (this.state.theme === "dark") {
            return DarkTheme;
        }

        return Theme;
    };

    public render(): JSX.Element {
        const locale = (Cookie.getLocale() as Locale) || Locale.hu;
        const language = localeToLang(locale);

        return (
            <Provider store={store}>
                <ThemeProvider theme={this.getTheme()}>
                    <LoctoolProvider language={language} messages={messages} isPreviewEnabled={true}>
                        <GlobalStyle />

                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </LoctoolProvider>
                </ThemeProvider>
            </Provider>
        );
    }
}

export { AppContainer };
