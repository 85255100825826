import { Asset } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { CheckboxComponent } from "@components/Checkbox";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { MenuItem } from "@components/Menu";
import { SSkeletonLine } from "@components/Skeleton";
import { RootState } from "@redux/store";
import { Transition } from "@theme/Theme";
import { FileSizeUtils } from "@utils/FileSizeUtils";
import { random } from "@utils/Helpers";
import { MimeType } from "@utils/MimeType";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { getDocumentType } from "./DocTypes";
import {
    AssetContent,
    AssetDetails,
    AssetFormat,
    AssetMenu,
    AssetOptions,
    AssetTitle,
    AssetWrapper,
} from "./ImageTile";

type Props = {
    asset: Asset;
    selected: boolean;
    onSelect: (asset: Asset) => void;
    onShiftSelect?: (asset: Asset) => void;
    onUrlCopy: (url: string) => void;
    onRename: (asset: Asset) => void;
    onMove: (asset: Asset) => void;
    onDelete: (asset: Asset) => void;
    onShow: (asset: Asset) => void;
} & WithLoctoolProps &
    ReduxProps;

type State = {
    isLoading: boolean;
    menuIsOpen: boolean;
};

export class DocumentTileComponent extends React.Component<Props, State> {
    state = {
        isLoading: true,
        menuIsOpen: false,
    };
    private assetWrapperRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    private readonly onClick = (e: React.MouseEvent<HTMLImageElement | HTMLInputElement, MouseEvent>): void => {
        e.shiftKey && this.props.onShiftSelect
            ? this.props.onShiftSelect(this.props.asset)
            : this.props.onSelect(this.props.asset);
    };

    private readonly activateHover = (): void => {
        this.assetWrapperRef.current?.classList.add("hover");
    };

    private readonly deactivateHover = (): void => {
        this.assetWrapperRef.current?.classList.remove("hover");
    };

    render() {
        const { asset, selected, Loctool } = this.props;
        const documentType = getDocumentType(asset.info.mimeType);

        return (
            <AssetWrapper ref={this.assetWrapperRef} $isSelected={selected}>
                <AssetOptions>
                    <CheckboxComponent label="Kiválasztás" onClick={this.onClick} checked={selected} />
                    <Element $style={{ pointerEvents: "all" }}>
                        <AssetMenu
                            onMenuButtonClick={() => this.activateHover()}
                            onClose={() => this.deactivateHover()}
                        >
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.copyUrl" })}
                                    onClick={() => {
                                        this.props.onUrlCopy(asset.url);
                                    }}
                                />
                            </MenuItem>
                            <MenuItem>
                                <Element
                                    as="a"
                                    href={`${asset.url}?download=1`}
                                    download
                                    $style={{ textDecoration: "none" }}
                                >
                                    <Button.Menu
                                        label={Loctool.formatMessage({ id: "components.assets.options.download" })}
                                    />
                                </Element>
                            </MenuItem>
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.rename" })}
                                    onClick={() => {
                                        this.props.onRename(asset);
                                    }}
                                />
                            </MenuItem>
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.move" })}
                                    onClick={() => {
                                        this.props.onMove(asset);
                                    }}
                                />
                            </MenuItem>
                            {this.props.appConfiguration.deleteButtonEnabled && (
                                <MenuItem>
                                    <Button.Menu
                                        label={Loctool.formatMessage({ id: "components.assets.options.delete" })}
                                        onClick={() => {
                                            this.props.onDelete(asset);
                                        }}
                                    />
                                </MenuItem>
                            )}
                        </AssetMenu>
                    </Element>
                </AssetOptions>
                <AssetContent>
                    <AssetTitle>{asset.title}</AssetTitle>
                    <AssetDetails>
                        <AssetFormat>
                            {MimeType.extension(asset.info.mimeType) || Loctool.formatMessage({ id: "common.unknown" })}
                        </AssetFormat>
                        <span className="size">{FileSizeUtils.humanFileSize(asset.fileSize)}</span>
                    </AssetDetails>
                </AssetContent>
                <AssetImage onClick={this.onClick} overlayColor={documentType?.color}>
                    {documentType ? documentType.icon : null}
                </AssetImage>
            </AssetWrapper>
        );
    }
}

export const LoadingAsset = (): React.ReactElement => {
    return (
        <AssetWrapper $isSelected={false}>
            <AssetContent>
                <AssetTitle>
                    <SSkeletonLine width={`${random(50, 120)}px`} height={"12px"} />
                </AssetTitle>
                <AssetDetails>
                    <AssetFormat>
                        <SSkeletonLine />
                    </AssetFormat>
                    <span className="size">
                        <SSkeletonLine width={`${random(30, 50)}px`} height={"11px"} />
                    </span>
                    <span className="dimension">
                        <SSkeletonLine width={"60px"} height={"11px"} />
                    </span>
                </AssetDetails>
            </AssetContent>
            <SSkeletonLine
                height={"100%"}
                width={"100%"}
                style={{ position: "absolute", top: "0px", left: "0px", objectFit: "cover", zIndex: -1 }}
            />
        </AssetWrapper>
    );
};

const AssetImage = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})<{ overlayColor?: string }>`
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 48px;
    border-radius: 10px 10px 15px 15px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${props => `${props.overlayColor}25`};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.overlayColor ?? props.theme.asset.tile.overlayColor};
        opacity: 0;
        transition: opacity ${Transition.common};
        pointer-events: none;
        z-index: 1;
    }

    svg {
        z-index: 2;
    }
`;

export const StyledAssetWrapper = styled(AssetWrapper)<{ $isSelected: boolean }>`
    &:hover,
    &.hover {
        ${AssetImage}::after {
            opacity: 1;
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    appConfiguration: state.appState.appConfiguration,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const DocumentTile = withLoctool(connector(DocumentTileComponent));
