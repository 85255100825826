import { Asset } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { CheckboxComponent } from "@components/Checkbox";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { Menu, MenuItem } from "@components/Menu";
import { SSkeletonLine } from "@components/Skeleton";
import SvgIconMoreVert from "@components/svg/IconMoreVert";
import { RootState } from "@redux/store";
import { Transition } from "@theme/Theme";
import { FileSizeUtils } from "@utils/FileSizeUtils";
import { random } from "@utils/Helpers";
import { MimeType } from "@utils/MimeType";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";

type Props = {
    asset: Asset;
    selected: boolean;
    onSelect: (asset: Asset) => void;
    onShiftSelect?: (asset: Asset) => void;
    onUrlCopy: (url: string) => void;
    onRename: (asset: Asset) => void;
    onMove: (asset: Asset) => void;
    onDelete: (asset: Asset) => void;
} & WithLoctoolProps &
    ReduxProps;

class ImageRowComponent extends React.Component<Props> {
    private readonly onSelect = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        e.shiftKey && this.props.onShiftSelect
            ? this.props.onShiftSelect(this.props.asset)
            : this.props.onSelect(this.props.asset);
    };

    render() {
        const { asset, selected, Loctool } = this.props;
        const imageURL = `${asset.url}?w=${Math.floor(20 * (window.devicePixelRatio ?? 1))}&f=auto`;

        return (
            <StyledAssetRow $isSelected={selected}>
                <td>
                    <Flex.Container $justifyContent="center">
                        <CheckboxComponent label="Kiválasztás" onClick={this.onSelect} checked={selected} />
                    </Flex.Container>
                </td>
                <td>
                    <Menu
                        transition
                        menuButton={
                            <Flex.Container $justifyContent="center">
                                <Button.Icon label="Opciók" icon={<SvgIconMoreVert width={16} height={16} />} />
                            </Flex.Container>
                        }
                    >
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.assets.options.copyUrl" })}
                                onClick={() => {
                                    this.props.onUrlCopy(asset.url);
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <Element
                                as="a"
                                $style={{ textDecoration: "none" }}
                                href={`${asset.url}?download=1`}
                                download
                                style={{ marginTop: 12, textDecoration: "none", display: "flex" }}
                            >
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.download" })}
                                />
                            </Element>
                        </MenuItem>
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.assets.options.rename" })}
                                onClick={() => {
                                    this.props.onRename(asset);
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.assets.options.move" })}
                                onClick={() => {
                                    this.props.onMove(asset);
                                }}
                            />
                        </MenuItem>
                        {this.props.appConfiguration.deleteButtonEnabled && (
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.delete" })}
                                    onClick={() => {
                                        this.props.onDelete(asset);
                                    }}
                                />
                            </MenuItem>
                        )}
                    </Menu>
                </td>
                <td>
                    <Element
                        as="img"
                        $style={{
                            display: "block",
                            minWidth: 30,
                            width: 30,
                            height: 30,
                            margin: "0 10px",
                            borderRadius: 4,
                            objectFit: "cover",
                        }}
                        src={imageURL}
                        alt={asset.fileName}
                    />
                </td>
                <Element
                    as="td"
                    $style={{ padding: "0 20px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                >
                    {asset.title}
                </Element>
                <Element as="td" $style={{ padding: "0 12px" }}>
                    <Flex.Container $alignItems="center">
                        {MimeType.extension(asset.info.mimeType) || Loctool.formatMessage({ id: "common.unknown" })}
                    </Flex.Container>
                </Element>
                <Element as="td" $style={{ padding: "0 12px", textAlign: "right" }}>
                    <span className="dimension">{`${asset.info.width}x${asset.info.height}`}</span>
                </Element>
                <Element as="td" $style={{ padding: "0 12px" }}>
                    {FileSizeUtils.humanFileSize(asset.fileSize)}
                </Element>
            </StyledAssetRow>
        );
    }
}

export const LoadingAssetRow = (): React.ReactElement => {
    return (
        <StyledAssetRow $isSelected={false}>
            <td>
                <SSkeletonLine width={"50px"} />
            </td>
            <td>
                <SSkeletonLine height={"30px"} width={"30px"} />
            </td>
            <td>
                <div>
                    <SSkeletonLine width={`${random(60, 180)}px`} />
                </div>
            </td>
            <td>
                <Flex.Container $alignItems="center">
                    <SSkeletonLine width={`${random(60, 120)}px`} />
                </Flex.Container>
            </td>
            <td>
                <SSkeletonLine width={"50px"} />
            </td>
            <td>
                <span className="dimension">
                    <SSkeletonLine width={"50px"} />
                </span>
            </td>
        </StyledAssetRow>
    );
};

export const StyledAssetRow = styled.tr<{ $isSelected?: boolean }>`
    color: ${props => props.theme.asset.row.color};
    border-bottom: 1px solid ${props => props.theme.asset.row.borderColor};
    transition: background ${Transition.common};
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;

    &:nth-of-type(1) {
        border-top: 1px solid ${props => props.theme.asset.row.borderColor};
    }

    td {
        vertical-align: middle;
        height: 50px;
        white-space: nowrap;
    }

    &:hover {
        background: ${props => props.theme.asset.row.hover.background};
    }

    ${props =>
        props.$isSelected
            ? `
      background: ${props.theme.asset.row.selected.background};
    `
            : ""}
`;

const mapStateToProps = (state: RootState) => ({
    appConfiguration: state.appState.appConfiguration,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const ImageRow = withLoctool(connector(ImageRowComponent));
