import { Api } from "@api/Api";
import { Asset } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import Modal from "@components/Modal";
import { AlertActions } from "@redux/alertSlice";
import React, { FormEvent } from "react";
import { connect, ConnectedProps } from "react-redux";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    refreshAssets: () => void;
    asset: Asset;
} & WithLoctoolProps &
    ReduxProps;

interface State {
    title: string;
    isLoading: boolean;
}

class AssetRenameModalComponent extends React.Component<Props, State> {
    private readonly inputId = "asset-rename-modal-input";
    public state: State = {
        title: this.props.asset.title,
        isLoading: false,
    };

    private readonly onTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    private readonly onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    await Api.updateAsset(this.props.asset.id, this.state.title);
                    this.props.alertSuccess({
                        message: this.props.Loctool.formatMessage({ id: "components.assetRenameModal.updateSuccess" }),
                    });
                    this.props.refreshAssets();
                    this.props.onModalClose();
                } catch (error) {
                    this.props.alertParseError(error as Error);
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    public render(): React.ReactElement {
        const { title } = this.state;
        const { Loctool } = this.props;

        return (
            <Modal
                heading={Loctool.formatMessage({ id: "components.assetRenameModal.title" })}
                mounted={this.props.mounted}
                onModalClose={this.props.onModalClose}
                initialFocus={`#${this.inputId}`}
            >
                <form onSubmit={this.onSubmit}>
                    <Input
                        id={this.inputId}
                        type="text"
                        label={Loctool.formatMessage({ id: "components.assetRenameModal.form.title" })}
                        value={title}
                        onChange={this.onTitleChange}
                    />
                    <Button.Primary
                        label={Loctool.formatMessage({ id: "common.save" })}
                        loading={this.state.isLoading}
                        disabled={!title || title === this.props.asset.title}
                        fullWidth
                        $style={{ marginTop: 24 }}
                        type="submit"
                    />
                </form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    alertSuccess: AlertActions.success,
    alertParseError: AlertActions.parseError,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const AssetRenameModal = withLoctool(connector(AssetRenameModalComponent));
