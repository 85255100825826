import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { Font, Transition } from "@theme/Theme";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";
import React from "react";
import styled, { CSSObject } from "styled-components";

type InputProps = {
    label: string;
    sizeLarge?: boolean;
    startIcon?: React.ReactElement;
    disabled?: boolean;
    hint?: string;
    success?: boolean | string;
    error?: boolean | string;
    $style?: CSSObject;
    className?: string;
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "as">;

export const Input = React.forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { label, sizeLarge, startIcon, disabled, hint, success, error, $style, className, ...otherProps } = props;

    const inputMessage = () => {
        if (typeof success === "string") {
            return success;
        }

        if (typeof error === "string") {
            return error;
        }

        if (!success && !error && hint) {
            return hint;
        }

        return null;
    };

    return (
        <StyledInput className={className} $success={success} $error={error} $style={$style}>
            <InputWrapper $sizeLarge={sizeLarge} $startIcon={startIcon ? true : false}>
                {startIcon && <InputIconWrapper>{startIcon}</InputIconWrapper>}
                <input aria-label={label} ref={ref} disabled={disabled} placeholder={label} {...otherProps} />
                <InputLabel>{label}</InputLabel>
            </InputWrapper>
            {inputMessage() && <InputMessage>{inputMessage()}</InputMessage>}
        </StyledInput>
    );
});

const InputIconWrapper = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 24px;
    color: ${props => props.theme.input.icon.color};
    pointer-events: none;
`;

const InputLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
    color: ${props => props.theme.input.label.color};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    pointer-events: none;
    transition: transform ${Transition.inputLabel}, color ${Transition.common}, font-size ${Transition.inputLabel};
`;

const InputMessage = styled(Text).attrs({
    variant: "textXSmall",
})`
    margin-top: 8px;
    color: ${props => props.theme.input.hint.color};
`;

const InputWrapper = styled.div<{
    $sizeLarge?: boolean;
    $startIcon?: boolean;
    $success?: boolean | string;
    $error?: boolean | string;
    $style?: CSSObject;
}>`
    position: relative;
    height: ${props => (props.$sizeLarge ? 64 : 56)}px;

    input {
        width: 100%;
        height: 100%;
        padding-top: 30px;
        padding-right: 24px;
        padding-bottom: ${props => (props.$sizeLarge ? 12 : 8)}px;
        padding-left: 24px;
        color: ${props => props.theme.input.color};
        background: ${props => props.theme.input.background};
        border: 2px solid ${props => props.theme.input.borderColor};
        border-radius: 16px;
        font-family: ${Font.primary};
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.75px;
        filter: none;
        user-select: none;
        transition: background ${Transition.common}, border-color ${Transition.common};
        user-select: none;

        &::placeholder {
            color: transparent;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            ~ ${InputLabel}, ~ ${InputMessage} {
                opacity: 0.5;
            }

            &[value] {
                ~ ${InputLabel} {
                    transform: translateY(calc(50% - 35px));
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.25px;
                }
            }
        }

        &:not([disabled]) {
            &:focus {
                background: ${props => props.theme.input.focus.background};
                border-color: ${props => props.theme.input.focus.borderColor};

                ~ ${InputLabel} {
                    color: ${props => props.theme.input.label.color};
                }
            }
        }

        &:focus,
        &:not(:placeholder-shown),
        &:-webkit-autofill {
            ~ ${InputLabel} {
                transform: translateY(calc(50% - 35px));
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0.25px;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: ${props => props.theme.input.color};
            -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.input.background} inset;
            border-color: ${props => props.theme.input.focus.borderColor};
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    ${props =>
        props.$startIcon &&
        `
        input {
            padding-left: 72px;
        }

        ${InputLabel} {
            left: 74px;
        }
    `}
`;

const StyledInput = styled.div<{
    $success?: boolean | string;
    $error?: boolean | string;
    $style?: CSSObject;
}>`
    position: relative;
    width: 100%;

    ${props =>
        props.$success &&
        `
        input {
            background-color: ${props.theme.input.success.background};
            border-color: ${props.theme.input.success.borderColor};

            &:focus {
                ~ ${InputMessage} {
                    display: none;
                }
            }
        }

        ${InputLabel},
        ${InputMessage} {
            color: ${props.theme.input.success.color};
        }
    `}

    ${props =>
        props.$error &&
        `
        input {
            background-color: ${props.theme.input.error.background};
            border-color: ${props.theme.input.error.borderColor};

            &:focus {
                ~ ${InputMessage} {
                    display: none;
                }
            }
        }

        ${InputLabel},
        ${InputMessage} {
            color: ${props.theme.input.error.color};
        }
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;
