import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { Menu, MenuItem } from "@components/Menu";
import SvgIconDisabled from "@components/svg/IconDisabled";
import SvgIconFolder from "@components/svg/IconFolder";
import SvgIconMoreVert from "@components/svg/IconMoreVert";
import { Text } from "@components/Text";
import { AlertActions } from "@redux/alertSlice";
import { Transition } from "@theme/Theme";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";

type FolderButtonProps = {
    btnLabel: string;
    isDeletable: boolean;
    disabled?: boolean;
    onClick: () => void;
    onRenameClick: () => void;
    onDeleteClick: () => void;
    onMoveClick: () => void;
} & WithLoctoolProps &
    ReduxProps;

class FolderButtonComponent extends React.Component<FolderButtonProps> {
    render() {
        const { btnLabel, onClick, Loctool } = this.props;

        return (
            <StyledFolder $disabled={this.props.disabled}>
                <Element
                    as="button"
                    $style={{ display: "flex", alignItems: "center", width: "100%", minHeight: "100%" }}
                    onClick={onClick}
                >
                    <Element as={SvgIconFolder} $style={{ minWidth: 14, width: 14, height: 14, marginLeft: 10 }} />
                    <Text
                        as="span"
                        variant="common"
                        lineClamp={1}
                        $style={{ margin: "0 8px", textAlign: "left" }}
                        title={btnLabel}
                    >
                        {btnLabel}
                    </Text>
                </Element>
                {this.props.disabled ? (
                    <Element
                        $style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 40,
                            minHeight: "100%",
                        }}
                    >
                        <SvgIconDisabled width={14} height={14} />
                    </Element>
                ) : (
                    <Menu
                        transition
                        align="end"
                        menuButton={
                            <Element
                                as="button"
                                type="button"
                                aria-label="Opciók"
                                $style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: 38,
                                    width: 38,
                                    minHeight: "100%",
                                    marginLeft: "auto",
                                    borderRadius: "50%",
                                }}
                            >
                                <SvgIconMoreVert width={14} height={14} />
                            </Element>
                        }
                    >
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.folders.options.rename" })}
                                onClick={() => {
                                    this.props.onRenameClick();
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.folders.options.move" })}
                                onClick={() => {
                                    this.props.onMoveClick();
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <Button.Menu
                                label={Loctool.formatMessage({ id: "components.folders.options.delete" })}
                                onClick={() => {
                                    if (this.props.isDeletable) {
                                        this.props.onDeleteClick();
                                    } else {
                                        this.props.alertInfo({
                                            message: Loctool.formatMessage({ id: "warning.folderNotDeletable" }),
                                        });
                                    }
                                }}
                            />
                        </MenuItem>
                    </Menu>
                )}
            </StyledFolder>
        );
    }
}

export const LoadingFolderButton = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
    as: "button",
    type: "button",
}))`
    min-width: 157px;
    height: 38px;
    border-radius: 10px;
    margin-right: 20px;
    pointer-events: none;
`;

const StyledFolder = styled(Flex.Container).attrs({
    $alignItems: "center",
})<{ $disabled?: boolean }>`
    position: relative;
    width: 100%;
    min-height: 40px;
    color: ${props => props.theme.folderButton.color};
    background: ${props => props.theme.folderButton.background};
    border: 1px solid ${props => props.theme.folderButton.borderColor};
    border-radius: 10px;
    box-shadow: ${props => props.theme.folderButton.boxShadow};
    transition: color ${Transition.common}, background ${Transition.common}, border-color ${Transition.common},
        box-shadow ${Transition.common};
    user-select: none;
    // overflow: hidden;

    &:hover {
        color: ${props => props.theme.folderButton.hover.color};
        box-shadow: ${props => props.theme.folderButton.hover.boxShadow};
    }

    ${props =>
        props.$disabled &&
        `
        color: ${props.theme.folderButton.disabled.color};
    `}
`;

const mapDispatchToProps = {
    alertInfo: AlertActions.info,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const FolderButton = withLoctool(connector(FolderButtonComponent));
