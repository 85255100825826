import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import { batch } from "react-redux";
import { Asset, AssetType, SearchListOptionsInput, SearchListSortField, SortOrder } from "@api/graphql/types";
import { Constants } from "@utils/Constants";
import { Api } from "@api/Api";
import { AppThunk } from "./store";
import { AppStateActions } from "./appStateSlice";
import { Cookie } from "@utils/Cookie";
import { AlertActions } from "./alertSlice";

type ViewOptions = {
    typeFilter: AssetType | null;
    sortField: SearchListSortField | null;
    sortOrder: SortOrder | null;
    assetListDisplay: boolean;
};

const viewOptions: ViewOptions | null = Cookie.getViewOptions();

export interface AssetState {
    assets: Asset[];
    count: number;
    typeFilter: AssetType | null;
    maximumSelectableAsset?: number;
    assetListDisplay: boolean;
    selectedAssets: Asset[];
    assetOptions: SearchListOptionsInput;
}

const initialState: AssetState = {
    assets: [],
    count: 0,
    typeFilter: viewOptions?.typeFilter ?? null,
    maximumSelectableAsset: 0,
    assetListDisplay: viewOptions?.assetListDisplay ?? false,
    selectedAssets: [],
    assetOptions: {
        sortField: viewOptions?.sortField ?? SearchListSortField.createdAt,
        control: {
            sortOrder: viewOptions?.sortOrder ?? SortOrder.DESC,
            search: null,
            limit: Constants.assetPageSize,
            offset: 0,
        },
    },
};

const assetSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        setAssets(state, { payload: assets }: PayloadAction<Asset[]>) {
            state.assets = assets;
        },
        setCount(state, { payload: count }: PayloadAction<number>) {
            state.count = count;
        },
        setTypeFilter(state, { payload: typeFilter }: PayloadAction<AssetType | null>) {
            state.typeFilter = typeFilter;
            if (typeFilter !== null) {
                state.selectedAssets = state.selectedAssets.filter(a => a.assetType === typeFilter);
            }
            saveViewOptions(state);
        },
        toggleSelectedAsset(state, { payload: asset }: PayloadAction<Asset>) {
            const { selectedAssets, maximumSelectableAsset = 0 } = state;

            if (maximumSelectableAsset > 1 && selectedAssets.length === maximumSelectableAsset) {
                return;
            }
            if (maximumSelectableAsset === 1) {
                state.selectedAssets = [asset];
            } else if (selectedAssets.find(({ id }) => id === asset.id)) {
                state.selectedAssets = selectedAssets.filter(({ id }) => id !== asset.id);
            } else {
                state.selectedAssets.push(asset);
            }
        },
        extendSelection(state, { payload: asset }: PayloadAction<Asset>) {
            const { assets, selectedAssets, typeFilter } = state;
            const selectedAssetIds = selectedAssets.map(a => a.id);
            const fromAsset = assets.find(a => a.id === asset.id || selectedAssetIds.includes(a.id));

            if (!fromAsset) {
                return;
            }

            const from = assets.indexOf(fromAsset);
            const to = assets.findIndex(a =>
                fromAsset.id === asset.id ? selectedAssetIds.includes(a.id) : a.id === asset.id
            );

            if (to) {
                const extendedSelection = assets.filter((a, index) => {
                    if (selectedAssetIds.includes(a.id)) {
                        return false;
                    }
                    if (typeFilter && a.assetType !== typeFilter) {
                        return false;
                    }
                    return index >= from && index <= to;
                });
                state.selectedAssets = [...selectedAssets, ...extendedSelection];
            } else if (!selectedAssetIds.includes(fromAsset.id)) {
                state.selectedAssets.push(fromAsset);
            }
        },
        selectAllAssets(state) {
            if (state.typeFilter) {
                state.selectedAssets = [...state.assets.filter(a => a.assetType === state.typeFilter)];
            } else {
                state.selectedAssets = [...state.assets];
            }
        },
        clearSelectedAssets(state) {
            state.selectedAssets = [];
        },
        setAssetSort(state, { payload }: PayloadAction<{ field: SearchListSortField; direction: SortOrder }>) {
            state.assetOptions.sortField = payload.field;
            state.assetOptions.control = {
                ...state.assetOptions.control,
                sortOrder: payload.direction,
            };
            saveViewOptions(state);
        },
        setAssetSearch(state, { payload: search }: PayloadAction<string | null>) {
            state.assetOptions.control = {
                ...state.assetOptions.control,
                search,
            };
        },
        setAssetOffset(state, { payload: offset }: PayloadAction<number>) {
            state.assetOptions.control = {
                ...state.assetOptions.control,
                offset,
            };
        },
        clearAssetOptions(state) {
            state.assetOptions = initialState.assetOptions;
            saveViewOptions(state);
        },
        toggleAssetListDisplay(state, { payload: assetListDisplay }: PayloadAction<boolean>) {
            state.assetListDisplay = assetListDisplay;
            saveViewOptions(state);
        },
        setMaximumSelectableAsset(state, { payload: amount }: PayloadAction<number>) {
            state.maximumSelectableAsset = amount;
        },
        clearPosition(state) {
            state.assetOptions.control!.limit = initialState.assetOptions.control?.limit;
            state.assetOptions.control!.offset = initialState.assetOptions.control?.offset;
        },
    },
});

const saveViewOptions = (state: AssetState) => {
    Cookie.setViewOptions({
        typeFilter: state.typeFilter,
        sortField: state.assetOptions.sortField ?? null,
        sortOrder: state.assetOptions.control?.sortOrder ?? null,
        assetListDisplay: state.assetListDisplay,
    });
};

const searchFolderAssets = (search: string | null): AppThunk => async (dispatch, getState) => {
    const { currentFolder } = getState().folders;

    batch(() => {
        dispatch(AssetActions.clearPosition());
        dispatch(AssetActions.setAssetSearch(search));
        dispatch(AppStateActions.toggleLoadingBar(true));
    });

    try {
        const { assetOptions } = getState().assets;
        const response = await Api.getAssets(currentFolder, assetOptions);

        batch(() => {
            dispatch(AssetActions.setAssets(response.result));
            dispatch(AssetActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const refreshAssets = (): AppThunk => async (dispatch, getState) => {
    const assetOptions = produce(getState().assets.assetOptions, draft => {
        draft.control!.offset = 0;
        draft.control!.limit = (getState().assets.assetOptions?.control?.offset ?? 0) + Constants.assetPageSize;
    });
    const { currentFolder } = getState().folders;

    dispatch(AppStateActions.toggleLoadingBar(true));

    try {
        const response = await Api.getAssets(currentFolder, assetOptions);

        batch(() => {
            dispatch(AssetActions.setAssets(response.result));
            dispatch(AssetActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const fetchMoreAssets = (): AppThunk => async (dispatch, getState) => {
    const { assets, count, assetOptions } = getState().assets;
    const { currentFolder } = getState().folders;

    if (count === assets.length) {
        return;
    }

    dispatch(AppStateActions.toggleLoadingBar(true));

    try {
        const response = await Api.getAssets(currentFolder, assetOptions);

        batch(() => {
            dispatch(AssetActions.setAssets([...assets, ...response.result]));
            dispatch(AssetActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

export const AssetActions = { ...assetSlice.actions, fetchMoreAssets, refreshAssets, searchFolderAssets };

export const AssetReducer = assetSlice.reducer;
