export const hexToRGBA = (h: string, opacity = 1): string => {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
};

export enum Color {
    chili500 = "#FF0000",
    errorBackground = "#FFE5F2",
    errorDark = "#98014C",
    errorDefault = "#E40173",
    errorLight = "#FFF2F9",
    grayscaleAsh = "#262338",
    grayscaleBackground = "#F7F7FC",
    grayscaleBody = "#4E4B66",
    grayscaleInput = "#EFF0F6",
    grayscaleLabel = "#6E7191",
    grayscaleLine = "#D9DBE9",
    grayscaleOffBlack = "#14142B",
    grayscaleOffWhite = "#FCFCFC",
    grayscalePlaceholder = "#A0A3BD",
    lime500 = "#57D916",
    ocean300 = "#77DAFF",
    ocean500 = "#00BAFF",
    ocean600 = "#00A7E5",
    ocean700 = "#0095CC",
    primaryDark = "#3C0594",
    primaryDarkMode = "#C19CFC",
    primaryDefault = "#6308F7",
    primaryLight = "#EEE5FE",
    sky200 = "#9BB2FD",
    sky300 = "#7897FF",
    sky500 = "#063EF9",
    sky800 = "#032596",
    sky900 = "#021964",
    successBackground = "#E5FFF2",
    successDark = "#00994D",
    successDefault = "#00CC67",
    successLight = "#F2FFF9",
    white = "#ffffff",
}

export enum Font {
    primary = "'Poppins', sans-serif",
}

export enum Transition {
    common = "150ms",
    inputLabel = "100ms",
}

export enum Shadow {
    none = "none",
    small = "0px 2px 4px rgba(0, 0, 0, 0.08)",
    medium = "0px 12px 16px rgba(0, 0, 0, 0.04)",
    large = "0px 0px 56px rgba(0, 0, 0, 0.08)",
    actionBar = "0px -24px 56px rgba(0, 0, 0, 0.08)",
    actionBarDark = "0px -24px 56px rgba(0, 0, 0, 0.5)",
}

export enum zIndex {
    actionBar = 1000,
    alert = 1200,
    appBar = 1000,
    assetExport = 1000,
    loadingOverlay = 1300,
    optionsBar = 700,
    sidebar = 800,
}

export enum BreakPoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
}

export const breakpoints: { [key in BreakPoint]: number } = {
    small: 0,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1920,
};

function customMediaQuery(mediaQuery: string): string {
    return `@media (${mediaQuery})`;
}

export const bp: { [key in BreakPoint]: string } & { custom: (mediaQuery: string) => string } = {
    custom: customMediaQuery,
    small: customMediaQuery(`max-width:  ${breakpoints.medium - 1}px`),
    medium: customMediaQuery(`min-width: ${breakpoints.medium}px`),
    large: customMediaQuery(`min-width: ${breakpoints.large}px`),
    xlarge: customMediaQuery(`min-width: ${breakpoints.xlarge}px`),
    xxlarge: customMediaQuery(`min-width: ${breakpoints.xxlarge}px`),
};

export const Theme = {
    actionBar: {
        background: Color.white,
        boxShadow: Shadow.actionBar,
        color: Color.sky500,
    },
    alert: {
        boxShadow: Shadow.large,
        default: {
            background: Color.grayscaleInput,
            color: Color.grayscaleAsh,
        },
        error: {
            background: Color.errorBackground,
            color: Color.errorDefault,
        },
        success: {
            background: Color.successBackground,
            color: Color.successDark,
        },
    },
    appBar: {
        auth: {
            background: Color.white,
            borderColor: Color.grayscaleInput,
        },
        bottom: {
            background: Color.white,
            borderColor: Color.grayscaleLine,
            color: Color.sky500,
        },
        menu: {
            checkboxLabel: {
                color: Color.sky900,
                active: {
                    color: Color.ocean600,
                },
            },
            radioButton: {
                background: Color.grayscaleLine,
                borderColor: Color.sky500,
            },
        },
        top: {
            background: Color.white,
            borderColor: Color.grayscaleLine,
            color: Color.sky900,
            tabButton: {
                borderColor: Color.sky500,
                color: Color.grayscaleOffBlack,
                active: {
                    color: Color.sky800,
                },
            },
        },
        search: {
            background: Color.grayscaleBackground,
            borderColor: Color.grayscaleLine,
            color: Color.grayscaleOffBlack,
            placeholder: {
                color: Color.grayscaleLabel,
            },
        },
    },
    asset: {
        row: {
            borderColor: Color.grayscaleLine,
            color: Color.sky900,
            hover: {
                background: Color.white,
            },
            selected: {
                background: Color.grayscaleBackground,
            },
        },
        tile: {
            background: Color.white,
            borderColor: Color.grayscaleLine,
            boxShadow: "none",
            detailsColor: Color.grayscaleLabel,
            hover: {
                boxShadow: Shadow.medium,
                titleColor: Color.sky500,
            },
            innerBorderColor: Color.grayscaleInput,
            overlayColor: Color.sky500,
            overlayOpacity: 0.4,
            selected: {
                outlineColor: Color.sky500,
            },
            selectedColor: Color.ocean600,
            titleColor: Color.sky900,
        },
    },
    assetExport: {
        background: Color.white,
        boxShadow: Shadow.large,
    },
    auth: {
        background: Color.grayscaleBackground,
    },
    body: {
        background: Color.white,
    },
    breadcrumb: {
        color: Color.sky900,
        active: {
            color: Color.grayscalePlaceholder,
        },
    },
    button: {
        action: {
            active: {
                background: "transparent",
                borderColor: Color.sky900,
                color: Color.sky900,
            },
            background: "transparent",
            borderColor: Color.grayscaleLine,
            color: Color.sky500,
            focus: {
                outlineColor: Color.grayscaleInput,
            },
            hover: {
                background: "transparent",
                borderColor: Color.grayscalePlaceholder,
                color: Color.sky800,
            },
            loading: {
                background: "transparent",
                borderColor: Color.ocean500,
                color: Color.ocean500,
            },
        },
        primary: {
            active: {
                background: Color.sky900,
                borderColor: Color.sky900,
                color: Color.white,
            },
            background: Color.sky500,
            borderColor: Color.sky500,
            color: Color.white,
            focus: {
                outlineColor: Color.sky200,
            },
            hover: {
                background: Color.sky800,
                borderColor: Color.sky800,
                color: Color.white,
            },
            loading: {
                background: Color.ocean500,
                borderColor: Color.ocean500,
                color: Color.white,
            },
        },
        secondary: {
            active: {
                background: "transparent",
                borderColor: Color.sky900,
                color: Color.sky900,
            },
            background: "transparent",
            borderColor: Color.sky500,
            color: Color.sky500,
            focus: {
                outlineColor: Color.sky200,
            },
            hover: {
                background: "transparent",
                borderColor: Color.sky800,
                color: Color.sky800,
            },
            loading: {
                background: "transparent",
                borderColor: Color.ocean500,
                color: Color.ocean500,
            },
        },
        text: {
            primary: {
                active: {
                    color: Color.sky900,
                },
                color: Color.sky500,
                focus: {
                    outlineColor: Color.grayscaleInput,
                },
                hover: {
                    color: Color.sky800,
                },
                loading: {
                    color: Color.ocean500,
                },
            },
            secondary: {
                active: {
                    color: Color.sky500,
                },
                color: Color.sky800,
                focus: {
                    outlineColor: Color.grayscaleInput,
                },
                hover: {
                    color: Color.sky500,
                },
                loading: {
                    color: Color.ocean500,
                },
            },
        },
    },
    checkbox: {
        background: Color.grayscaleLine,
        borderColor: Color.white,
        checked: {
            background: Color.sky500,
        },
        checkmark: {
            color: Color.white,
        },
    },
    dropzone: {
        background: Color.grayscaleOffWhite,
        borderColor: Color.grayscalePlaceholder,
        color: Color.grayscalePlaceholder,
    },
    folderButton: {
        background: Color.grayscaleBackground,
        borderColor: Color.grayscalePlaceholder,
        boxShadow: "none",
        color: Color.grayscaleOffBlack,
        disabled: {
            color: Color.sky300,
        },
        hover: {
            boxShadow: Shadow.medium,
            color: Color.sky500,
        },
    },
    folderSelect: {
        borderColor: Color.grayscaleLine,
        color: Color.sky900,
        folderButton: {
            hover: {
                background: Color.grayscaleInput,
            },
        },
        parentFolderButton: {
            borderColor: Color.sky900,
        },
        selected: {
            borderColor: Color.sky500,
            boxShadow: Shadow.medium,
        },
    },
    input: {
        background: Color.grayscaleInput,
        borderColor: Color.grayscaleInput,
        color: Color.grayscaleOffBlack,
        error: {
            background: Color.errorLight,
            borderColor: Color.errorDefault,
            color: Color.errorDark,
        },
        focus: {
            background: Color.white,
            borderColor: Color.grayscaleOffBlack,
        },
        hint: {
            color: Color.grayscaleLabel,
        },
        icon: {
            color: Color.grayscaleLabel,
        },
        label: {
            color: Color.grayscaleLabel,
        },
        success: {
            background: Color.successLight,
            borderColor: Color.successDefault,
            color: Color.successDark,
        },
    },
    link: {
        color: Color.sky900,
        hover: {
            color: Color.ocean500,
        },
    },
    loadingBar: {
        backgroundColor: Color.grayscaleLine,
        color: Color.ocean500,
    },
    menu: {
        background: Color.white,
        boxShadow: Shadow.large,
        color: Color.sky900,
        divider: {
            color: Color.grayscaleLine,
        },
    },
    modal: {
        background: Color.white,
        boxShadow: Shadow.large,
        underlay: {
            backdropFilter: "blur(10px)",
            background: "rgba(229, 235, 254, 0.7)",
        },
        list: {
            borderColor: Color.grayscaleLine,
            containerGradient: "linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%)",
        },
    },
    optionsBar: {
        background: Color.white,
        borderColor: Color.grayscaleLine,
        color: Color.grayscaleOffBlack,
    },
    progressBar: {
        background: Color.grayscaleInput,
        progressColor: "linear-gradient(95.08deg, #00BAFF 2.49%, #063EF9 97.19%)",
    },
    separator: {
        color: Color.grayscaleLine,
    },
    sidebar: {
        background: Color.white,
        borderColor: Color.grayscaleLine,
        color: Color.sky900,
        folder: {
            active: {
                background: Color.sky500,
                color: Color.white,
            },
            hover: {
                background: `${hexToRGBA(Color.sky500, 0.1)}`,
            },
        },
    },
    skeleton: {
        background: "linear-gradient(-90deg, #eee 0%, #ddd 50%, #eee 100%)",
    },
    text: {
        body: {
            color: Color.sky900,
            fontFamily: Font.primary,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 24,
        },
        common: {
            fontFamily: Font.primary,
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 0.25,
            lineHeight: 22,
        },
        displayXSmall: {
            color: Color.grayscaleOffBlack,
            fontFamily: Font.primary,
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: 1,
            lineHeight: 32,
        },
        linkMedium: {
            color: Color.grayscaleOffBlack,
            fontFamily: Font.primary,
            fontSize: 17,
            fontWeight: 700,
            letterSpacing: 0.75,
            lineHeight: 28,
        },
        linkSmall: {
            color: Color.sky900,
            fontFamily: Font.primary,
            fontSize: 15,
            fontWeight: 600,
            letterSpacing: 0.75,
            lineHeight: 24,
        },
        linkXSmall: {
            color: Color.sky900,
            fontFamily: Font.primary,
            fontSize: 13,
            fontWeight: 700,
            letterSpacing: 0.25,
            lineHeight: 22,
        },
        textXSmall: {
            color: Color.sky900,
            fontFamily: Font.primary,
            fontSize: 13,
            fontWeight: 500,
            lineHeight: 22,
        },
    },
};

export const DarkTheme: typeof Theme = {
    actionBar: {
        background: Color.grayscaleAsh,
        boxShadow: Shadow.actionBarDark,
        color: Color.grayscaleOffWhite,
    },
    alert: {
        boxShadow: Shadow.large,
        default: {
            background: Color.grayscaleInput,
            color: Color.grayscaleAsh,
        },
        error: {
            background: Color.errorBackground,
            color: Color.errorDefault,
        },
        success: {
            background: Color.successBackground,
            color: Color.successDark,
        },
    },
    appBar: {
        auth: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
        },
        bottom: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
            color: Color.grayscaleOffWhite,
        },
        menu: {
            checkboxLabel: {
                active: {
                    color: Color.ocean300,
                },
                color: Color.white,
            },
            radioButton: {
                background: Color.grayscaleBody,
                borderColor: Color.ocean300,
            },
        },
        search: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
            color: Color.white,
            placeholder: {
                color: Color.ocean300,
            },
        },
        top: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
            color: Color.grayscaleOffWhite,
            tabButton: {
                active: {
                    color: Color.ocean300,
                },
                borderColor: Color.ocean300,
                color: Color.white,
            },
        },
    },
    asset: {
        row: {
            borderColor: Color.grayscaleBody,
            color: Color.ocean300,
            hover: {
                background: Color.grayscaleBody,
            },
            selected: {
                background: Color.grayscaleBody,
            },
        },
        tile: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
            boxShadow: "none",
            detailsColor: Color.grayscaleLabel,
            hover: {
                boxShadow: Shadow.medium,
                titleColor: Color.ocean300,
            },
            innerBorderColor: Color.grayscaleBody,
            overlayColor: Color.sky500,
            overlayOpacity: 0.4,
            selected: {
                outlineColor: Color.ocean300,
            },
            selectedColor: Color.ocean600,
            titleColor: Color.grayscaleOffWhite,
        },
    },
    assetExport: {
        background: Color.grayscaleAsh,
        boxShadow: Shadow.large,
    },
    auth: {
        background: Color.grayscaleOffBlack,
    },
    body: {
        background: Color.grayscaleAsh,
    },
    breadcrumb: {
        active: {
            color: Color.grayscaleBody,
        },
        color: Color.white,
    },
    button: {
        action: {
            active: {
                background: "transparent",
                borderColor: Color.ocean300,
                color: Color.ocean300,
            },
            background: "transparent",
            borderColor: Color.grayscaleOffWhite,
            color: Color.grayscaleOffWhite,
            focus: {
                outlineColor: Color.grayscaleBody,
            },
            hover: {
                background: "transparent",
                borderColor: Color.ocean300,
                color: Color.ocean300,
            },
            loading: {
                background: "transparent",
                borderColor: Color.grayscaleOffWhite,
                color: Color.grayscaleOffWhite,
            },
        },
        primary: {
            active: {
                background: Color.grayscaleOffWhite,
                borderColor: Color.grayscaleOffWhite,
                color: Color.sky900,
            },
            background: Color.primaryDarkMode,
            borderColor: Color.primaryDarkMode,
            color: Color.sky800,
            focus: {
                outlineColor: Color.grayscaleBody,
            },
            hover: {
                background: Color.primaryLight,
                borderColor: Color.primaryLight,
                color: Color.sky800,
            },
            loading: {
                background: Color.primaryLight,
                borderColor: Color.primaryLight,
                color: Color.sky800,
            },
        },
        secondary: {
            active: {
                background: "transparent",
                borderColor: Color.grayscaleOffWhite,
                color: Color.grayscaleOffWhite,
            },
            background: "transparent",
            borderColor: Color.primaryDarkMode,
            color: Color.primaryDarkMode,
            focus: {
                outlineColor: Color.grayscaleBody,
            },
            hover: {
                background: "transparent",
                borderColor: Color.primaryLight,
                color: Color.primaryLight,
            },
            loading: {
                background: "transparent",
                borderColor: Color.primaryLight,
                color: Color.primaryLight,
            },
        },
        text: {
            primary: {
                active: {
                    color: Color.grayscaleOffWhite,
                },
                color: Color.ocean300,
                focus: {
                    outlineColor: Color.grayscaleBody,
                },
                hover: {
                    color: Color.grayscaleOffWhite,
                },
                loading: {
                    color: Color.ocean300,
                },
            },
            secondary: {
                active: {
                    color: Color.ocean300,
                },
                color: Color.grayscaleOffWhite,
                focus: {
                    outlineColor: Color.grayscaleBody,
                },
                hover: {
                    color: Color.ocean300,
                },
                loading: {
                    color: Color.grayscaleOffWhite,
                },
            },
        },
    },
    checkbox: {
        background: Color.grayscaleBody,
        borderColor: Color.grayscaleOffBlack,
        checked: {
            background: Color.grayscaleBody,
        },
        checkmark: {
            color: Color.ocean300,
        },
    },
    dropzone: {
        background: Color.grayscaleAsh,
        borderColor: Color.grayscaleOffWhite,
        color: Color.grayscaleOffWhite,
    },
    folderButton: {
        background: Color.grayscaleAsh,
        borderColor: Color.grayscaleBody,
        boxShadow: "none",
        color: Color.grayscaleOffWhite,
        disabled: {
            color: Color.grayscaleBody,
        },
        hover: {
            boxShadow: Shadow.medium,
            color: Color.grayscaleOffWhite,
        },
    },
    folderSelect: {
        borderColor: Color.grayscaleBody,
        color: Color.ocean300,
        folderButton: {
            hover: {
                background: Color.grayscaleBody,
            },
        },
        parentFolderButton: {
            borderColor: Color.sky900,
        },
        selected: {
            borderColor: Color.sky500,
            boxShadow: Shadow.medium,
        },
    },
    input: {
        background: Color.grayscaleBody,
        borderColor: Color.grayscaleBody,
        color: Color.white,
        error: {
            background: Color.errorLight,
            borderColor: Color.errorDefault,
            color: Color.errorDark,
        },
        focus: {
            background: Color.grayscaleAsh,
            borderColor: Color.grayscaleBody,
        },
        hint: {
            color: Color.grayscaleLabel,
        },
        icon: {
            color: Color.ocean300,
        },
        label: {
            color: Color.ocean300,
        },
        success: {
            background: Color.successLight,
            borderColor: Color.successDefault,
            color: Color.successDark,
        },
    },
    link: {
        color: Color.sky900,
        hover: {
            color: Color.ocean500,
        },
    },
    loadingBar: {
        backgroundColor: Color.grayscaleLine,
        color: Color.ocean500,
    },
    menu: {
        background: Color.grayscaleOffBlack,
        boxShadow: Shadow.large,
        color: Color.ocean500,
        divider: {
            color: Color.grayscaleBody,
        },
    },
    modal: {
        background: Color.grayscaleAsh,
        boxShadow: Shadow.large,
        list: {
            borderColor: Color.grayscaleBody,
            containerGradient: "linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(0, 0, 0, 0) 100%)",
        },
        underlay: {
            backdropFilter: "blur(10px)",
            background: "rgba(26, 20, 1, 0.7)",
        },
    },
    optionsBar: {
        background: Color.grayscaleAsh,
        borderColor: Color.grayscaleBody,
        color: Color.white,
    },
    progressBar: {
        background: Color.grayscaleInput,
        progressColor: "linear-gradient(95.08deg, #00BAFF 2.49%, #063EF9 97.19%)",
    },
    separator: {
        color: Color.grayscaleBody,
    },
    skeleton: {
        background: "linear-gradient(-90deg, #262338 0%, #4E4B66 50%, #262338 100%)",
    },
    sidebar: {
        background: Color.grayscaleAsh,
        borderColor: Color.grayscaleBody,
        color: Color.white,
        folder: {
            active: {
                background: Color.ocean300,
                color: Color.grayscaleAsh,
            },
            hover: {
                background: `${hexToRGBA(Color.ocean300, 0.1)}`,
            },
        },
    },
    text: {
        body: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 24,
        },
        common: {
            fontFamily: Font.primary,
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 0.25,
            lineHeight: 22,
        },
        displayXSmall: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: 1,
            lineHeight: 32,
        },
        linkMedium: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 17,
            fontWeight: 700,
            letterSpacing: 0.75,
            lineHeight: 28,
        },
        linkSmall: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 15,
            fontWeight: 600,
            letterSpacing: 0.75,
            lineHeight: 24,
        },
        linkXSmall: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 13,
            fontWeight: 700,
            letterSpacing: 0.25,
            lineHeight: 22,
        },
        textXSmall: {
            color: Color.ocean300,
            fontFamily: Font.primary,
            fontSize: 13,
            fontWeight: 500,
            lineHeight: 22,
        },
    },
};
