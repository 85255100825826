import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import SvgIconCloseX from "@components/svg/IconCloseX";
import { Text } from "@components/Text";
import { bp } from "@theme/Theme";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";
import React, { ReactNode } from "react";
import AriaModal from "react-aria-modal";
import styled, { CSSObject, withTheme } from "styled-components";

interface State {
    modalActive: boolean;
}

type Props = {
    heading: string;
    hideHeading?: boolean;
    children: ReactNode;
    mounted?: boolean;
    onModalClose?: () => void;
    renderButtonActivate?: ReactNode;
    hideCloseButton?: boolean;
    underlayClickExits?: boolean;
    initialFocus?: string;
    $style?: CSSObject;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme?: any;
} & Omit<React.HTMLProps<HTMLDivElement>, "children" | "ref" | "as"> &
    WithLoctoolProps;

class ModalComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalActive: false,
        };

        this.activateModal = this.activateModal.bind(this);
        this.deactivateModal = this.deactivateModal.bind(this);
        this.getApplicationNode = this.getApplicationNode.bind(this);
    }

    activateModal = () => {
        this.setState({ modalActive: true });
    };

    deactivateModal = () => {
        this.setState({ modalActive: false });
        this.props.onModalClose && this.props.onModalClose();
    };

    getApplicationNode = (): HTMLElement => document.getElementById("root")!;

    render(): JSX.Element {
        const {
            heading,
            hideHeading,
            children,
            mounted,
            renderButtonActivate,
            onModalClose,
            initialFocus,
            ...otherProps
        } = this.props;
        const { modalActive } = this.state;
        const ButtonActivate =
            renderButtonActivate &&
            React.cloneElement(renderButtonActivate as JSX.Element, { onClick: this.activateModal });

        return (
            <>
                {ButtonActivate}

                {(modalActive || mounted) && (
                    <AriaModal
                        focusDialog={!initialFocus}
                        getApplicationNode={this.getApplicationNode}
                        mounted={mounted}
                        onExit={this.deactivateModal}
                        titleText={heading}
                        underlayClickExits={this.props.underlayClickExits ?? true}
                        underlayStyle={{
                            background: this.props.theme.modal.underlay.background,
                            backdropFilter: this.props.theme.modal.underlay.backdropFilter,
                        }}
                        verticallyCenter={true}
                        initialFocus={initialFocus}
                    >
                        <ModalContainer $style={this.props.$style} {...otherProps}>
                            {!this.props.hideCloseButton && (
                                <Button.Icon
                                    label={this.props.Loctool.formatMessage({ id: "common.close" })}
                                    icon={<SvgIconCloseX width={24} height={24} />}
                                    inverse
                                    $style={{ position: "absolute", top: 20, right: 20 }}
                                    onClick={this.deactivateModal}
                                />
                            )}
                            {!hideHeading && (
                                <Text
                                    as="h2"
                                    variant="displayXSmall"
                                    $style={{ marginBottom: 34, textAlign: "center" }}
                                >
                                    {heading}
                                </Text>
                            )}
                            {children}
                        </ModalContainer>
                    </AriaModal>
                )}
            </>
        );
    }
}

interface ModalListProps {
    children?: ReactNode;
}

export const ModalList = (props: ModalListProps) => {
    return (
        <ModalListContainer>
            <StyledModalList>{props.children}</StyledModalList>
        </ModalListContainer>
    );
};

const ModalListContainer = styled.div`
    position: relative;
    margin-bottom: 15px;

    ::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px;
        height: 15px;
        background: ${props => props.theme.modal.list.containerGradient};
    }
`;

const StyledModalList = styled.ul`
    padding: 5px 0;
    border-top: 1px solid ${props => props.theme.modal.list.borderColor};
    border-bottom: 1px solid ${props => props.theme.modal.list.borderColor};
    max-height: 30vh;
    overflow-y: auto;
`;

const Modal = withLoctool(withTheme(ModalComponent));

export default Modal;

export const ModalContainer = styled(Element)<{ $style?: CSSObject }>`
    position: relative;
    width: 100%;
    max-width: 665px;
    padding: 30px;
    background: ${props => props.theme.modal.background};
    box-shadow: ${props => props.theme.modal.boxShadow};
    border-radius: 24px;

    ${bp.medium} {
        min-width: 576px;
        margin: 20px 0;
    }

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;
