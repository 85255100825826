import { CSSObject } from "styled-components";

export function SortObjKeysAlphabetically(obj: CSSObject) {
    const ordered: CSSObject = {};
    Object.keys(obj)
        .sort()
        .forEach(key => {
            ordered[key] = obj[key];
        });
    return ordered;
}
