import { LoctoolMessage } from "@bigfish/react-loctool";
import { ActionBar } from "@components/ActionBar";
import { AppBar } from "@components/AppBar";
import { Assets } from "@components/Assets/Assets";
import { Element } from "@components/Element";
import { EmptyFolderInfo } from "@components/EmptyFolderInfo";
import { Folders } from "@components/Folders";
import { Main } from "@components/Main";
import { OptionsBar } from "@components/OptionsBar";
import { Sidebar } from "@components/Sidebar";
import { StyledSplitPane } from "@components/SplitPane";
import { Text } from "@components/Text";
import { AssetActions } from "@redux/assetSlice";
import { AssetUploadActions } from "@redux/assetUploadSlice";
import { ContentActions } from "@redux/contentActions";
import { FolderActions } from "@redux/folderSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import { Constants } from "@utils/Constants";
import { isEqual } from "@utils/Helpers";
import throttle from "lodash/throttle";
import React from "react";
import Dropzone from "react-dropzone";
import { batch, connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

interface RouteProps {
    id?: string;
}

type Props = {} & ReduxProps & RouteComponentProps<RouteProps>;

interface State {
    sidebarWidth: number;
    tabIndex: number;
}

class FolderPageComponent extends React.Component<Props, State> {
    initialPath: string | null = null;

    public state: State = {
        sidebarWidth: 240,
        tabIndex: 0,
    };

    componentDidMount() {
        this.props.setCurrentFolder(this.props.match.params.id);
        this.props.fetchInitialContents({ setSidebarFolders: !this.props.match.params.id });
        window.addEventListener("scroll", this.onScroll);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            batch(() => {
                this.props.setCurrentFolder(this.props.match.params.id);
                this.props.fetchInitialContents({ clearSelectedAssets: true, resetSearch: true });
            });
        } else if (!isEqual(prevProps.assetOptions, this.props.assetOptions)) {
            prevProps.assetOptions.control?.offset === this.props.assetOptions.control?.offset
                ? this.props.refreshAssets()
                : this.props.fetchMoreAssets();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    private readonly onScroll = throttle(() => {
        const bottom =
            Math.abs(
                document.documentElement.scrollHeight -
                    document.documentElement.scrollTop -
                    document.documentElement.clientHeight
            ) < 1;
        if (bottom) {
            this.props.setAssetOffset((this.props.assetOptions.control?.offset ?? 0) + Constants.assetPageSize);
        }
    }, 200);

    private startUpload = (files: File[]) => {
        batch(() => {
            this.props.toggleFileUploadModal();
            this.props.uploadAssets(files);
        });
    };

    render() {
        const search = this.props.assetOptions.control?.search ?? "";

        return (
            <React.Fragment key="root">
                <AppBar
                    key="appBar"
                    initialPath={this.initialPath || ""}
                    hideAppBarBottom={false}
                    selectedAssets={this.props.selectedAssets}
                    maxSelectableAsset={this.props.maxSelectableAsset}
                    selectAllAssets={this.props.selectAllAssets}
                    refreshAssets={() => this.props.refreshAssets()}
                    clearSelectedAssets={this.props.clearSelectedAssets}
                />

                <StyledSplitPane
                    split="vertical"
                    minSize={240}
                    maxSize={360}
                    primary="first"
                    onDragFinished={size => {
                        this.setState({
                            sidebarWidth: size,
                        });
                    }}
                    size={this.props.sidebarOpen ? this.state.sidebarWidth : 0}
                >
                    <Sidebar />

                    <Dropzone onDropAccepted={this.startUpload} noClick>
                        {({ getRootProps, getInputProps, isDragActive, open }) => (
                            <Main {...getRootProps()}>
                                <OptionsBar />
                                <Folders />
                                <Assets
                                    assets={this.props.assets}
                                    assetListDisplay={this.props.assetListDisplay}
                                    selectedAssets={this.props.selectedAssets}
                                    toggleSelectedAsset={this.props.toggleSelectedAsset}
                                    onShiftSelectAsset={
                                        this.props.maxSelectableAsset === 0
                                            ? this.props.extendSelection
                                            : this.props.toggleSelectedAsset
                                    }
                                    refreshAssets={() => this.props.refreshAssets()}
                                    typeFilter={this.props.typeFilter}
                                />

                                <Element $style={{ height: 20 }} />

                                {search.length > 0 && this.props.assets.length === 0 && !this.props.isLoading && (
                                    <>
                                        <Text variant="body" $style={{ marginTop: 20, textAlign: "center" }}>
                                            <LoctoolMessage id="components.searchPage.noResults" />
                                        </Text>
                                        <Text variant="body" $style={{ textAlign: "center", fontWeight: 700 }}>
                                            {search}
                                        </Text>
                                    </>
                                )}

                                {search.length === 0 &&
                                    this.props.assets.length === 0 &&
                                    !this.props.isLoading &&
                                    this.props.typeFilter !== null && (
                                        <>
                                            <Text variant="body" $style={{ marginTop: 20, textAlign: "center" }}>
                                                <LoctoolMessage id="components.folderPage.assetsMaybeFiltered" />
                                            </Text>
                                            <Text
                                                variant="linkXSmall"
                                                $style={{ textAlign: "center", paddingTop: 20, cursor: "pointer" }}
                                            >
                                                <a
                                                    onClick={() => {
                                                        this.props.setTypeFilter(null);
                                                    }}
                                                >
                                                    <LoctoolMessage id="components.folderPage.clearAssetFilter" />
                                                </a>
                                            </Text>
                                        </>
                                    )}

                                {search.length === 0 &&
                                    this.props.assets.length === 0 &&
                                    !this.props.isLoading &&
                                    this.props.typeFilter === null && <EmptyFolderInfo onClick={open} />}

                                <input {...getInputProps()} />

                                {isDragActive && (
                                    <DropzoneContainer>
                                        <LoctoolMessage id="components.folderPage.dropzone" />
                                    </DropzoneContainer>
                                )}

                                {this.props.selectedAssets.length > 0 && (
                                    <ActionBar
                                        selectedAssets={this.props.selectedAssets}
                                        maxSelectableAsset={this.props.maxSelectableAsset}
                                        selectAllAssets={this.props.selectAllAssets}
                                        refreshAssets={() => this.props.refreshAssets()}
                                        clearSelectedAssets={this.props.clearSelectedAssets}
                                    />
                                )}
                            </Main>
                        )}
                    </Dropzone>
                </StyledSplitPane>
            </React.Fragment>
        );
    }
}

const DropzoneContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
`;

const mapStateToProps = (state: RootState) => ({
    assets: state.assets.assets,
    assetOptions: state.assets.assetOptions,
    assetListDisplay: state.assets.assetListDisplay,
    selectedAssets: state.assets.selectedAssets,
    maxSelectableAsset: state.assets.maximumSelectableAsset ?? 0,
    currentFolder: state.folders.currentFolder,
    sidebarOpen: state.appState.sidebarOpen,
    config: state.appState.appConfiguration,
    typeFilter: state.assets.typeFilter,
    isLoading: state.appState.showLoadingBar,
});

const mapDispatchToProps = {
    fetchInitialContents: ContentActions.fetchInitialContents,
    fetchMoreAssets: AssetActions.fetchMoreAssets,
    refreshAssets: AssetActions.refreshAssets,
    selectAllAssets: AssetActions.selectAllAssets,
    clearSelectedAssets: AssetActions.clearSelectedAssets,
    setAssetOffset: AssetActions.setAssetOffset,
    toggleSelectedAsset: AssetActions.toggleSelectedAsset,
    extendSelection: AssetActions.extendSelection,
    setCurrentFolder: FolderActions.setCurrentFolder,
    uploadAssets: AssetUploadActions.uploadAssets,
    toggleFileUploadModal: ModalActions.toggleFileUploadModal,
    setTypeFilter: AssetActions.setTypeFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const FolderPage = withRouter(connector(FolderPageComponent));
