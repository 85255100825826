import * as React from "react";
import { SVGProps } from "react";

const SvgLogoPiklibColoredDarkDev = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={787}
        height={266}
        viewBox="0 0 787 266"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M310.626 222.355V83.863H332.589L334.131 96.959C341.644 86.1719 354.163 81.7434 364.95 81.7434C394.227 81.7434 413.682 103.507 413.682 131.44C413.682 159.373 396.157 181.515 365.717 181.515C355.507 181.515 341.058 178.241 334.131 167.644V222.346H310.635L310.626 222.355ZM390.177 131.44C390.177 116.612 380.166 104.472 363.209 104.472C346.253 104.472 336.241 116.603 336.241 131.44C336.241 146.277 347.029 158.597 363.209 158.597C379.39 158.597 390.177 146.268 390.177 131.44Z"
            fill="white"
        />
        <path
            d="M445.077 43.4199C452.013 43.4199 458.949 48.0469 458.949 57.0933C458.949 66.1397 452.013 70.7666 445.077 70.7666C438.142 70.7666 431.206 66.3381 431.206 57.0933C431.206 47.8484 438.142 43.4199 445.077 43.4199ZM456.83 83.4838V178.827H433.334V83.4838H456.83Z"
            fill="white"
        />
        <path
            d="M505.75 44.1863V122.005L537.724 83.8714H565.846V85.2153L527.135 128.553L571.24 177.095V178.827H542.928L505.75 135.877V178.827H482.255V44.1863H505.75Z"
            fill="white"
        />
        <path d="M608.031 44.1863V178.827H584.725V44.1863H608.031Z" fill="white" />
        <path
            d="M645.786 43.4199C652.722 43.4199 659.658 48.0469 659.658 57.0933C659.658 66.1397 652.722 70.7666 645.786 70.7666C638.85 70.7666 631.914 66.3381 631.914 57.0933C631.914 47.8484 638.85 43.4199 645.786 43.4199ZM657.538 83.4838V178.827H634.043V83.4838H657.538Z"
            fill="white"
        />
        <path
            d="M706.459 44.1865V96.7692C712.24 86.7488 727.456 81.5536 737.666 81.5536C765.4 81.5536 786.01 98.501 786.01 131.25C786.01 162.259 764.823 181.326 737.089 181.326C725.535 181.326 713.972 177.276 706.459 165.722L704.917 178.818H682.955V44.1774H706.45L706.459 44.1865ZM708.001 131.25C708.001 148.396 720.71 159.183 735.546 159.183C750.383 159.183 762.514 147.819 762.514 131.25C762.514 114.682 750.383 103.516 735.546 103.516C720.71 103.516 708.001 114.88 708.001 131.25Z"
            fill="white"
        />
        <path
            d="M0.00900063 238.426L57.2187 135.57C60.8175 129.103 70.2517 129.482 73.3093 136.228L97.6976 189.929L113.68 164.441C117.351 158.587 125.982 158.867 129.256 164.955L183.616 265.782V0L0 27.058V238.426H0.00900063ZM132.945 43.4191C141.865 43.4191 149.09 53.6832 149.09 66.3553C149.09 79.0275 141.865 89.2915 132.945 89.2915C124.025 89.2915 116.801 79.0275 116.801 66.3553C116.801 53.6832 124.034 43.4191 132.945 43.4191ZM0.559195 238.796L0 238.426V238.715L0.559195 238.796ZM248.267 21.3578V244.415L265.521 238.715V27.058L248.267 21.3578ZM220.965 253.435L238.219 247.734V18.0387L220.965 12.3385V253.435ZM193.663 262.454L210.917 256.754V9.01935L193.663 3.31911V262.454Z"
            fill="url(#paint0_linear_5543_69475)"
        />
        <path
            d="M640.515 209.151C640.515 202.972 645.523 197.964 651.702 197.964H774.813C780.991 197.964 786 202.972 786 209.151V253.9C786 260.079 780.991 265.088 774.813 265.088H651.702C645.523 265.088 640.515 260.079 640.515 253.9V209.151Z"
            fill="#4CCFFF"
        />
        <path
            d="M654 237.13C654 233.849 654.642 230.94 655.926 228.401C657.25 225.863 659.04 223.91 661.297 222.544C663.554 221.177 666.064 220.493 668.827 220.493C670.929 220.493 672.933 220.962 674.84 221.899C676.747 222.797 678.264 224.008 679.393 225.531V209H687.682V253.473H679.393V248.669C678.381 250.271 676.961 251.559 675.131 252.536C673.302 253.512 671.181 254 668.769 254C666.045 254 663.554 253.297 661.297 251.891C659.04 250.485 657.25 248.513 655.926 245.975C654.642 243.397 654 240.449 654 237.13ZM679.451 237.247C679.451 235.255 679.062 233.556 678.284 232.15C677.505 230.705 676.455 229.612 675.131 228.87C673.808 228.089 672.388 227.698 670.87 227.698C669.352 227.698 667.951 228.069 666.667 228.811C665.383 229.553 664.332 230.647 663.515 232.092C662.737 233.498 662.348 235.177 662.348 237.13C662.348 239.082 662.737 240.8 663.515 242.284C664.332 243.729 665.383 244.842 666.667 245.623C667.99 246.404 669.391 246.795 670.87 246.795C672.388 246.795 673.808 246.424 675.131 245.682C676.455 244.901 677.505 243.807 678.284 242.402C679.062 240.957 679.451 239.238 679.451 237.247Z"
            fill="white"
        />
        <path
            d="M731.203 236.544C731.203 237.715 731.126 238.77 730.97 239.707H707.328C707.523 242.05 708.34 243.886 709.78 245.213C711.22 246.541 712.991 247.205 715.092 247.205C718.128 247.205 720.287 245.897 721.572 243.28H730.386C729.452 246.404 727.662 248.982 725.016 251.013C722.369 253.004 719.12 254 715.267 254C712.154 254 709.352 253.317 706.861 251.95C704.41 250.544 702.483 248.572 701.082 246.033C699.72 243.495 699.039 240.566 699.039 237.247C699.039 233.888 699.72 230.94 701.082 228.401C702.444 225.863 704.351 223.91 706.803 222.544C709.255 221.177 712.076 220.493 715.267 220.493C718.342 220.493 721.085 221.157 723.498 222.485C725.95 223.813 727.837 225.707 729.16 228.167C730.522 230.588 731.203 233.381 731.203 236.544ZM722.739 234.201C722.7 232.092 721.941 230.413 720.463 229.163C718.984 227.874 717.174 227.23 715.034 227.23C713.01 227.23 711.298 227.855 709.897 229.104C708.535 230.315 707.698 232.014 707.387 234.201H722.739Z"
            fill="white"
        />
        <path
            d="M756.13 245.916L764.302 221.021H773L761.033 253.473H751.11L739.201 221.021H747.957L756.13 245.916Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_5543_69475"
                x1={-0.00000635034}
                y1={163.5}
                x2={266}
                y2={163.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#285AFF" />
                <stop offset={0.791667} stopColor="#4CCFFF" />
                <stop offset={1} stopColor="white" />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgLogoPiklibColoredDarkDev;
