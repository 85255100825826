import * as React from "react";
import { SVGProps } from "react";

const SvgIconBullets = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M8.33331 4.16699H18.3333"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.33331 10H18.3333"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <ellipse cx={2.49998} cy={9.99967} rx={1.66667} ry={1.66667} fill="currentColor" />
        <ellipse cx={2.49998} cy={4.16667} rx={1.66667} ry={1.66667} fill="currentColor" />
        <ellipse cx={2.49998} cy={15.8337} rx={1.66667} ry={1.66667} fill="currentColor" />
        <path
            d="M8.33331 15.833H18.3333"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconBullets;
