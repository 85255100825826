import styled, { css, CSSObject } from "styled-components";
import { bp } from "@theme/Theme";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";

export interface ElementProps {
    $style?: CSSObject;
    $styleSmall?: CSSObject;
    $styleMedium?: CSSObject;
    $styleLarge?: CSSObject;
    $styleXLarge?: CSSObject;
    $styleXXLarge?: CSSObject;
}

export const Element = styled.div<ElementProps>`
    ${({ $style }) => ($style && Object.keys($style).length !== 0 ? SortObjKeysAlphabetically($style) : {})}

    ${({ $styleSmall }) =>
        $styleSmall && Object.keys($styleSmall).length !== 0
            ? ` ${bp.small} { ${css(SortObjKeysAlphabetically($styleSmall)).join("")} } `
            : {}}

    ${({ $styleMedium }) =>
        $styleMedium && Object.keys($styleMedium).length !== 0
            ? ` ${bp.medium} { ${css(SortObjKeysAlphabetically($styleMedium)).join("")} } `
            : {}}

    ${({ $styleLarge }) =>
        $styleLarge && Object.keys($styleLarge).length !== 0
            ? ` ${bp.large} { ${css(SortObjKeysAlphabetically($styleLarge)).join("")} } `
            : {}}

    ${({ $styleXLarge }) =>
        $styleXLarge && Object.keys($styleXLarge).length !== 0
            ? ` ${bp.xlarge} { ${css(SortObjKeysAlphabetically($styleXLarge)).join("")} } `
            : {}}

    ${({ $styleXXLarge }) =>
        $styleXXLarge && Object.keys($styleXXLarge).length !== 0
            ? ` ${bp.xxlarge} { ${css(SortObjKeysAlphabetically($styleXXLarge)).join("")} } `
            : {}}
`;
