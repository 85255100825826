export class FolderUtils {
    public static readonly getCurrentFolder = (): string | null => {
        const currentFolder = window.location.pathname;
        if (currentFolder === "/" || !currentFolder.startsWith("/directory")) {
            return null;
        }

        return currentFolder.replace("/directory/", "");
    };
}
