import React from "react";
import { AppPath } from "@pages/paths";
import { isLoggedIn } from "@redux/authSlice";
import { connect, ConnectedProps } from "react-redux";
import { RouteProps } from "react-router";
import { Route, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { RootState } from "@redux/store";

type ComponentProps = RouteProps;

type Props = ReduxProps & ComponentProps & RouteComponentProps;

class PrivateRouteComponent extends React.Component<Props> {
    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn && !this.props.isLoggedIn) {
            this.props.history.push(AppPath.Login);
        }
    }

    public render(): React.ReactElement {
        const { component: Component, ...rest } = this.props;
        if (!this.props.isLoggedIn) {
            return <Redirect to={AppPath.Login} />;
        }

        return (
            <>
                <Route
                    {...rest}
                    render={props => {
                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const PrivateRoute = withRouter(connector(PrivateRouteComponent));
