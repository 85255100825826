import { Api } from "@api/Api";
import { Asset, AssetType } from "@api/graphql/types";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { NewAlert } from "@components/Alert/NewAlert";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import Modal, { ModalList } from "@components/Modal";
import SvgIconCloseX from "@components/svg/IconCloseX";
import SvgIconDelete from "@components/svg/IconDelete";
import { getMessageIdFromError } from "@i18n/messages";
import { AlertActions } from "@redux/alertSlice";
import { AssetActions } from "@redux/assetSlice";
import { Color } from "@theme/Theme";
import React from "react";
import { connect, DispatchProp } from "react-redux";
import { getDocumentType } from "./DocTypes";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    refreshAssets: () => void;
    assets: Asset[];
} & DispatchProp &
    WithLoctoolProps;

interface State {
    isLoading: boolean;
    error: string | null;
}

class AssetDeleteModalComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: false,
        error: null,
    };

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.mounted !== this.props.mounted) {
            this.setState({ error: null });
        }
    }

    private readonly onDelete = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                if (this.props.assets.length > 0) {
                    await Api.deleteAssets(this.props.assets.map(asset => asset.id));
                } else {
                    await Api.deleteAsset(this.props.assets[0].id);
                }
                this.props.dispatch(
                    AlertActions.success({
                        message: this.props.Loctool.formatMessage({ id: "components.assetDeleteModal.deleteSuccess" }),
                    })
                );
                this.props.dispatch(AssetActions.clearSelectedAssets());
                this.props.refreshAssets();
                this.props.onModalClose();
            } catch (error) {
                this.setState({ isLoading: false, error: getMessageIdFromError(error as Error) });
            }
        });
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <Modal
                heading={Loctool.formatMessage({ id: "components.assetDeleteModal.title" })}
                mounted={this.props.mounted}
                onModalClose={this.props.onModalClose}
            >
                <>
                    {this.state.error && (
                        <NewAlert type="error" message={this.state.error} $style={{ marginBottom: 24 }} />
                    )}
                    <Element
                        as="p"
                        $style={{
                            marginBottom: 10,
                            fontSize: 13,
                            fontWeight: 500,
                            lineHeight: "22px",
                            letterSpacing: "0.25px",
                            textAlign: "center",
                        }}
                    >
                        {Loctool.formatMessage({
                            id:
                                this.props.assets.length > 1
                                    ? "components.assetDeleteModal.descriptionMultiple"
                                    : "components.assetDeleteModal.description",
                        })}
                    </Element>
                    <ModalList>
                        {this.props.assets.map(
                            (item: Asset, key: number): React.ReactElement => {
                                const documentType = getDocumentType(item.info.mimeType);

                                return (
                                    <Element
                                        key={key}
                                        $style={{ display: "flex", alignItems: "center", margin: "5px 0" }}
                                    >
                                        {item.assetType === AssetType.image ? (
                                            <Element
                                                as="img"
                                                src={`${item.url}?w=${Math.floor(
                                                    30 * (window.devicePixelRatio ?? 1)
                                                )}&f=auto`}
                                                alt={item.title}
                                                $style={{
                                                    width: 30,
                                                    height: 30,
                                                    marginRight: 10,
                                                    borderRadius: 6,
                                                    objectFit: "cover",
                                                }}
                                            />
                                        ) : (
                                            <Element $style={{ width: 30, height: 30, marginRight: 10 }}>
                                                {documentType.iconRow}
                                            </Element>
                                        )}
                                        <Element
                                            as="span"
                                            $style={{
                                                color: Color.sky500,
                                                fontSize: 13,
                                                fontWeight: 700,
                                                lineHeight: "22px",
                                                letterSpacing: "0.25px",
                                            }}
                                        >
                                            {item.title}
                                        </Element>
                                    </Element>
                                );
                            }
                        )}
                    </ModalList>
                </>

                <Element
                    as="p"
                    $style={{
                        marginTop: 10,
                        marginBottom: 24,
                        fontSize: 13,
                        fontWeight: 500,
                        lineHeight: "22px",
                        letterSpacing: "0.25px",
                        textAlign: "center",
                    }}
                >
                    <LoctoolMessage id="components.assetDeleteModal.alert" />
                </Element>
                <Element $style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 10 }}>
                    <Button.Primary
                        label={Loctool.formatMessage({ id: "common.delete" })}
                        loading={this.state.isLoading}
                        startIcon={<SvgIconDelete width={24} height={24} />}
                        onClick={() => this.onDelete()}
                    />
                    <Button.Secondary
                        label={Loctool.formatMessage({ id: "common.cancel" })}
                        startIcon={<SvgIconCloseX width={24} height={24} />}
                        onClick={() => this.props.onModalClose()}
                    />
                </Element>
            </Modal>
        );
    }
}

const AssetDeleteModal = withLoctool(connect()(AssetDeleteModalComponent));

export { AssetDeleteModal };
