import { Api } from "@api/Api";
import { AnyAssetFolder } from "@api/ApiTypes";
import { withLoctool, WithLoctoolProps, LoctoolMessage } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import Modal from "@components/Modal";
import { Text } from "@components/Text";
import { AlertActions } from "@redux/alertSlice";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    onDeleted: (folderId: string) => void;
    folder: AnyAssetFolder;
} & WithLoctoolProps &
    ReduxProps;

interface State {
    isLoading: boolean;
}

class FolderDeleteModalComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: false,
    };

    private readonly onDelete = async (): Promise<void> => {
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.deleteAssetDirectory(this.props.folder.id);
                this.props.alertSuccess({
                    message: this.props.Loctool.formatMessage({ id: "components.folderDeleteModal.deleteSuccess" }),
                });
                this.props.onDeleted(this.props.folder.id);
                this.props.onModalClose();
            } catch (error) {
                this.props.alertParseError(error as Error);
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <Modal
                heading={Loctool.formatMessage({ id: "components.folderDeleteModal.title" })}
                mounted={this.props.mounted}
                onModalClose={this.props.onModalClose}
            >
                <Text as="p" variant="textXSmall" $style={{ marginBottom: 34, textAlign: "center" }}>
                    <LoctoolMessage id="components.folderDeleteModal.description" />
                </Text>
                <Element $style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 10 }}>
                    <Button.Primary
                        label={Loctool.formatMessage({ id: "common.delete" })}
                        onClick={() => this.onDelete()}
                        loading={this.state.isLoading}
                    />
                    <Button.Secondary
                        label={Loctool.formatMessage({ id: "common.cancel" })}
                        onClick={() => this.props.onModalClose()}
                    />
                </Element>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    alertParseError: AlertActions.parseError,
    alertSuccess: AlertActions.success,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const FolderDeleteModal = withLoctool(connector(FolderDeleteModalComponent));
