import { Api } from "@api/Api";
import { ApiError, ApiErrorCode } from "@api/ApiError";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import Modal from "@components/Modal";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import { Validator, ValidatorMessage } from "@utils/Validator";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { AlertActions } from "@redux/alertSlice";

interface State {
    currentPassword: string;
    currentPasswordError: ValidatorMessage | null;
    newPassword: string;
    newPasswordError: ValidatorMessage | null;
    confirmPassword: string;
    confirmPasswordError: ValidatorMessage | null;
    isSubmitted: boolean;
    isLoading: boolean;
}

class ChangePasswordModalComponent extends React.Component<ReduxProps & WithLoctoolProps, State> {
    private readonly inputId = "password-change-modal-input";

    public state: State = {
        currentPassword: "",
        currentPasswordError: null,
        newPassword: "",
        newPasswordError: null,
        confirmPassword: "",
        confirmPasswordError: null,
        isSubmitted: false,
        isLoading: false,
    };

    private readonly onCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            currentPassword: e.currentTarget.value,
            currentPasswordError: Validator.required(e.currentTarget.value),
        });
    };

    private readonly onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            newPassword: e.currentTarget.value,
            newPasswordError: Validator.validatePassword(e.currentTarget.value),
        });
    };

    private readonly onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            confirmPassword: e.currentTarget.value,
            confirmPasswordError: Validator.validateConfirmPassword(this.state.newPassword, e.currentTarget.value),
        });
    };

    private readonly validateAll = () => {
        const currentPasswordError = Validator.required(this.state.currentPassword);
        const newPasswordError = Validator.validatePassword(this.state.newPassword);
        const confirmPasswordError = Validator.validateConfirmPassword(
            this.state.newPassword,
            this.state.confirmPassword
        );

        this.setState({
            currentPasswordError,
            newPasswordError,
            confirmPasswordError,
        });

        return !currentPasswordError && !newPasswordError && !confirmPasswordError;
    };

    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        const { Loctool } = this.props;

        if (!this.validateAll()) {
            this.setState({ isSubmitted: true });
            return;
        }

        try {
            this.setState({ isLoading: true, isSubmitted: true });
            await Api.changePassword(this.state.currentPassword, this.state.newPassword);
            this.props.alertSuccess({
                message: Loctool.formatMessage({ id: "components.changePasswordModal.success" }),
            });
            this.onModalClose();
        } catch (error) {
            if ((error as ApiError).message === ApiErrorCode.VALIDATION) {
                this.props.alertError({
                    message: Loctool.formatMessage({ id: "components.changePasswordModal.validationError" }),
                    translate: true,
                });
            } else {
                this.props.alertParseError(error as Error);
            }
        } finally {
            this.setState({ isLoading: false });
        }
    };

    private onModalClose = () => {
        this.props.onModalClose();
        // Reset modal state
        this.setState({
            currentPassword: "",
            currentPasswordError: null,
            newPassword: "",
            newPasswordError: null,
            confirmPassword: "",
            confirmPasswordError: null,
            isSubmitted: false,
            isLoading: false,
        });
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;
        const {
            currentPassword,
            currentPasswordError,
            newPassword,
            newPasswordError,
            confirmPassword,
            confirmPasswordError,
            isSubmitted,
            isLoading,
        } = this.state;

        return (
            <Modal
                heading={Loctool.formatMessage({
                    id: "components.changePasswordModal.title",
                })}
                mounted={this.props.mounted}
                initialFocus={`#${this.inputId}`}
                onModalClose={this.onModalClose}
            >
                <form onSubmit={this.onSubmit}>
                    <Input
                        id={this.inputId}
                        type="password"
                        label={Loctool.formatMessage({ id: "components.changePasswordModal.currentPassword" })}
                        value={currentPassword}
                        onChange={this.onCurrentPasswordChange}
                        error={
                            isSubmitted && currentPasswordError
                                ? Loctool.formatMessage({ id: `error.validation.${currentPasswordError}` })
                                : false
                        }
                        $style={{ marginTop: 24 }}
                        name="currentPassword"
                    />
                    <Input
                        type="password"
                        label={Loctool.formatMessage({ id: "components.changePasswordModal.newPassword" })}
                        value={newPassword}
                        onChange={this.onNewPasswordChange}
                        error={
                            isSubmitted && newPasswordError
                                ? Loctool.formatMessage({ id: `error.validation.${newPasswordError}` })
                                : false
                        }
                        $style={{ marginTop: 24 }}
                        name="newPassword"
                    />
                    <Input
                        type="password"
                        label={Loctool.formatMessage({ id: "components.changePasswordModal.confirmPassword" })}
                        value={confirmPassword}
                        onChange={this.onConfirmPasswordChange}
                        error={
                            isSubmitted && confirmPasswordError
                                ? Loctool.formatMessage({ id: `error.validation.${confirmPasswordError}` })
                                : false
                        }
                        $style={{ marginTop: 24 }}
                        name="confirmPassword"
                    />
                    <Button.Primary
                        type="submit"
                        label={Loctool.formatMessage({ id: "common.save" })}
                        loading={isLoading}
                        $style={{ marginTop: 24 }}
                        disabled={!currentPassword || !newPassword || !confirmPassword}
                        fullWidth
                    />
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mounted: state.modals.changePasswordModal.show,
});

const mapDispatchToProps = {
    onModalClose: ModalActions.toggleChangePasswordModal,
    alertSuccess: AlertActions.success,
    alertError: AlertActions.error,
    alertParseError: AlertActions.parseError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const ChangePasswordModal = withLoctool(connector(ChangePasswordModalComponent));
