import { Api } from "@api/Api";
import { AnyAssetFolder } from "@api/ApiTypes";
import { CreateAssetDirectoryInput, UpdateAssetDirectoryInput } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import Modal from "@components/Modal";
import { AlertActions } from "@redux/alertSlice";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

type Props = {
    mounted: boolean;
    currentFolder?: string;
    onModalClose: () => void;
    refreshFolders: (folder?: AnyAssetFolder) => void;
    folder?: AnyAssetFolder;
} & WithLoctoolProps &
    ReduxProps;

interface State {
    input: CreateAssetDirectoryInput | UpdateAssetDirectoryInput;
    isLoading: boolean;
}

class FolderModalComponent extends React.Component<Props, State> {
    private readonly inputId = "folder-modal-input";

    public state: State = {
        input: {
            name: this.props.folder?.name || "",
            parentId: this.props.currentFolder,
        },
        isLoading: false,
    };

    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        const { Loctool } = this.props;

        try {
            if (this.props.folder) {
                const response = await Api.updateAssetDirectory({
                    id: this.props.folder.id,
                    input: this.state.input as UpdateAssetDirectoryInput,
                });
                if (response !== null) {
                    this.props.alertSuccess({
                        message: Loctool.formatMessage({ id: "components.folderModal.messages.updateSuccess" }),
                    });
                    this.props.onModalClose();
                    this.props.refreshFolders(response);
                    return;
                }
            } else {
                const response = await Api.createAssetDirectory({
                    input: this.state.input as CreateAssetDirectoryInput,
                });
                if (response !== null) {
                    this.props.alertSuccess({
                        message: Loctool.formatMessage({ id: "components.folderModal.messages.createSuccess" }),
                    });
                    this.props.onModalClose();
                }
            }
            this.props.refreshFolders();
        } catch (error) {
            this.props.alertParseError(error as Error);
        }
    };

    private readonly onNameChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            input: {
                ...this.state.input,
                name: e.currentTarget.value,
            },
        });
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <Modal
                heading={Loctool.formatMessage({
                    id: `components.folderModal.${this.props.folder !== undefined ? "update" : "create"}Title`,
                })}
                mounted={this.props.mounted}
                onModalClose={this.props.onModalClose}
                initialFocus={`#${this.inputId}`}
            >
                <form onSubmit={this.onSubmit}>
                    <Input
                        id={this.inputId}
                        type="text"
                        label={Loctool.formatMessage({ id: "components.folderModal.form.name" })}
                        value={this.state.input.name || ""}
                        onChange={this.onNameChange}
                    />
                    <Button.Primary
                        type="submit"
                        label={Loctool.formatMessage({ id: "common.save" })}
                        fullWidth
                        loading={this.state.isLoading}
                        $style={{ marginTop: 24 }}
                        disabled={!this.state.input.name || this.state.input.name === (this.props.folder?.name ?? "")}
                    />
                </form>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    alertParseError: AlertActions.parseError,
    alertSuccess: AlertActions.success,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const FolderModal = withLoctool(connector(FolderModalComponent));
