import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import SvgIconCircleActionsAlertInfo from "@components/svg/IconCircleActionsAlertInfo";
import SvgIconCircleActionsSuccess from "@components/svg/IconCircleActionsSuccess";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";
import React from "react";
import styled, { CSSObject } from "styled-components";

interface NewAlertProps {
    type: "success" | "error";
    message: React.ReactNode;
    $style?: CSSObject;
}

export const NewAlert = (props: NewAlertProps) => {
    const { type, message, $style } = props;

    return (
        <StyledNewAlert $type={type} $style={$style}>
            {type === "success" && <SvgIconCircleActionsSuccess width={24} height={24} />}
            {type === "error" && <SvgIconCircleActionsAlertInfo width={24} height={24} />}
            <Element
                as="p"
                $style={{ margin: 0, fontSize: 15, fontWeight: 600, lineHeight: "24px", letterSpacing: "0.75px" }}
            >
                {message}
            </Element>
        </StyledNewAlert>
    );
};

const StyledNewAlert = styled(Flex.Container).attrs({
    $alignItems: "center",
})<{ $type: "success" | "error"; $style?: CSSObject }>`
    padding: 25px;

    border-radius: 12px;

    ${props =>
        props.$type === "success" &&
        `
        color: ${props.theme.alert.success.color};
        background: ${props.theme.alert.success.background};
    `}

    ${props =>
        props.$type === "error" &&
        `
        color: ${props.theme.alert.error.color};
        background: ${props.theme.alert.error.background};
    `}


    svg {
        margin-right: 35px;
    }

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;
