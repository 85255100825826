import { AnyAssetFolder } from "@api/ApiTypes";
import { LoctoolMessage } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { FolderTree } from "@components/FolderTree";
import SvgIconCloseX from "@components/svg/IconCloseX";
import SvgIconFolder from "@components/svg/IconFolder";
import { Paths } from "@pages/paths";
import { AppStateActions } from "@redux/appStateSlice";
import { FolderActions } from "@redux/folderSlice";
import { RootState } from "@redux/store";
import { zIndex } from "@theme/Theme";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

type Props = ReduxProps & RouteComponentProps<{ id?: string }>;

class SidebarComponent extends React.Component<Props> {
    public componentDidMount(): void {
        if (this.props.match.params.id) {
            this.props.fetchSidebarFolders();
        }
    }

    private readonly toggleSidebar = (): void => {
        this.props.toggleSidebar(!this.props.sidebarOpen);
    };

    private readonly onOpenMenu = (item: AnyAssetFolder): void => {
        if (item.children?.[0].id === undefined) {
            this.props.fetchSidebarSubFolders({ folderId: item.id });
        }
    };

    render() {
        return (
            <SidebarWrapper>
                <SidebarHeader>
                    <SidebarTitle>
                        <SvgIconFolder width={16} height={16} />
                        <Element as="span" $style={{ marginLeft: 8 }}>
                            <LoctoolMessage id="components.folders.title" />
                        </Element>
                    </SidebarTitle>
                    <Button.Icon
                        label="Bezárás"
                        inverse
                        icon={<SvgIconCloseX width={16} height={16} />}
                        onClick={() => this.toggleSidebar()}
                    />
                </SidebarHeader>
                <SidebarFolders>
                    <FolderTree
                        items={this.props.sidebarFolders}
                        onOpenMenu={this.onOpenMenu}
                        onClick={(item: AnyAssetFolder) => {
                            this.props.history.push(Paths.Folder(item.id));
                        }}
                        activeOnInit={this.props.currentFolder || ""}
                        isLoading={this.props.isSidebarLoading}
                    />
                </SidebarFolders>
            </SidebarWrapper>
        );
    }
}

const SidebarWrapper = styled.nav`
    box-sizing: border-box;
    position: fixed;
    top: 84px;
    left: 0;
    width: inherit;
    height: calc(100% - 84px);
    transform: translate3d(0, 0, 0);
    color: ${props => props.theme.sidebar.color};
    background-color: ${props => props.theme.sidebar.background};
    border-right: 1px solid ${props => props.theme.sidebar.borderColor};
    overflow: hidden;
    z-index: ${zIndex.sidebar};
`;

const SidebarHeader = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    width: 100%;
    height: 38px;
    padding-right: 10px;
    padding-left: 20px;
    border-bottom: 1px solid ${props => props.theme.sidebar.borderColor};
`;

const SidebarTitle = styled(Flex.Container).attrs({
    as: "h2",
    $alignItems: "center",
})`
    margin: 0 auto 0 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
`;

const SidebarFolders = styled.div`
    height: 100%;
    overflow-y: auto;
    list-style-type: none;
    font-size: 12px;

    button {
        width: 100%;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-left: 20px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    sidebarOpen: state.appState.sidebarOpen,
    currentFolder: state.folders.currentFolder,
    sidebarFolders: state.folders.sidebarFolders,
    isSidebarLoading: state.folders.isSidebarLoading,
});

const mapDispatchToProps = {
    toggleSidebar: AppStateActions.toggleSidebar,
    fetchSidebarFolders: FolderActions.fetchSidebarFolders,
    fetchSidebarSubFolders: FolderActions.fetchSidebarSubFolders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const Sidebar = withRouter(connector(SidebarComponent));
