import * as React from "react";
import { SVGProps } from "react";

const SvgIconSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M7.33331 13.333C10.647 13.333 13.3333 10.6467 13.3333 7.33301C13.3333 4.0193 10.647 1.33301 7.33331 1.33301C4.0196 1.33301 1.33331 4.0193 1.33331 7.33301C1.33331 10.6467 4.0196 13.333 7.33331 13.333Z"
            stroke="currentColor"
            strokeWidth={1.7}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M14 14L12 12" stroke="currentColor" strokeWidth={1.7} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SvgIconSearch;
