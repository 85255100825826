import { AssetType } from "@api/graphql/types";
import { Language } from "@bigfish/react-loctool";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isNil from "lodash/isNil";
import { RootState } from "./store";

export interface AppConfiguration {
    path?: string;
    selectButtonTitle?: string;
    navbarColor?: string;
    insertButtonEnabled?: boolean;
    deleteButtonEnabled?: boolean;
    selectedFile?: string;
    isEmbeddedView?: boolean;
    maximumSelectableAsset?: number;
    initialAssetTypeFilter?: AssetType;
}

interface AppState {
    selectedLanguage: Language;
    sidebarOpen: boolean;
    appConfiguration: AppConfiguration;
    showLoadingBar: boolean;
}

const initialState: AppState = {
    selectedLanguage: Language.HU,
    sidebarOpen: true,
    appConfiguration: {
        deleteButtonEnabled: true,
    },
    showLoadingBar: false,
};

const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        updateLanguage(state, { payload: language }: PayloadAction<Language>) {
            state.selectedLanguage = language;
        },
        toggleSidebar(state, { payload: open }: PayloadAction<boolean>) {
            state.sidebarOpen = open;
        },
        setAppConfiguration(state, { payload: appConfiguration }: PayloadAction<Partial<AppConfiguration>>) {
            state.appConfiguration = { ...state.appConfiguration, ...appConfiguration };
        },
        toggleLoadingBar(state, { payload: showLoadingBar }: PayloadAction<boolean>) {
            state.showLoadingBar = showLoadingBar;
        },
    },
});

export const isEmbeddedView = (state: RootState) => !isNil(state.appState.appConfiguration.isEmbeddedView);

export const AppStateActions = appStateSlice.actions;

export const AppStateReducer = appStateSlice.reducer;
