import * as React from "react";
import { SVGProps } from "react";

const SvgFileXls = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={65}
        height={85}
        viewBox="0 0 65 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect width={65} height={85} rx={10} fill="#00CC67" />
        <path
            d="M27.1504 65L25.4404 62.42L23.9254 65H21.6754L24.3904 60.83L21.6454 56.69H24.0154L25.7104 59.255L27.2404 56.69H29.4904L26.7604 60.83L29.5204 65H27.1504ZM33.511 53.9V65H31.411V53.9H33.511ZM39.5158 65.135C38.8358 65.135 38.2258 65.015 37.6858 64.775C37.1458 64.525 36.7158 64.19 36.3958 63.77C36.0858 63.35 35.9158 62.885 35.8858 62.375H38.0008C38.0408 62.695 38.1958 62.96 38.4658 63.17C38.7458 63.38 39.0908 63.485 39.5008 63.485C39.9008 63.485 40.2108 63.405 40.4308 63.245C40.6608 63.085 40.7758 62.88 40.7758 62.63C40.7758 62.36 40.6358 62.16 40.3558 62.03C40.0858 61.89 39.6508 61.74 39.0508 61.58C38.4308 61.43 37.9208 61.275 37.5208 61.115C37.1308 60.955 36.7908 60.71 36.5008 60.38C36.2208 60.05 36.0808 59.605 36.0808 59.045C36.0808 58.585 36.2108 58.165 36.4708 57.785C36.7408 57.405 37.1208 57.105 37.6108 56.885C38.1108 56.665 38.6958 56.555 39.3658 56.555C40.3558 56.555 41.1458 56.805 41.7358 57.305C42.3258 57.795 42.6508 58.46 42.7108 59.3H40.7008C40.6708 58.97 40.5308 58.71 40.2808 58.52C40.0408 58.32 39.7158 58.22 39.3058 58.22C38.9258 58.22 38.6308 58.29 38.4208 58.43C38.2208 58.57 38.1208 58.765 38.1208 59.015C38.1208 59.295 38.2608 59.51 38.5408 59.66C38.8208 59.8 39.2558 59.945 39.8458 60.095C40.4458 60.245 40.9408 60.4 41.3308 60.56C41.7208 60.72 42.0558 60.97 42.3358 61.31C42.6258 61.64 42.7758 62.08 42.7858 62.63C42.7858 63.11 42.6508 63.54 42.3808 63.92C42.1208 64.3 41.7408 64.6 41.2408 64.82C40.7508 65.03 40.1758 65.135 39.5158 65.135Z"
            fill="white"
        />
        <path d="M51 41H14V14" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 36H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28 36H32" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37 36H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47 36H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M19 32H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M28 32H32" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M37 32H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M47 32H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M19 28H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M37 28H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M47 28H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M19 24H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M37 24H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M47 24H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.2} d="M47 20H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SvgFileXls;
