import { bp, breakpoints, Theme } from "@theme/Theme";
import { createGlobalStyle, css, keyframes } from "styled-components";

// https://accessibility.18f.gov/hidden-content/
export const showForSr = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

type ThemeProps = {
    theme: typeof Theme;
};

export const GlobalStyle = createGlobalStyle<ThemeProps>`
    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
        padding: 0;
        margin: 0;
    }

    :focus {
        outline: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    body {
        margin: 0;
        color: ${props => props.theme.text.body.color};
        background: ${props => props.theme.body.background};
        font-family: ${props => props.theme.text.body.fontFamily};
        font-size: ${props => props.theme.text.body.fontSize}px;
        font-weight: ${props => props.theme.text.body.fontWeight};
        line-height: ${props => props.theme.text.body.lineHeight}px;
        overflow-y: scroll;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    button {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: ${props => props.theme.text.body.fontFamily};
        font-size: 100%;
        margin: 0;
        padding: 0;
    }

    input {
        font-family: ${props => props.theme.text.body.fontFamily};
    }

    a {
        color: inherit;
    }

    article,
    aside,
    header,
    footer,
    main,
    nav {
        display: block;
    }

    ul {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }

    dt {
        font-weight: 400;
    }

    dd {
        font-weight: 600;
    }

    svg {
        display: block;
    }

    img{
        user-drag: none; 
        user-select: none;
    }

    /* Utility Classes */
    .show-for-sr {
        ${showForSr}
    }

    /* Visibility Classes */
    .show-for-small-only {
        ${bp.custom(`min-width: ${breakpoints.medium}px`)} {
            display: none !important;
        }
    }

    .show-for-medium {
        ${bp.custom(`max-width: ${breakpoints.medium - 1}px`)} {
            display: none !important;
        }
    }

    .show-for-medium-only {
        ${`@media (max-width: ${breakpoints.medium - 1}px), (min-width: ${breakpoints.large}px)`} {
            display: none !important;
        }
    }

    .hide-for-medium {
        ${bp.custom(`min-width: ${breakpoints.medium}px`)} {
            display: none !important;
        }
    }

    .show-for-large {
        ${bp.custom(`max-width: ${breakpoints.large - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-large {
        ${bp.custom(`min-width: ${breakpoints.large}px`)} {
            display: none !important;
        }
    }

    .show-for-xlarge {
        ${bp.custom(`max-width: ${breakpoints.xlarge - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-xlarge {
        ${bp.custom(`min-width: ${breakpoints.xlarge}px`)} {
            display: none !important;
        }
    }

    .hide-for-large-only {
        ${`@media (min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge - 1}px)`} {
            display: none !important;
        }
    }
`;

// Keyframes
export const keyframesFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const keyframesRotate360 = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const keyframesSlideUpAndBackWithOpacity = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    10% {
        opacity: 1;
        transform: translateX(0);
    }
    90% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
`;
