import { Asset } from "@api/graphql/types";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { CheckboxComponent, StyledCheckbox } from "@components/Checkbox";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { ControlledMenu, MenuItem } from "@components/Menu";
import { SSkeletonLine } from "@components/Skeleton";
import SvgIconMoreVert from "@components/svg/IconMoreVert";
import { RootState } from "@redux/store";
import { useMenuState } from "@szhsin/react-menu";
import { Color, Transition } from "@theme/Theme";
import { FileSizeUtils } from "@utils/FileSizeUtils";
import { random } from "@utils/Helpers";
import { MimeType } from "@utils/MimeType";
import React, { useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";

const EMPTYGIF = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

type Props = {
    asset: Asset;
    selected: boolean;
    onSelect: (asset: Asset) => void;
    onShiftSelect?: (asset: Asset) => void;
    onUrlCopy: (url: string) => void;
    onRename: (asset: Asset) => void;
    onMove: (asset: Asset) => void;
    onDelete: (asset: Asset) => void;
    onShow: (asset: Asset) => void;
} & WithLoctoolProps &
    ReduxProps;

type State = {
    isLoading: boolean;
    menuIsOpen: boolean;
};

class ImageTileComponent extends React.Component<Props, State> {
    state = {
        isLoading: true,
        menuIsOpen: false,
    };
    private imageRef: React.RefObject<HTMLImageElement> = React.createRef<HTMLImageElement>();
    private assetWrapperRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private dblClickTimer: null | ReturnType<typeof setTimeout> = null;

    componentWillUnmount() {
        if (this.dblClickTimer) {
            clearTimeout(this.dblClickTimer);
        }
    }

    private readonly onClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        if (e.detail === 2) {
            if (this.dblClickTimer) {
                clearTimeout(this.dblClickTimer);
            }
            this.props.onShow(this.props.asset);
        } else if (e.detail === 1) {
            if (this.dblClickTimer) {
                clearTimeout(this.dblClickTimer);
            }
            this.dblClickTimer = setTimeout(() => {
                e.shiftKey && this.props.onShiftSelect
                    ? this.props.onShiftSelect(this.props.asset)
                    : this.props.onSelect(this.props.asset);
            }, 220);
        }
    };

    private readonly onSelect = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
        e.shiftKey && this.props.onShiftSelect
            ? this.props.onShiftSelect(this.props.asset)
            : this.props.onSelect(this.props.asset);
    };

    private readonly activateHover = (): void => {
        this.assetWrapperRef.current?.classList.add("hover");
    };

    private readonly deactivateHover = (): void => {
        this.assetWrapperRef.current?.classList.remove("hover");
    };

    render() {
        const { asset, selected, Loctool } = this.props;
        const imageURL = `${asset.url}?w=${Math.floor(240 * (window.devicePixelRatio ?? 1))}&f=auto`;

        return (
            <AssetWrapper ref={this.assetWrapperRef} $isSelected={selected}>
                <AssetOptions>
                    <CheckboxComponent label="Kiválasztás" onClick={this.onSelect} checked={selected} />
                    <Element $style={{ pointerEvents: "all" }}>
                        <AssetMenu
                            onMenuButtonClick={() => this.activateHover()}
                            onClose={() => this.deactivateHover()}
                        >
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.copyUrl" })}
                                    onClick={() => {
                                        this.props.onUrlCopy(asset.url);
                                    }}
                                />
                            </MenuItem>
                            <MenuItem>
                                <Element
                                    as="a"
                                    href={`${asset.url}?download=1`}
                                    download
                                    $style={{ textDecoration: "none" }}
                                >
                                    <Button.Menu
                                        label={Loctool.formatMessage({ id: "components.assets.options.download" })}
                                    />
                                </Element>
                            </MenuItem>
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.rename" })}
                                    onClick={() => {
                                        this.props.onRename(asset);
                                    }}
                                />
                            </MenuItem>
                            <MenuItem>
                                <Button.Menu
                                    label={Loctool.formatMessage({ id: "components.assets.options.move" })}
                                    onClick={() => {
                                        this.props.onMove(asset);
                                    }}
                                />
                            </MenuItem>
                            {this.props.appConfiguration.deleteButtonEnabled && (
                                <MenuItem>
                                    <Button.Menu
                                        label={Loctool.formatMessage({ id: "components.assets.options.delete" })}
                                        onClick={() => {
                                            this.props.onDelete(asset);
                                        }}
                                    />
                                </MenuItem>
                            )}
                        </AssetMenu>
                    </Element>
                </AssetOptions>
                <AssetContent>
                    <AssetTitle>{asset.title}</AssetTitle>
                    <AssetDetails>
                        <AssetFormat>
                            {MimeType.extension(asset.info.mimeType) || Loctool.formatMessage({ id: "common.unknown" })}
                        </AssetFormat>
                        <span className="size">{FileSizeUtils.humanFileSize(asset.fileSize)}</span>
                        <span className="dimension">{`${asset.info.width}x${asset.info.height}`}</span>
                    </AssetDetails>
                </AssetContent>
                <AssetImage>
                    {this.state.isLoading && (
                        <SSkeletonLine
                            height={"100%"}
                            width={"100%"}
                            style={{ position: "absolute", top: "0px", left: "0px", objectFit: "cover", zIndex: 2 }}
                        />
                    )}
                    <img
                        ref={this.imageRef}
                        src={imageURL}
                        alt={asset.fileName}
                        onClick={this.onClick}
                        onError={() => {
                            if (this.imageRef) {
                                this.imageRef.current!.src = EMPTYGIF;
                            }
                            this.setState({ isLoading: false });
                        }}
                        onLoad={() => {
                            this.setState({ isLoading: false });
                        }}
                        style={this.state.isLoading ? { display: "none" } : {}}
                    />
                </AssetImage>
            </AssetWrapper>
        );
    }
}

export const LoadingAsset = (): React.ReactElement => {
    return (
        <AssetWrapper $isSelected={false}>
            <AssetContent>
                <AssetTitle>
                    <SSkeletonLine width={`${random(50, 120)}px`} height={"12px"} />
                </AssetTitle>
                <AssetDetails>
                    <AssetFormat>
                        <SSkeletonLine />
                    </AssetFormat>
                    <span className="size">
                        <SSkeletonLine width={`${random(30, 50)}px`} height={"11px"} />
                    </span>
                    <span className="dimension">
                        <SSkeletonLine width={"60px"} height={"11px"} />
                    </span>
                </AssetDetails>
            </AssetContent>
            <SSkeletonLine
                height={"100%"}
                width={"100%"}
                style={{ position: "absolute", top: "0px", left: "0px", objectFit: "cover", zIndex: -1 }}
            />
        </AssetWrapper>
    );
};

interface AssetMenuProps {
    onMenuButtonClick: () => void;
    onClose: () => void;
}

export const AssetMenu: React.FC<AssetMenuProps> = ({ onMenuButtonClick, onClose, children }) => {
    const [menuProps, toggleMenu] = useMenuState({ transition: true });
    const ref = useRef(null);

    return (
        <>
            <AssetMenuButton
                ref={ref}
                aria-label="Opciók"
                onClick={() => {
                    onMenuButtonClick();
                    toggleMenu(true);
                }}
            >
                <SvgIconMoreVert width={14} height={14} />
            </AssetMenuButton>
            <ControlledMenu
                {...menuProps}
                anchorRef={ref}
                transition
                align="end"
                onClose={() => {
                    onClose();
                    toggleMenu(false);
                }}
            >
                {children}
            </ControlledMenu>
        </>
    );
};

export const AssetImage = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 15px 15px;
    overflow: hidden;
    cursor: pointer;
    background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M0 0h10v10H0z'  stroke-width='1' stroke='none' fill='hsla(231, 28%, 95%, 1)'/><path d='M10 10h10v10H10z'  stroke-width='1' stroke='none' fill='hsla(231, 28%, 95%, 1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.asset.tile.overlayColor};
        opacity: 0;
        pointer-events: none;
        transition: ${Transition.common};
        z-index: 1;
    }

    img {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: cover;
    }
`;

export const AssetContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.asset.tile.background};
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 3;
`;

export const AssetTitle = styled.div`
    padding: 5px 8px;
    color: ${props => props.theme.asset.tile.titleColor};
    border-top: 1px solid ${props => props.theme.asset.tile.innerBorderColor};
    border-bottom: 1px solid ${props => props.theme.asset.tile.innerBorderColor};
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    transition: color ${Transition.common};
`;

export const AssetDetails = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    padding: 3px 8px 5px;
    color: ${props => props.theme.asset.tile.detailsColor};
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;

    > span {
        display: flex;
        align-items: center;

        + span {
            position: relative;

            &::before {
                content: "";
                position: relative;
                width: 0;
                height: 10px;
                margin: 0 4px;
                border-left: 1px solid ${props => props.theme.asset.tile.innerBorderColor};
            }
        }
    }
`;

export const AssetFormat = styled(Flex.Container).attrs(() => ({
    as: "span",
    $display: "inline-flex",
    $alignItems: "center",
}))`
    svg {
        margin-right: 5px;
    }
`;

export const AssetMenuButton = styled(Flex.Container).attrs({
    as: "button",
    type: "button",
    $alignItems: "center",
    $justifyContent: "center",
})`
    width: 18px;
    height: 18px;
    margin-left: auto;
    color: ${Color.ocean700};
    background: ${Color.white};
    opacity: 0;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity ${Transition.common};
`;

export const AssetOptions = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
    $justifyContent: "space-between",
}))`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    pointer-events: none;
    z-index: 4;

    ${StyledCheckbox} {
        margin-right: 10px;
        opacity: 0;
        transition: opacity ${Transition.common};
        pointer-events: none;
    }
`;

export const AssetWrapper = styled.div<{ $isSelected: boolean }>`
    display: block;
    position: relative;
    width: 220px;
    height: 220px;
    margin-top: 20px;
    margin-right: 20px;
    border: 1px solid ${props => props.theme.asset.tile.borderColor};
    box-shadow: ${props => props.theme.asset.tile.boxShadow};
    border-radius: 10px;
    transition: box-shadow ${Transition.common};

    @supports (display: grid) {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        margin: 0;
    }

    ${props =>
        props.$isSelected &&
        `
        border-color: ${props.theme.asset.tile.selected.outlineColor};

        &::before {
            content: "";
            box-sizing: content-box;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border: 4px solid ${props.theme.asset.tile.selected.outlineColor};;
            border-radius: 12px;
            pointer-events: none;
        }

        ${StyledCheckbox} {
            opacity: 1;
            pointer-events: all;
        }
    `}

    &:hover, &.hover {
        box-shadow: ${props => props.theme.asset.tile.hover.boxShadow};

        ${AssetMenuButton},
        ${StyledCheckbox} {
            opacity: 1;
            pointer-events: all;
        }

        ${AssetImage}::before {
            opacity: ${props => props.theme.asset.tile.overlayOpacity};
        }

        ${AssetTitle} {
            color: ${props => props.theme.asset.tile.hover.titleColor};
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    appConfiguration: state.appState.appConfiguration,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const ImageTile = withLoctool(connector(ImageTileComponent));
