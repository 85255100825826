import React from "react";
import styled, { keyframes } from "styled-components";
import { useAppSelector } from "@redux/store";
import { bp } from "@theme/Theme";

export const UndeterminedLoadingBar = () => {
    const { showLoadingBar } = useAppSelector(state => state.appState);

    if (!showLoadingBar) {
        return null;
    }

    return (
        <ProgressBarWrapper>
            <ProgressBar>
                <BarLine $delay={"0s"} />
            </ProgressBar>
        </ProgressBarWrapper>
    );
};

const indeterminateAnimation = keyframes`
  0% {
      transform:  translateX(0) scaleX(0);
  }
  40% {
      transform:  translateX(0) scaleX(0.4);
  }
  100% {
      transform:  translateX(100%) scaleX(0.5);
  }
`;

const fadeInAnimation = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;

const BarLine = styled.div<{ $delay: string }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.loadingBar.color};
    animation: ${indeterminateAnimation} 0.7s infinite linear;
    animation-delay: ${({ $delay }) => $delay};
    transform-origin: 0% 50%;

    ${bp.medium} {
        animation: ${indeterminateAnimation} 1s infinite linear;
        animation-delay: ${({ $delay }) => $delay};
    }
`;

const ProgressBar = styled.div`
    height: 3px;
    background-color: ${props => props.theme.loadingBar.backgroundColor};
    width: 100%;
    animation: ${fadeInAnimation} 0.6s ease-in;

    ${bp.medium} {
        height: 4px;
    }
`;

const ProgressBarWrapper = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 5;
`;
