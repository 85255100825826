import gql from "graphql-tag";

import { Fragments } from "./Fragments";

class Mutations {
    public static readonly login = gql`
        mutation login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
                account {
                    ...Account
                }
                authToken
                refreshToken
            }
        }
        ${Fragments.account}
    `;

    public static readonly logout = gql`
        mutation logout {
            logout
        }
    `;

    public static readonly refreshAccessToken = gql`
        mutation refreshAccessToken($refreshToken: String!) {
            refreshAccessToken(refreshToken: $refreshToken)
        }
    `;

    public static readonly uploadAsset = gql`
        mutation uploadAsset($title: String!, $file: Upload!, $directoryId: ID) {
            uploadAsset(title: $title, file: $file, directoryId: $directoryId) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly updateAsset = gql`
        mutation updateAsset($id: ID!, $title: String!) {
            updateAsset(id: $id, title: $title) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteAsset = gql`
        mutation deleteAsset($id: ID!) {
            deleteAsset(id: $id)
        }
    `;

    public static readonly deleteAssets = gql`
        mutation deleteAssets($ids: [ID!]!) {
            deleteAssets(ids: $ids)
        }
    `;

    public static readonly createAssetDirectory = gql`
        mutation createAssetDirectory($input: CreateAssetDirectoryInput!) {
            createAssetDirectory(input: $input) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly updateAssetDirectory = gql`
        mutation updateAssetDirectory($id: ID, $input: UpdateAssetDirectoryInput!) {
            updateAssetDirectory(id: $id, input: $input) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly deleteAssetDirectory = gql`
        mutation deleteAssetDirectory($id: ID!) {
            deleteAssetDirectory(id: $id)
        }
    `;

    public static readonly moveAssetsToAssetDirectory = gql`
        mutation moveAssetsToAssetDirectory($assetIds: [ID!]!, $directoryId: ID) {
            moveAssetsToAssetDirectory(assetIds: $assetIds, directoryId: $directoryId)
        }
    `;

    public static readonly initiateAccountRecovery = gql`
        mutation initiateAccountRecovery($email: String!) {
            initiateAccountRecovery(email: $email)
        }
    `;

    public static readonly finishAccountRecovery = gql`
        mutation finishAccountRecovery($email: String!, $token: String!, $password: String!) {
            finishAccountRecovery(email: $email, token: $token, password: $password)
        }
    `;

    public static readonly createAssetExport = gql`
        mutation createAssetExport($assetIds: [ID!]!) {
            createAssetExport(assetIds: $assetIds) {
                ...AssetExportJob
            }
        }
        ${Fragments.assetExportJob}
    `;

    public static readonly markAssetExportDownloadedById = gql`
        mutation markAssetExportDownloadedById($id: ID!) {
            markAssetExportDownloadedById(id: $id)
        }
    `;

    public static readonly changePassword = gql`
        mutation changePassword($currentPassword: String!, $newPassword: String!) {
            changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
        }
    `;

    public static readonly loginWithSignature = gql`
        mutation loginWithSignature($email: String, $projectId: ID!, $apiKey: String!, $signature: String!) {
            loginWithSignature(email: $email, projectID: $projectId, apiKey: $apiKey, signature: $signature) {
                account {
                    ...Account
                }
                authToken
                refreshToken
            }
        }
        ${Fragments.account}
    `;
}

export { Mutations };
