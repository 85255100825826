import { LoctoolMessage } from "@bigfish/react-loctool";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import SvgIconCircleActionsAlertInfo from "@components/svg/IconCircleActionsAlertInfo";
import SvgIconCircleActionsSuccess from "@components/svg/IconCircleActionsSuccess";
import { Text } from "@components/Text";
import { keyframesSlideUpAndBackWithOpacity } from "@theme/GlobalStyle";
import { zIndex } from "@theme/Theme";
import React from "react";
import styled from "styled-components";
import { AlertType } from "./AlertTypes";

interface Props {
    type: AlertType;
    message: string;
    visible: boolean;
    onClose: () => void;
    translate?: boolean;
}

const Alert = ({ translate, ...restProps }: Props) => {
    const renderIcon = () => {
        switch (restProps.type) {
            case AlertType.success:
                return (
                    <Element
                        as={SvgIconCircleActionsSuccess}
                        $style={{ minWidth: 24, width: 24, height: 24, marginRight: 36 }}
                    />
                );
            case AlertType.info:
            case AlertType.error:
            default:
                return (
                    <Element
                        as={SvgIconCircleActionsAlertInfo}
                        $style={{ minWidth: 24, width: 24, height: 24, marginRight: 36 }}
                    />
                );
        }
    };

    return (
        <AlertContainer {...restProps}>
            {renderIcon()}
            <Text as="p" variant="linkSmall" $style={{ color: "inherit" }}>
                {translate ? <LoctoolMessage id={restProps.message} /> : restProps.message}
            </Text>
        </AlertContainer>
    );
};

const AlertContainer = styled(Flex.Container)<Props>`
    display: ${props => (props.visible ? "flex" : "none")};
    position: fixed;
    top: 146px;
    right: 20px;
    padding: 24px;
    color: ${props => props.theme.alert.default.color};
    background: ${props => props.theme.alert.default.background};
    border-radius: 12px;
    box-shadow: ${props => props.theme.alert.boxShadow};
    animation: ${keyframesSlideUpAndBackWithOpacity} 5000ms forwards;
    z-index: ${zIndex.alert};

    ${props =>
        props.type === AlertType.success &&
        `
        color: ${props.theme.alert.success.color};
        background: ${props.theme.alert.success.background};
    `}

    ${props =>
        props.type === AlertType.error &&
        `
        color: ${props.theme.alert.error.color};
        background: ${props.theme.alert.error.background};
    `}
`;

export { Alert, AlertType };
