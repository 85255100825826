import * as React from "react";
import { SVGProps } from "react";

const SvgIconRefresh = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={20}
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M3.85757 7.97874C4.28443 6.49008 5.17162 5.17478 6.39197 4.22134C7.61232 3.26789 9.10319 2.72526 10.6509 2.67122C12.1986 2.61717 13.7237 3.05448 15.0076 3.92047C16.2915 4.78647 17.2682 6.03668 17.7979 7.49194"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.6634 12.8507C17.1118 14.1582 16.1926 15.2775 15.0175 16.0729C13.8423 16.8683 12.4616 17.3057 11.0428 17.332C9.62396 17.3583 8.228 16.9724 7.02417 16.221C5.82034 15.4697 4.86036 14.3852 4.26064 13.0991"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.76172 12.4458L3.62259 12.763L2.59289 14.6646"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.1111 8.10815L18.2502 7.79094L19.2799 5.88932"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconRefresh;
