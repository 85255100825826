import React from "react";
import { Api } from "@api/Api";
import { AnyAssetFolder } from "@api/ApiTypes";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import Modal from "@components/Modal";
import { SSkeletonLine } from "@components/Skeleton";
import SvgIconArrowChevronBack from "@components/svg/IconArrowChevronBack";
import SvgIconArrowChevronForward from "@components/svg/IconArrowChevronForward";
import SvgIconFolder from "@components/svg/IconFolder";
import { Text } from "@components/Text";
import styled from "styled-components";
import { withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { AlertActions } from "@redux/alertSlice";
import { connect, ConnectedProps } from "react-redux";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    folder: AnyAssetFolder;
    refreshFolders: (folder?: AnyAssetFolder) => void;
} & WithLoctoolProps &
    ReduxProps;

interface State {
    isLoading: boolean;
    folders: AnyAssetFolder[];
    selectedFolder: AnyAssetFolder | null;
    currentFolder: AnyAssetFolder | null;
}

class FolderMoveModalComponent extends React.Component<Props, State> {
    public state: State = {
        isLoading: true,
        folders: [],
        selectedFolder: null,
        currentFolder: null,
    };

    componentDidMount() {
        this.fetchFolders();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if ((!prevProps.mounted && this.props.mounted) || prevState.currentFolder !== this.state.currentFolder) {
            this.fetchFolders();
        }
    }

    private readonly fetchFolders = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await Api.getAssetDirectoriesByParentId(
                        this.state.currentFolder ? this.state.currentFolder.id : null
                    );
                    this.setState({
                        isLoading: false,
                        folders: response,
                    });
                } catch (error) {
                    this.props.alertParseError(error as Error);
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    private readonly onNavigate = (folder: AnyAssetFolder | null): void => {
        this.setState({
            currentFolder: folder,
            selectedFolder: null,
        });
    };

    private readonly onMove = (): void => {
        const selectedFolder = this.state.selectedFolder;
        if (!selectedFolder) {
            return;
        }
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    await Api.updateAssetDirectory({
                        id: this.props.folder.id,
                        input: {
                            name: this.props.folder.name,
                            parentId: selectedFolder.id,
                        },
                    });
                    this.props.alertSuccess({
                        message: this.props.Loctool.formatMessage({ id: "components.folderMoveModal.moveSuccess" }),
                    });
                    this.props.refreshFolders();
                    this.props.onModalClose();
                } catch (error) {
                    this.props.alertParseError(error as Error);
                    this.setState({ isLoading: false });
                }
            }
        );
    };

    private readonly toggleSelectedFolder = (folder: AnyAssetFolder): void => {
        const { selectedFolder } = this.state;
        if (selectedFolder?.id === folder.id) {
            this.setState({
                selectedFolder: null,
            });
        }
        this.setState({
            selectedFolder: folder,
        });
    };

    private readonly dataLoadingView = (rowCount = 4) => {
        let i = 0;
        return (
            <>
                {new Array(rowCount).fill("1").map(() => {
                    return (
                        <FolderSelect key={`skeleton-${++i}`}>
                            <SSkeletonLine width={"14px"} />
                            <Element $style={{ width: 10 }} />
                            <SSkeletonLine width={"60%"} />
                            <Element $style={{ width: 10, marginLeft: "auto" }} />
                            <SSkeletonLine width={"14px"} />
                        </FolderSelect>
                    );
                })}
            </>
        );
    };

    private readonly renderFolders = (folders: AnyAssetFolder[]): React.ReactElement[] => {
        return folders
            .filter(f => f.id !== this.props.folder.id)
            .map(
                (folder: AnyAssetFolder, key: number): React.ReactElement => {
                    return (
                        <FolderSelect
                            key={key}
                            selected={folder.id === this.state.selectedFolder?.id}
                            onClick={() => this.toggleSelectedFolder(folder)}
                        >
                            <SvgIconFolder width={14} height={14} />
                            <Text as="span" variant="textXSmall" $style={{ margin: "0 8px" }}>
                                {folder.name}
                            </Text>
                            {folder.children?.length && (
                                <FolderButton onClick={() => this.onNavigate(folder)}>
                                    <SvgIconArrowChevronForward width={15} height={15} />
                                </FolderButton>
                            )}
                        </FolderSelect>
                    );
                }
            );
    };

    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <Modal
                heading={Loctool.formatMessage({ id: "components.folderMoveModal.title" })}
                mounted={this.props.mounted}
                onModalClose={this.props.onModalClose}
            >
                <Element $style={{ maxHeight: 400, padding: 10, margin: -10, overflowY: "auto" }}>
                    {!!this.state.currentFolder && (
                        <ParentFolderButton onClick={() => this.onNavigate(this.state.currentFolder?.parent ?? null)}>
                            <SvgIconArrowChevronBack width={15} height={15} />
                            <Element as={SvgIconFolder} $style={{ width: 14, height: 14, marginLeft: 5 }} />
                            <Text as="span" variant="textXSmall" $style={{ marginLeft: 8 }}>
                                {Loctool.formatMessage({ id: "components.folderMoveModal.back" })}
                            </Text>
                        </ParentFolderButton>
                    )}
                    {this.state.isLoading ? this.dataLoadingView() : this.renderFolders(this.state.folders)}
                </Element>
                <Button.Primary
                    label={
                        this.state.selectedFolder
                            ? Loctool.formatMessage(
                                  { id: "components.folderMoveModal.moveButtonLabel" },
                                  { folderName: this.state.selectedFolder.name }
                              )
                            : Loctool.formatMessage({ id: "common.move" })
                    }
                    onClick={() => this.onMove()}
                    loading={this.state.isLoading}
                    disabled={!this.state.selectedFolder}
                    fullWidth
                    $style={{ marginTop: 24 }}
                />
            </Modal>
        );
    }
}

const FolderSelect = styled(Flex.Container).attrs({
    $alignItems: "center",
})<{ selected?: boolean }>`
    padding: 10px 20px;
    color: ${props => props.theme.folderSelect.color};
    border: 1px solid ${props => props.theme.folderSelect.borderColor};
    border-radius: 6px;
    cursor: pointer;

    &:not(:nth-of-type(1)) {
        margin-top: 10px;
    }

    &:hover {
        border-color: ${props => props.theme.folderSelect.selected.borderColor};
        box-shadow: ${props => props.theme.folderSelect.selected.boxShadow};
        outline: 1px solid ${props => props.theme.folderSelect.selected.borderColor};
    }

    ${props =>
        props.selected &&
        `
        border-color: ${props.theme.folderSelect.selected.borderColor};
        box-shadow: ${props.theme.folderSelect.selected.boxShadow};
        outline: 1px solid ${props.theme.folderSelect.selected.borderColor};
    `}
`;

const FolderButton = styled(Flex.Container).attrs({
    as: "button",
    $alignItems: "center",
    $justifyContent: "center",
})`
    min-width: 22px;
    width: 22px;
    height: 22px;
    margin-left: auto;
    border-radius: 50%;

    &:hover {
        background: ${props => props.theme.folderSelect.folderButton.hover.background};
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 36px;
        height: 36px;
    }
`;

const ParentFolderButton = styled.button.attrs({
    $alignItems: "center",
})`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 2px solid ${props => props.theme.folderSelect.parentFolderButton.borderColor};
    margin-bottom: 10px;
`;

const mapDispatchToProps = {
    alertParseError: AlertActions.parseError,
    alertSuccess: AlertActions.success,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const FolderMoveModal = withLoctool(connector(FolderMoveModalComponent));
