export enum AlertType {
    success = "success",
    info = "info",
    error = "error",
}

export interface AlertOptions {
    message: string;
    type: AlertType;
    callback?: () => Promise<void> | void;
    timeout?: number;
    translate?: boolean;
}

export type AlertPayload = Partial<Omit<AlertOptions, "message">> & Pick<AlertOptions, "message">;
