import * as React from "react";
import { SVGProps } from "react";

const SvgFileUnknown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={65}
        height={85}
        viewBox="0 0 65 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect width={65} height={85} rx={10} fill="#00BAFF" />
        <rect x={14} y={14} width={37} height={57} rx={6} stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 22H35.7838M46 22H40.1622" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 62H35.7838M46 62H40.1622" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 38H30.6757M46 38H35.0541" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 30H46" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 54H46" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M19 46H46" stroke="white" strokeWidth={2} strokeLinecap="round" />
    </svg>
);

export default SvgFileUnknown;
