import * as React from "react";
import { SVGProps } from "react";

const SvgIconCircleActionsClose = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <circle cx={8.99984} cy={8.99984} r={7.33333} stroke="currentColor" strokeWidth={2} />
        <path d="M7 7L11 11" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 11L11 7" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SvgIconCircleActionsClose;
