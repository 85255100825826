import { AnyAssetFolder } from "@api/ApiTypes";
import { FolderDeleteModal } from "@components/Folder/FolderDeleteModal";
import { FolderModal } from "@components/Folder/FolderModal";
import { FolderButton } from "@components/FolderButton";
import { Section } from "@components/Section";
import { Paths } from "@pages/paths";
import { FolderActions } from "@redux/folderSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState } from "@redux/store";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { FolderMoveModal } from "./Assets/FolderMoveModal";

type Props = ReduxProps & RouteComponentProps;

class FoldersComponent extends Component<Props> {
    private readonly renderFolders = (folders: AnyAssetFolder[]): React.ReactElement[] => {
        return folders.map(
            (folder: AnyAssetFolder): React.ReactElement => {
                return (
                    <FolderButton
                        key={folder.id}
                        disabled={false}
                        btnLabel={folder.name}
                        isDeletable={folder.deletable}
                        onClick={() => this.props.history.push(Paths.Folder(folder.id))}
                        onRenameClick={() => this.props.toggleRenameFolderModal(folder)}
                        onDeleteClick={() => this.props.toggleDeleteFolderModal(folder)}
                        onMoveClick={() => this.props.toggleMoveFolderModal(folder)}
                    />
                );
            }
        );
    };

    public render(): React.ReactElement {
        const { folders, renameFolderModal, deleteFolderModal, moveFolderModal } = this.props;

        return (
            <>
                {folders.length > 0 && (
                    <Section>
                        <StyledFolders>{this.renderFolders(folders)}</StyledFolders>
                    </Section>
                )}
                {renameFolderModal.show && renameFolderModal.folder && (
                    <FolderModal
                        mounted={renameFolderModal.show}
                        onModalClose={this.props.toggleRenameFolderModal}
                        refreshFolders={() => {
                            this.props.fetchFolders();
                            if (this.props.currentFolder) {
                                this.props.fetchSidebarSubFolders({ folderId: this.props.currentFolder });
                                this.props.fetchBreadcrumbs(this.props.currentFolder);
                            } else {
                                this.props.fetchSidebarFolders();
                            }
                        }}
                        folder={renameFolderModal.folder}
                    />
                )}
                {deleteFolderModal.show && deleteFolderModal.folder && (
                    <FolderDeleteModal
                        mounted={deleteFolderModal.show}
                        onModalClose={this.props.toggleDeleteFolderModal}
                        onDeleted={(folderId: string) => {
                            this.props.fetchFolders();
                            this.props.fetchSidebarFolders();
                            if (this.props.currentFolder) {
                                this.props.fetchSidebarSubFolders({ folderId: this.props.currentFolder });
                            }
                            if (folderId === this.props.currentFolder) {
                                this.props.history.push(Paths.Home);
                            }
                        }}
                        folder={deleteFolderModal.folder}
                    />
                )}
                {moveFolderModal.show && moveFolderModal.folder && (
                    <FolderMoveModal
                        mounted={moveFolderModal.show}
                        onModalClose={this.props.toggleMoveFolderModal}
                        refreshFolders={() => {
                            this.props.fetchFolders();
                            this.props.fetchSidebarFolders();
                            if (this.props.currentFolder) {
                                this.props.fetchSidebarSubFolders({ folderId: this.props.currentFolder });
                                this.props.fetchBreadcrumbs(this.props.currentFolder);
                            } else {
                                this.props.fetchSidebarFolders();
                            }
                        }}
                        folder={moveFolderModal.folder}
                    />
                )}
            </>
        );
    }
}

const StyledFolders = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    folders: state.folders.folders,
    currentFolder: state.folders.currentFolder,
    renameFolderModal: state.modals.renameFolderModal,
    deleteFolderModal: state.modals.deleteFolderModal,
    moveFolderModal: state.modals.moveFolderModal,
});

const mapDispatchToProps = {
    fetchFolders: FolderActions.fetchFolders,
    fetchSidebarFolders: FolderActions.fetchSidebarFolders,
    fetchSidebarSubFolders: FolderActions.fetchSidebarSubFolders,
    fetchBreadcrumbs: FolderActions.fetchBreadcrumbs,
    toggleRenameFolderModal: ModalActions.toggleRenameFolderModal,
    toggleDeleteFolderModal: ModalActions.toggleDeleteFolderModal,
    toggleMoveFolderModal: ModalActions.toggleMoveFolderModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const Folders = withRouter(connector(FoldersComponent));
