import * as React from "react";
import { SVGProps } from "react";

const SvgIconEdit = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M2.44677 10.4794L11.7461 1.18007C11.7648 1.17429 11.7858 1.16818 11.8087 1.16196C11.939 1.12668 12.1266 1.09001 12.3469 1.08697C12.7725 1.0811 13.3364 1.19759 13.8878 1.74896C14.4391 2.30032 14.5556 2.86419 14.5497 3.28981C14.5467 3.51009 14.51 3.69773 14.4748 3.82799C14.4685 3.85095 14.4624 3.87189 14.4566 3.89065L5.15735 13.1899L2.07715 13.5596L2.44677 10.4794ZM14.4182 4.00008L14.419 3.99821C14.4183 3.99978 14.418 4.00051 14.4181 4.0004L14.4182 4.00008Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
        <path d="M9.51147 2.35449L13.1031 5.94615" stroke="currentColor" strokeWidth={1.5} />
    </svg>
);

export default SvgIconEdit;
