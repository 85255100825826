import { Asset } from "@api/graphql/types";
import { LoctoolMessage, useLoctool, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import BreadcrumbBar from "@components/BreadcrumbBar";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { FolderModal } from "@components/Folder/FolderModal";
import { Menu, MenuDivider, MenuItem } from "@components/Menu";
import { Separator } from "@components/Separator";
import SvgIconChevronDown from "@components/svg/IconArrowChevronDown";
import SvgIconCloseX from "@components/svg/IconCloseX";
import SvgIconFolderPlus from "@components/svg/IconFolderPlus";
import SvgIconLockUnlocked from "@components/svg/IconLockUnlocked";
import SvgIconLogOutCircle from "@components/svg/IconLogOutCircle";
import SvgIconProfile from "@components/svg/IconProfile";
import SvgIconSearch from "@components/svg/IconSearch";
import SvgIconUpload from "@components/svg/IconUpload";
import SvgLogoPiklibColored from "@components/svg/LogoPiklibColored";
import SvgLogoPiklibColoredDark from "@components/svg/LogoPiklibColoredDark";
import SvgLogoPiklibColoredDarkDev from "@components/svg/LogoPiklibColoredDarkDev";
import SvgLogoPiklibColoredDev from "@components/svg/LogoPiklibColoredDev";
import { UploadModal } from "@components/Upload/UploadModal";
import { AppPath, Paths } from "@pages/paths";
import { isEmbeddedView } from "@redux/appStateSlice";
import { AssetActions } from "@redux/assetSlice";
import { AuthActions } from "@redux/authSlice";
import { FolderActions, getCurrentFolderName } from "@redux/folderSlice";
import { ModalActions } from "@redux/modalSlice";
import { RootState, useAppDispatch, useAppSelector } from "@redux/store";
import { Color, Transition, zIndex } from "@theme/Theme";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import debounce from "lodash/debounce";
import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import { UndeterminedLoadingBar } from "./UndeterminateLoadingBar";

type Props = {
    initialPath: string;
    hideAppBarBottom?: boolean;
    maxSelectableAsset: number;
    selectedAssets: Asset[];
    refreshAssets: () => void;
    selectAllAssets: () => void;
    clearSelectedAssets: () => void;
} & ReduxProps &
    RouteComponentProps &
    WithLoctoolProps;

class AppBarComponent extends Component<Props> {
    public render(): React.ReactElement {
        const { Loctool } = this.props;

        return (
            <>
                <StyledAppBar>
                    <AppBarTop>
                        <Flex.Container $alignItems="flex-end">
                            <button
                                onClick={() => this.props.history.push(Paths.Home)}
                                aria-label={Loctool.formatMessage({ id: "components.appBar.tabs.folders" })}
                            >
                                {AppBarLogo()}
                            </button>
                        </Flex.Container>
                        <Flex.Container>
                            <AppBarTabButton
                                $isActive={[AppPath.Home, AppPath.Folder].includes(this.props.match.path)}
                                onClick={() =>
                                    this.props.history.push(
                                        this.props.currentFolder ? Paths.Folder(this.props.currentFolder) : Paths.Home
                                    )
                                }
                            >
                                <LoctoolMessage id="components.appBar.tabs.folders" />
                            </AppBarTabButton>
                            <AppBarTabButton
                                $isActive={this.props.match.path === AppPath.Search}
                                onClick={() => this.props.history.push(Paths.Search)}
                            >
                                <LoctoolMessage id="components.appBar.tabs.search" />
                            </AppBarTabButton>
                        </Flex.Container>
                        {this.props.isEmbeddedView ? (
                            <Button.Icon
                                label="Bezárás"
                                icon={<SvgIconCloseX width={20} height={20} />}
                                onClick={() => {
                                    sendPMEvent(OutgoingEvent.Close);
                                }}
                            />
                        ) : (
                            <AppBarMenu />
                        )}
                    </AppBarTop>
                    {!this.props.hideAppBarBottom && (
                        <AppBarBottom>
                            <BreadcrumbBar />
                            <AppBarSearch />
                            <Button.Text
                                label="Új mappa"
                                startIcon={<SvgIconFolderPlus width={16} height={16} />}
                                adjust
                                onClick={() => this.props.toggleCreateFolderModal()}
                            />
                            <Separator vertical />
                            <Button.Text
                                label={Loctool.formatMessage({ id: "common.upload" })}
                                startIcon={<SvgIconUpload width={16} height={16} />}
                                adjust
                                onClick={() => this.props.toggleFileUploadModal()}
                            />
                        </AppBarBottom>
                    )}
                    {this.props.createFolderModal.show && (
                        <FolderModal
                            mounted={this.props.createFolderModal.show}
                            onModalClose={() => this.props.toggleCreateFolderModal()}
                            refreshFolders={folder => {
                                this.props.fetchFolders();
                                if (folder) {
                                    this.props.fetchSidebarSubFolders({ folderId: folder.id });
                                } else {
                                    this.props.fetchSidebarFolders();
                                }
                            }}
                            currentFolder={this.props.currentFolder || undefined}
                        />
                    )}
                    <UploadModal
                        mounted={this.props.fileUploadModal.show}
                        onToggleModal={() => this.props.toggleFileUploadModal()}
                    />
                    <UndeterminedLoadingBar />
                </StyledAppBar>
            </>
        );
    }
}

const AppBarMenu = () => {
    const me = useSelector((state: RootState) => state.auth.me);
    const currentProjectId = useAppSelector(state => state.auth.projectId);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const Loctool = useLoctool();

    const selectProject = (projectId: string) => {
        dispatch(AuthActions.setProjectId(projectId));
        history.push(Paths.Home);
        document.location.reload();
    };

    return (
        <div>
            <Menu
                transition
                menuButton={({ open }) => (
                    <Element as="button" $style={{ textAlign: "right" }}>
                        <Flex.Container>
                            <Element $style={{ marginRight: 2 }}>
                                <Flex.Container>
                                    <SvgIconProfile width={14} height={14} />
                                    <Element
                                        as="span"
                                        $style={{
                                            display: "block",
                                            marginLeft: 2,
                                            fontSize: 13,
                                            fontWeight: 500,
                                            lineHeight: "16px",
                                            letterSpacing: "0.25px",
                                        }}
                                    >
                                        <LoctoolMessage
                                            id="components.appBar.menu.greeting"
                                            values={{ name: `${me?.lastName} ${me?.firstName}` }}
                                        />
                                    </Element>
                                </Flex.Container>
                                <Element
                                    as="span"
                                    $style={{
                                        display: "block",
                                        marginTop: 2,
                                        color: Color.ocean500,
                                        fontSize: 10,
                                        fontWeight: 600,
                                        lineHeight: "10px",
                                        letterSpacing: "0.25px",
                                    }}
                                >
                                    {me?.projects.find(p => p.id === currentProjectId)?.title}
                                </Element>
                            </Element>
                            <Element
                                $style={{
                                    minWidth: 14,
                                    width: 14,
                                    height: 14,
                                    transform: `rotate(${open ? "180deg" : 0})`,
                                }}
                            >
                                <SvgIconChevronDown width={14} height={14} />
                            </Element>
                        </Flex.Container>
                    </Element>
                )}
            >
                {me?.projects.map(project => (
                    <MenuItem key={project.id}>
                        <AppBarMenuCheckbox>
                            <input
                                type="radio"
                                name="project"
                                checked={project.id === currentProjectId}
                                onChange={() => selectProject(project.id)}
                            />
                            <AppBarMenuCheckboxCheckmark />
                            <AppBarMenuCheckboxLabel>{project.title}</AppBarMenuCheckboxLabel>
                        </AppBarMenuCheckbox>
                    </MenuItem>
                ))}
                <MenuDivider />
                <MenuItem>
                    <Button.Menu
                        label={Loctool.formatMessage({ id: "components.appBar.menu.changePassword" })}
                        startIcon={<SvgIconLogOutCircle width={18} height={18} />}
                        onClick={() => {
                            dispatch(ModalActions.toggleChangePasswordModal());
                        }}
                    />
                </MenuItem>
                <MenuItem>
                    <Button.Menu
                        label={Loctool.formatMessage({ id: "components.appBar.menu.logOut" })}
                        startIcon={<SvgIconLockUnlocked width={18} height={18} />}
                        onClick={() => dispatch(AuthActions.logout())}
                    />
                </MenuItem>
            </Menu>
        </div>
    );
};

const AppBarSearch = () => {
    const storedSearch = useAppSelector(state => state.assets.assetOptions.control?.search);
    const [search, setSearch] = useState(storedSearch ?? "");
    const [searchIsOpen, setSearchIsOpen] = useState(!!storedSearch);
    const folderName = useAppSelector(getCurrentFolderName);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const Loctool = useLoctool();

    useEffect(() => {
        if (searchIsOpen) {
            inputRef.current?.focus();
        }
    }, [searchIsOpen]);

    useEffect(() => {
        if (storedSearch === null) {
            setSearch("");
        }
    }, [storedSearch]);

    const runSearch = useMemo(
        () => debounce(searchString => dispatch(AssetActions.searchFolderAssets(searchString)), 500),
        [dispatch]
    );

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        if (value.length === 0) {
            runSearch(null);
        }
        if (value.length >= 3) {
            runSearch(value);
        }
    };

    const handleBlur = () => {
        if (search.length === 0) {
            setSearchIsOpen(false);
        }
    };

    const clearSearch = () => {
        setSearch("");
        setSearchIsOpen(false);
        runSearch(null);
    };

    return (
        <Element
            $style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                width: 440,
                height: "100%",
                marginLeft: "auto",
            }}
        >
            {!searchIsOpen && (
                <Element
                    $style={{
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        right: 0,
                        transform: "translateY(-50%)",
                    }}
                >
                    <Button.Icon
                        label={Loctool.formatMessage({ id: "common.search" })}
                        icon={<SvgIconSearch width={16} height={16} />}
                        onClick={() => setSearchIsOpen(true)}
                    />
                    <Separator vertical />
                </Element>
            )}

            <StyledAppBarSearch $isOpen={searchIsOpen}>
                <input
                    ref={inputRef}
                    value={search}
                    placeholder={Loctool.formatMessage({ id: "components.appBar.search" }, { folderName })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Button.Icon
                    label={Loctool.formatMessage({ id: "common.close" })}
                    icon={<SvgIconCloseX width={16} height={16} />}
                    $style={{ position: "absolute", top: "50%", right: 15, transform: "translateY(-50%)" }}
                    onClick={clearSearch}
                />
            </StyledAppBarSearch>
        </Element>
    );
};

export const AuthAppBar = () => {
    const isEmbedded = useSelector((state: RootState) => isEmbeddedView(state));
    if (isEmbedded) {
        return (
            <StyledAuthAppBar>
                {AppBarLogo()}
                <div style={{ position: "absolute", right: 16 }}>
                    <Button.Icon
                        label="Bezárás"
                        icon={<SvgIconCloseX width={20} height={20} />}
                        onClick={() => {
                            sendPMEvent(OutgoingEvent.Close);
                        }}
                    />
                </div>
            </StyledAuthAppBar>
        );
    }
    return <StyledAuthAppBar>{AppBarLogo()}</StyledAuthAppBar>;
};

const AppBarLogo = () => {
    const prefersDarkScheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const betaEnvironment = process.env.REACT_APP_DEPLOYMENT_ENV === "beta";

    if (prefersDarkScheme && betaEnvironment) {
        return <SvgLogoPiklibColoredDarkDev width={83} height={28} />;
    }

    if (prefersDarkScheme) {
        return <SvgLogoPiklibColoredDark width={83} height={28} />;
    }

    if (betaEnvironment) {
        return <SvgLogoPiklibColoredDev width={83} height={28} />;
    }

    return <SvgLogoPiklibColored width={83} height={28} />;
};

const AppBarMenuCheckboxCheckmark = styled.div`
    min-width: 18px;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: ${props => props.theme.checkbox.background};
    border-radius: 50%;
`;

const AppBarMenuCheckboxLabel = styled.span`
    color: ${props => props.theme.appBar.menu.checkboxLabel.color};
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    user-select: none;
`;

const AppBarMenuCheckbox = styled(Flex.Container).attrs({
    as: "label",
    $alignItems: "center",
})`
    position: relative;
    cursor: pointer;

    &:not(:nth-of-type(1)) {
        margin-top: 12px;
    }

    &:hover {
        opacity: 0.7;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked {
            & ~ ${AppBarMenuCheckboxCheckmark} {
                background: ${props => props.theme.appBar.menu.radioButton.background};
                border: 5px solid ${props => props.theme.appBar.menu.radioButton.borderColor};
            }

            & ~ ${AppBarMenuCheckboxLabel} {
                color: ${props => props.theme.appBar.menu.checkboxLabel.active.color};
            }
        }
    }
`;

const StyledAppBar = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${zIndex.appBar};
`;

const AppBarTop = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
    $justifyContent: "space-between",
}))`
    height: 46px;
    padding: 0 20px;
    color: ${props => props.theme.appBar.top.color};
    background: ${props => props.theme.appBar.top.background};
    border-bottom: 1px solid ${props => props.theme.appBar.top.borderColor};
`;

const AppBarBottom = styled(Flex.Container).attrs(() => ({
    $alignItems: "center",
}))`
    height: 38px;
    padding: 0 20px;
    color: ${props => props.theme.appBar.bottom.color};
    background-color: ${props => props.theme.appBar.bottom.background};
    border-bottom: 1px solid ${props => props.theme.appBar.bottom.borderColor};
`;

export const AppBarTabButton = styled(Flex.Container).attrs({
    as: "button",
    $alignItems: "center",
})<{ $isActive?: boolean }>`
    height: 46px;
    padding: 0 40px;
    color: ${props => props.theme.appBar.top.tabButton.color};
    opacity: 0.5;
    border-bottom: 4px solid transparent;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: center;

    &:hover {
        opacity: 1;
    }

    ${props =>
        props.$isActive &&
        `
        color: ${props.theme.appBar.top.tabButton.active.color};
        opacity: 1;
        border-color: ${props.theme.appBar.top.tabButton.borderColor};
    `}
`;

const StyledAuthAppBar = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    width: 100%;
    height: 46px;
    background: ${props => props.theme.appBar.auth.background};
    border-bottom: 1px solid ${props => props.theme.appBar.auth.borderColor};
`;

const StyledAppBarSearch = styled.div<{ $isOpen: boolean }>`
    position: relative;
    width: 100%;
    max-width: 0;
    height: 100%;
    margin-right: 15px;
    transition: max-width ${Transition.common};
    overflow: hidden;

    ${props =>
        props.$isOpen &&
        `
        max-width: 440px;
    `}

    input {
        width: 100%;
        height: 100%;
        padding-right: 44px;
        padding-left: 14px;
        color: ${props => props.theme.appBar.search.color};
        background: ${props => props.theme.appBar.search.background};
        border: 1px solid ${props => props.theme.appBar.search.borderColor};
        border-top: 0;
        border-bottom: 0;

        &::placeholder {
            color: ${props => props.theme.appBar.search.placeholder.color};
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.75px;
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    sidebarOpen: state.appState.sidebarOpen,
    isEmbeddedView: isEmbeddedView(state),
    currentFolder: state.folders.currentFolder,
    navbarColor: state.appState.appConfiguration.navbarColor,
    createFolderModal: state.modals.createFolderModal,
    fileUploadModal: state.modals.fileUploadModal,
});

const mapDispatchToProps = {
    fetchFolders: FolderActions.fetchFolders,
    fetchSidebarFolders: FolderActions.fetchSidebarFolders,
    fetchSidebarSubFolders: FolderActions.fetchSidebarSubFolders,
    toggleCreateFolderModal: ModalActions.toggleCreateFolderModal,
    toggleFileUploadModal: ModalActions.toggleFileUploadModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const AppBar = withLoctool(withRouter(connector(AppBarComponent)));
