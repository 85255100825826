import { ActionBar } from "@components/ActionBar";
import { AppBar } from "@components/AppBar";
import { Assets } from "@components/Assets/Assets";
import { Input } from "@components/Input";
import { Element } from "@components/Element";
import { SearchMain as Main } from "@components/Main";
import SvgIconSearch from "@components/svg/IconSearch";
import { Text } from "@components/Text";
import { SearchActions } from "@redux/searchSlice";
import { RootState } from "@redux/store";
import { Constants } from "@utils/Constants";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { LoctoolMessage, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";

interface RouteProps {
    id?: string;
}

type Props = {} & ReduxProps & RouteComponentProps<RouteProps> & WithLoctoolProps;

type State = {
    search: string;
};

class SearchPageComponent extends React.Component<Props, State> {
    private searchRef = React.createRef<HTMLInputElement>();

    public state = {
        search: this.props.assetOptions.control?.search ?? "",
    };

    componentDidMount() {
        this.searchRef.current?.focus();
        window.addEventListener("scroll", this.onScroll);
        if (this.state.search) {
            this.props.refreshSearchResults();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    private readonly onScroll = throttle(() => {
        const bottom =
            Math.abs(
                document.documentElement.scrollHeight -
                    document.documentElement.scrollTop -
                    document.documentElement.clientHeight
            ) < 1;
        if (bottom) {
            this.props.setAssetOffset((this.props.assetOptions.control?.offset ?? 0) + Constants.assetPageSize);
        }
    }, 200);

    private readonly search = debounce(this.props.searchInitialAssets, 500);

    private readonly handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { value: search } = event.currentTarget;
        this.setState({ search });
        if (search.length === 0) {
            this.props.clearSearch();
        }
        if (search.length >= 3) {
            this.search({ search });
        }
    };

    render() {
        return (
            <React.Fragment key="root">
                <AppBar
                    key="appBar"
                    initialPath=""
                    hideAppBarBottom={true}
                    selectedAssets={this.props.selectedAssets}
                    maxSelectableAsset={this.props.maxSelectableAsset}
                    refreshAssets={() => this.props.refreshSearchResults()}
                    selectAllAssets={this.props.selectAllAssets}
                    clearSelectedAssets={this.props.clearSelectedAssets}
                />

                <Main>
                    <Element $style={{ padding: 20 }}>
                        <Input
                            type="text"
                            label={this.props.Loctool.formatMessage({ id: "components.searchPage.search" })}
                            startIcon={<SvgIconSearch width={24} height={24} />}
                            sizeLarge
                            value={this.state.search}
                            onChange={this.handleSearchChange}
                            ref={this.searchRef}
                        />
                    </Element>

                    <Assets
                        assets={this.props.assets}
                        assetListDisplay={this.props.assetListDisplay}
                        selectedAssets={this.props.selectedAssets}
                        toggleSelectedAsset={this.props.toggleSelectedAsset}
                        onShiftSelectAsset={
                            this.props.maxSelectableAsset === 0
                                ? this.props.extendSelection
                                : this.props.toggleSelectedAsset
                        }
                        refreshAssets={() => this.props.refreshSearchResults()}
                    />

                    {this.props.search?.length > 0 && this.props.assets.length === 0 && !this.props.isLoading && (
                        <>
                            <Text variant="body" $style={{ textAlign: "center" }}>
                                <LoctoolMessage id="components.searchPage.noResults" />
                            </Text>
                            <Text variant="body" $style={{ textAlign: "center", fontWeight: 700 }}>
                                {this.props.search}
                            </Text>
                        </>
                    )}
                </Main>

                {this.props.selectedAssets.length > 0 && (
                    <ActionBar
                        selectedAssets={this.props.selectedAssets}
                        maxSelectableAsset={this.props.maxSelectableAsset}
                        selectAllAssets={this.props.selectAllAssets}
                        refreshAssets={() => this.props.refreshSearchResults()}
                        clearSelectedAssets={this.props.clearSelectedAssets}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    search: state.search.assetOptions.control?.search ?? "",
    assets: state.search.assets,
    assetOptions: state.search.assetOptions,
    assetListDisplay: state.search.assetListDisplay,
    selectedAssets: state.search.selectedAssets,
    maxSelectableAsset: state.search.maximumSelectableAsset ?? 0,
    isLoading: state.appState.showLoadingBar,
});

const mapDispatchToProps = {
    searchInitialAssets: SearchActions.searchInitialAssets,
    fetchMoreSearchResults: SearchActions.fetchMoreSearchResults,
    refreshSearchResults: SearchActions.refreshSearchResults,
    selectAllAssets: SearchActions.selectAllAssets,
    clearSelectedAssets: SearchActions.clearSelectedAssets,
    setAssetOffset: SearchActions.setAssetOffset,
    toggleSelectedAsset: SearchActions.toggleSelectedAsset,
    extendSelection: SearchActions.extendSelection,
    clearSearch: SearchActions.clearSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const SearchPage = withLoctool(withRouter(connector(SearchPageComponent)));
