import * as React from "react";
import { SVGProps } from "react";

const SvgIconCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M2.66675 8.20898L6.43798 11.9802L13.9805 4.43775"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

export default SvgIconCheck;
