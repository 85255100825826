/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login_account_projects {
  __typename: "Project";
  id: string;
  title: string;
  namespace: string;
}

export interface login_login_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  firstName: string;
  lastName: string;
  email: any;
  projects: login_login_account_projects[];
}

export interface login_login {
  __typename: "AuthResponse";
  /**
   * authenticated account data
   * 
   * - @see type Account
   */
  account: login_login_account;
  /**
   * JWT auth token (short life)
   */
  authToken: string;
  /**
   * JWT refresh token (long life)
   */
  refreshToken: string;
}

export interface login {
  login: login_login | null;
}

export interface loginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout {
  logout: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refreshAccessToken
// ====================================================

export interface refreshAccessToken {
  refreshAccessToken: string;
}

export interface refreshAccessTokenVariables {
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadAsset
// ====================================================

export interface uploadAsset_uploadAsset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface uploadAsset_uploadAsset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface uploadAsset_uploadAsset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: uploadAsset_uploadAsset_info;
  directory: uploadAsset_uploadAsset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface uploadAsset {
  uploadAsset: uploadAsset_uploadAsset | null;
}

export interface uploadAssetVariables {
  title: string;
  file: any;
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAsset
// ====================================================

export interface updateAsset_updateAsset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface updateAsset_updateAsset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface updateAsset_updateAsset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: updateAsset_updateAsset_info;
  directory: updateAsset_updateAsset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAsset {
  updateAsset: updateAsset_updateAsset;
}

export interface updateAssetVariables {
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAsset
// ====================================================

export interface deleteAsset {
  deleteAsset: any | null;
}

export interface deleteAssetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssets
// ====================================================

export interface deleteAssets {
  deleteAssets: any | null;
}

export interface deleteAssetsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetDirectory
// ====================================================

export interface createAssetDirectory_createAssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface createAssetDirectory_createAssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: createAssetDirectory_createAssetDirectory_parent_children[] | null;
}

export interface createAssetDirectory_createAssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface createAssetDirectory_createAssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: createAssetDirectory_createAssetDirectory_children_children[] | null;
}

export interface createAssetDirectory_createAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: createAssetDirectory_createAssetDirectory_parent | null;
  childrenCount: number;
  children: createAssetDirectory_createAssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAssetDirectory {
  createAssetDirectory: createAssetDirectory_createAssetDirectory | null;
}

export interface createAssetDirectoryVariables {
  input: CreateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssetDirectory
// ====================================================

export interface updateAssetDirectory_updateAssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface updateAssetDirectory_updateAssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: updateAssetDirectory_updateAssetDirectory_parent_children[] | null;
}

export interface updateAssetDirectory_updateAssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface updateAssetDirectory_updateAssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: updateAssetDirectory_updateAssetDirectory_children_children[] | null;
}

export interface updateAssetDirectory_updateAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: updateAssetDirectory_updateAssetDirectory_parent | null;
  childrenCount: number;
  children: updateAssetDirectory_updateAssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAssetDirectory {
  updateAssetDirectory: updateAssetDirectory_updateAssetDirectory | null;
}

export interface updateAssetDirectoryVariables {
  id?: string | null;
  input: UpdateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAssetDirectory
// ====================================================

export interface deleteAssetDirectory {
  deleteAssetDirectory: any | null;
}

export interface deleteAssetDirectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveAssetsToAssetDirectory
// ====================================================

export interface moveAssetsToAssetDirectory {
  moveAssetsToAssetDirectory: any | null;
}

export interface moveAssetsToAssetDirectoryVariables {
  assetIds: string[];
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: initiateAccountRecovery
// ====================================================

export interface initiateAccountRecovery {
  initiateAccountRecovery: any | null;
}

export interface initiateAccountRecoveryVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: finishAccountRecovery
// ====================================================

export interface finishAccountRecovery {
  finishAccountRecovery: any | null;
}

export interface finishAccountRecoveryVariables {
  email: string;
  token: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAssetExport
// ====================================================

export interface createAssetExport_createAssetExport_assets {
  __typename: "Asset";
  id: string;
  title: string;
}

export interface createAssetExport_createAssetExport {
  __typename: "AssetExportJob";
  id: string;
  assets: createAssetExport_createAssetExport_assets[];
  status: AssetExportJobStatus;
  totalAssets: number;
  progressPercent: number;
  archiveURL: string | null;
}

export interface createAssetExport {
  createAssetExport: createAssetExport_createAssetExport;
}

export interface createAssetExportVariables {
  assetIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: markAssetExportDownloadedById
// ====================================================

export interface markAssetExportDownloadedById {
  markAssetExportDownloadedById: any | null;
}

export interface markAssetExportDownloadedByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  changePassword: any | null;
}

export interface changePasswordVariables {
  currentPassword: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: loginWithSignature
// ====================================================

export interface loginWithSignature_loginWithSignature_account_projects {
  __typename: "Project";
  id: string;
  title: string;
  namespace: string;
}

export interface loginWithSignature_loginWithSignature_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  firstName: string;
  lastName: string;
  email: any;
  projects: loginWithSignature_loginWithSignature_account_projects[];
}

export interface loginWithSignature_loginWithSignature {
  __typename: "AuthResponse";
  /**
   * authenticated account data
   * 
   * - @see type Account
   */
  account: loginWithSignature_loginWithSignature_account;
  /**
   * JWT auth token (short life)
   */
  authToken: string;
  /**
   * JWT refresh token (long life)
   */
  refreshToken: string;
}

export interface loginWithSignature {
  loginWithSignature: loginWithSignature_loginWithSignature | null;
}

export interface loginWithSignatureVariables {
  email?: string | null;
  projectId: string;
  apiKey: string;
  signature: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: account
// ====================================================

export interface account_me_projects {
  __typename: "Project";
  id: string;
  title: string;
  namespace: string;
}

export interface account_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  firstName: string;
  lastName: string;
  email: any;
  projects: account_me_projects[];
}

export interface account_getActiveAssetExportJobs_assets {
  __typename: "Asset";
  id: string;
  title: string;
}

export interface account_getActiveAssetExportJobs {
  __typename: "AssetExportJob";
  id: string;
  assets: account_getActiveAssetExportJobs_assets[];
  status: AssetExportJobStatus;
  totalAssets: number;
  progressPercent: number;
  archiveURL: string | null;
}

export interface account {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: account_me;
  getActiveAssetExportJobs: account_getActiveAssetExportJobs[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetDirectoriesByParentId
// ====================================================

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent_children[] | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children_children[] | null;
}

export interface getAssetDirectoriesByParentId_getAssetDirectoriesByParentId {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_parent | null;
  childrenCount: number;
  children: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoriesByParentId {
  getAssetDirectoriesByParentId: getAssetDirectoriesByParentId_getAssetDirectoriesByParentId[];
}

export interface getAssetDirectoriesByParentIdVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetDirectoryById
// ====================================================

export interface getAssetDirectoryById_getAssetDirectoryById_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getAssetDirectoryById_getAssetDirectoryById_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getAssetDirectoryById_getAssetDirectoryById_parent_children[] | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getAssetDirectoryById_getAssetDirectoryById_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getAssetDirectoryById_getAssetDirectoryById_children_children[] | null;
}

export interface getAssetDirectoryById_getAssetDirectoryById {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getAssetDirectoryById_getAssetDirectoryById_parent | null;
  childrenCount: number;
  children: getAssetDirectoryById_getAssetDirectoryById_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetDirectoryById {
  getAssetDirectoryById: getAssetDirectoryById_getAssetDirectoryById | null;
}

export interface getAssetDirectoryByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: search
// ====================================================

export interface search_search_result_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface search_search_result_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface search_search_result {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: search_search_result_info;
  directory: search_search_result_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface search_search {
  __typename: "SearchList";
  count: number;
  result: search_search_result[];
}

export interface search {
  search: search_search;
}

export interface searchVariables {
  options?: SearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetByFileName
// ====================================================

export interface getAssetByFileName_getAssetByFileName_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface getAssetByFileName_getAssetByFileName_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface getAssetByFileName_getAssetByFileName {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: getAssetByFileName_getAssetByFileName_info;
  directory: getAssetByFileName_getAssetByFileName_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAssetByFileName {
  getAssetByFileName: getAssetByFileName_getAssetByFileName | null;
}

export interface getAssetByFileNameVariables {
  fileName: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkAccountRecoveryTokenValidity
// ====================================================

export interface checkAccountRecoveryTokenValidity {
  checkAccountRecoveryTokenValidity: boolean;
}

export interface checkAccountRecoveryTokenValidityVariables {
  email: string;
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getHomeFolderContents
// ====================================================

export interface getHomeFolderContents_getAssetDirectoriesByParentId_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getHomeFolderContents_getAssetDirectoriesByParentId_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getHomeFolderContents_getAssetDirectoriesByParentId_parent_children[] | null;
}

export interface getHomeFolderContents_getAssetDirectoriesByParentId_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getHomeFolderContents_getAssetDirectoriesByParentId_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getHomeFolderContents_getAssetDirectoriesByParentId_children_children[] | null;
}

export interface getHomeFolderContents_getAssetDirectoriesByParentId {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getHomeFolderContents_getAssetDirectoriesByParentId_parent | null;
  childrenCount: number;
  children: getHomeFolderContents_getAssetDirectoriesByParentId_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getHomeFolderContents_search_result_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface getHomeFolderContents_search_result_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface getHomeFolderContents_search_result {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: getHomeFolderContents_search_result_info;
  directory: getHomeFolderContents_search_result_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getHomeFolderContents_search {
  __typename: "SearchList";
  count: number;
  result: getHomeFolderContents_search_result[];
}

export interface getHomeFolderContents {
  getAssetDirectoriesByParentId: getHomeFolderContents_getAssetDirectoriesByParentId[];
  search: getHomeFolderContents_search;
}

export interface getHomeFolderContentsVariables {
  options?: SearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFolderContents
// ====================================================

export interface getFolderContents_getAssetDirectoryById_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getFolderContents_getAssetDirectoryById_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getFolderContents_getAssetDirectoryById_parent_children[] | null;
}

export interface getFolderContents_getAssetDirectoryById_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface getFolderContents_getAssetDirectoryById_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: getFolderContents_getAssetDirectoryById_children_children[] | null;
}

export interface getFolderContents_getAssetDirectoryById {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: getFolderContents_getAssetDirectoryById_parent | null;
  childrenCount: number;
  children: getFolderContents_getAssetDirectoryById_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

export interface getFolderContents_search_result_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface getFolderContents_search_result_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface getFolderContents_search_result {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: getFolderContents_search_result_info;
  directory: getFolderContents_search_result_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getFolderContents_search {
  __typename: "SearchList";
  count: number;
  result: getFolderContents_search_result[];
}

export interface getFolderContents {
  getAssetDirectoryById: getFolderContents_getAssetDirectoryById | null;
  search: getFolderContents_search;
}

export interface getFolderContentsVariables {
  id: string;
  options?: SearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getActiveAssetExportJobs
// ====================================================

export interface getActiveAssetExportJobs_getActiveAssetExportJobs_assets {
  __typename: "Asset";
  id: string;
  title: string;
}

export interface getActiveAssetExportJobs_getActiveAssetExportJobs {
  __typename: "AssetExportJob";
  id: string;
  assets: getActiveAssetExportJobs_getActiveAssetExportJobs_assets[];
  status: AssetExportJobStatus;
  totalAssets: number;
  progressPercent: number;
  archiveURL: string | null;
}

export interface getActiveAssetExportJobs {
  getActiveAssetExportJobs: getActiveAssetExportJobs_getActiveAssetExportJobs[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetExportJobById
// ====================================================

export interface getAssetExportJobById_getAssetExportJobById_assets {
  __typename: "Asset";
  id: string;
  title: string;
}

export interface getAssetExportJobById_getAssetExportJobById {
  __typename: "AssetExportJob";
  id: string;
  assets: getAssetExportJobById_getAssetExportJobById_assets[];
  status: AssetExportJobStatus;
  totalAssets: number;
  progressPercent: number;
  archiveURL: string | null;
}

export interface getAssetExportJobById {
  getAssetExportJobById: getAssetExportJobById_getAssetExportJobById | null;
}

export interface getAssetExportJobByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBreadcrumDataForAssetDirectoryById
// ====================================================

export interface getBreadcrumDataForAssetDirectoryById_getBreadcrumDataForAssetDirectoryById {
  __typename: "AssetDirectoryBreadcrumbData";
  id: string;
  name: string;
  deletable: boolean;
}

export interface getBreadcrumDataForAssetDirectoryById {
  getBreadcrumDataForAssetDirectoryById: getBreadcrumDataForAssetDirectoryById_getBreadcrumDataForAssetDirectoryById[];
}

export interface getBreadcrumDataForAssetDirectoryByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Project
// ====================================================

export interface Project {
  __typename: "Project";
  id: string;
  title: string;
  namespace: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Account
// ====================================================

export interface Account_projects {
  __typename: "Project";
  id: string;
  title: string;
  namespace: string;
}

export interface Account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  firstName: string;
  lastName: string;
  email: any;
  projects: Account_projects[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Asset
// ====================================================

export interface Asset_info {
  __typename: "AssetInfo";
  originalFileName: string;
  mimeType: string;
  encoding: string;
  etag: string;
  width: number | null;
  height: number | null;
}

export interface Asset_directory {
  __typename: "AssetDirectory";
  id: string;
}

export interface Asset {
  __typename: "Asset";
  id: string;
  title: string;
  fileName: string;
  fileSize: number;
  url: any;
  assetType: AssetType;
  info: Asset_info;
  directory: Asset_directory | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectoryItem
// ====================================================

export interface AssetDirectoryItem_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface AssetDirectoryItem {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: AssetDirectoryItem_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectory
// ====================================================

export interface AssetDirectory_parent_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface AssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: AssetDirectory_parent_children[] | null;
}

export interface AssetDirectory_children_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
}

export interface AssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
  childrenCount: number;
  children: AssetDirectory_children_children[] | null;
}

export interface AssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  parent: AssetDirectory_parent | null;
  childrenCount: number;
  children: AssetDirectory_children[] | null;
  deletable: boolean;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetExportJob
// ====================================================

export interface AssetExportJob_assets {
  __typename: "Asset";
  id: string;
  title: string;
}

export interface AssetExportJob {
  __typename: "AssetExportJob";
  id: string;
  assets: AssetExportJob_assets[];
  status: AssetExportJobStatus;
  totalAssets: number;
  progressPercent: number;
  archiveURL: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetExportJobStatus {
  created = "created",
  downloaded = "downloaded",
  error = "error",
  in_progress = "in_progress",
  waiting_for_download = "waiting_for_download",
}

export enum AssetType {
  file = "file",
  image = "image",
  video = "video",
}

export enum SearchListSortField {
  createdAt = "createdAt",
  fileSize = "fileSize",
  title = "title",
  updatedAt = "updatedAt",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface CreateAssetDirectoryInput {
  name: string;
  parentId?: string | null;
}

export interface ListControl {
  sortOrder?: SortOrder | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export interface SearchInput {
  assetDirectory?: string | null;
  recursive?: boolean | null;
  listOptions?: SearchListOptionsInput | null;
}

export interface SearchListOptionsInput {
  sortField?: SearchListSortField | null;
  control?: ListControl | null;
}

export interface UpdateAssetDirectoryInput {
  name?: string | null;
  parentId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
