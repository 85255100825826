import { Element } from "@components/Element";
import SvgIconLoading from "@components/svg/IconLoading";
import { keyframesRotate360 } from "@theme/GlobalStyle";
import { Transition } from "@theme/Theme";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";
import React from "react";
import { Link } from "react-router-dom";
import styled, { CSSObject } from "styled-components";

interface ButtonComponentProps {
    label?: string;
    title?: string;
    href?: string;
    rel?: string;
    download?: boolean | string;
    type?: "button" | "submit";
    disabled?: boolean;
    loading?: boolean;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    fullWidth?: boolean;
    $style?: CSSObject;
    className?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

const ButtonComponent = React.forwardRef(
    (props: ButtonComponentProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) => {
        if (props.href) {
            return (
                <StyledButton
                    as={Link}
                    to={props.href}
                    rel={props.rel}
                    aria-label={props.label}
                    title={props.title}
                    className={props.className}
                    download={props.download}
                    $fullWidth={props.fullWidth}
                    $loading={props.loading}
                    $style={props.$style}
                    onClick={props.onClick}
                    ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                >
                    {props.loading && <LoadingIcon width={24} height={24} />}

                    {props.startIcon && !props.loading && (
                        <Element as="span" $style={{ marginRight: 10 }}>
                            {props.startIcon}
                        </Element>
                    )}

                    {props.label ?? props.children}

                    {props.endIcon && !props.loading && (
                        <Element as="span" $style={{ marginLeft: 10 }}>
                            {props.endIcon}
                        </Element>
                    )}
                </StyledButton>
            );
        }

        return (
            <StyledButton
                as="button"
                type={props.type || "button"}
                title={props.title}
                className={props.className}
                disabled={props.disabled || props.loading}
                $fullWidth={props.fullWidth}
                $loading={props.loading}
                $style={props.$style}
                onClick={props.onClick}
                ref={ref as React.ForwardedRef<HTMLButtonElement>}
            >
                {props.loading && <LoadingIcon width={24} height={24} />}

                {props.startIcon && !props.loading && (
                    <Element as="span" $style={{ marginRight: 10 }}>
                        {props.startIcon}
                    </Element>
                )}

                {props.label}

                {props.endIcon && !props.loading && (
                    <Element as="span" $style={{ marginLeft: 10 }}>
                        {props.endIcon}
                    </Element>
                )}
            </StyledButton>
        );
    }
);

interface IconButtonComponentProps {
    label: string;
    type?: "button" | "submit";
    disabled?: boolean;
    loading?: boolean;
    icon: JSX.Element;
    $style?: CSSObject;
    className?: string;
    onClick?: () => void;
}

const IconButtonComponent = (props: IconButtonComponentProps) => {
    return (
        <StyledButton
            as="button"
            aria-label={props.label}
            title={props.label}
            className={props.className}
            disabled={props.disabled || props.loading}
            type={props.type || "button"}
            $style={props.$style}
            onClick={props.onClick}
        >
            {props.loading ? <LoadingIcon width={24} height={24} /> : props.icon}
        </StyledButton>
    );
};

export const StyledButton = styled(Element)<{ $style?: CSSObject; $fullWidth?: boolean; $loading?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: ${props => props.theme.button.color};
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-decoration: none;
    user-select: none;
    transition: color ${Transition.common}, background-color ${Transition.common}, border-color ${Transition.common};
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    ${props => props.$fullWidth && "width: 100%;"}
    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const IconButton = styled(IconButtonComponent)<{
    $style?: CSSObject;
    loading?: boolean;
    inverse?: boolean;
}>`
    color: ${props => props.theme.button.text.primary.color};

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 32px;
        width: calc(100% + 10px);
        height: 32px;
        border-radius: 12px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.text.primary.hover.color};
        }

        &:focus-visible {
            &::before {
                outline: 8px solid ${props => props.theme.button.text.primary.focus.outlineColor};
            }
        }

        &:active {
            color: ${props => props.theme.button.text.primary.active.color};
        }
    }

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.text.primary.loading.color};
        opacity: 1 !important;
    `}

    ${props =>
        props.inverse &&
        `
        color: ${props.theme.button.text.secondary.color};

        &:not([disabled]) {
            &:hover {
                color: ${props.theme.button.text.secondary.hover.color};
            }

            &:focus-visible {
                &::before {
                    outline: 8px solid ${props.theme.button.text.secondary.focus.outlineColor};
                }
            }

            &:active {
                color: ${props.theme.button.text.secondary.active.color};
            }
        }

        ${
            props.loading
                ? `
            color: ${props.theme.button.text.secondary.loading.color};
        `
                : ""
        }
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const TextButton = styled(ButtonComponent)<{
    $style?: CSSObject;
    loading?: boolean;
    adjust?: boolean;
    inverse?: boolean;
}>`
    color: ${props => props.theme.button.text.primary.color};
    border-radius: 12px;
    font-weight: 500;
    white-space: nowrap;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.text.primary.hover.color};
        }

        &:active {
            color: ${props => props.theme.button.text.primary.active.color};
        }
    }

    ${props =>
        props.adjust
            ? `
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 32px;
            width: calc(100% + 32px);
            height: 32px;
            border-radius: 12px;
        }

        &:not([disabled]) {
            &:focus-visible {
                &::before {
                    outline: 8px solid ${props.theme.button.text.primary.focus.outlineColor};
                }
            }
        }
    `
            : `
        height: 46px;
        padding: 0 20px;

        &:not([disabled]) {
            &:focus-visible {
                outline: 8px solid ${props.theme.button.text.primary.focus.outlineColor};
            }
        }
    `}

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.text.primary.loading.color};
        opacity: 1 !important;
    `}

    ${props =>
        props.inverse &&
        `
        color: ${props.theme.button.text.secondary.color};

        &:not([disabled]) {
            &:hover {
                color: ${props.theme.button.text.secondary.hover.color};
            }

            &:focus-visible {
                &::before {
                    outline: 8px solid ${props.theme.button.text.secondary.focus.outlineColor};
                }
            }

            &:active {
                color: ${props.theme.button.text.secondary.active.color};
            }
        }

        ${
            props.adjust
                ? `
            &:not([disabled]) {
                &:focus-visible {
                    &::before {
                        outline: 8px solid ${props.theme.button.text.secondary.focus.outlineColor};
                    }
                }
            }
        `
                : `
            &:not([disabled]) {
                &:focus-visible {
                    outline: 8px solid ${props.theme.button.text.secondary.focus.outlineColor};
                }
            }
        `
        }

        ${
            props.loading
                ? `
            color: ${props.theme.button.text.secondary.loading.color};
        `
                : ""
        }
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const PrimaryButton = styled(ButtonComponent)<{ $style?: CSSObject; loading?: boolean }>`
    height: 56px;
    padding: 0 20px;
    color: ${props => props.theme.button.primary.color};
    background: ${props => props.theme.button.primary.background};
    border: 2px solid ${props => props.theme.button.primary.borderColor};
    border-radius: 12px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;

    &::before {
        display: none;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.primary.hover.color};
            background: ${props => props.theme.button.primary.hover.background};
            border-color: ${props => props.theme.button.primary.hover.borderColor};
        }

        &:focus-visible {
            outline: 8px solid ${props => props.theme.button.primary.focus.outlineColor};
        }

        &:active {
            color: ${props => props.theme.button.primary.active.color};
            background: ${props => props.theme.button.primary.active.background};
            border-color: ${props => props.theme.button.primary.active.borderColor};
        }
    }

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.primary.loading.color};
        background: ${props.theme.button.primary.loading.background};
        border-color: ${props.theme.button.primary.loading.borderColor};
        opacity: 1 !important;
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const SecondaryButton = styled(PrimaryButton)<{ $style?: CSSObject; loading?: boolean }>`
    color: ${props => props.theme.button.secondary.color};
    background: ${props => props.theme.button.secondary.background};
    border-color: ${props => props.theme.button.secondary.borderColor};

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.secondary.hover.color};
            background: ${props => props.theme.button.secondary.background};
            border-color: ${props => props.theme.button.secondary.hover.borderColor};
        }

        &:focus-visible {
            outline: 8px solid ${props => props.theme.button.secondary.focus.outlineColor};
        }

        &:active {
            color: ${props => props.theme.button.secondary.active.color};
            background: ${props => props.theme.button.secondary.background};
            border-color: ${props => props.theme.button.secondary.active.borderColor};
        }
    }

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.secondary.loading.color};
        background-color: ${props.theme.button.secondary.loading.background};
        border-color: ${props.theme.button.secondary.loading.borderColor};
        opacity: 1 !important;
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const ActionButton = styled(ButtonComponent)<{ $style?: CSSObject; loading?: boolean }>`
    padding: 12px 22px;
    margin-top: 10px;
    margin-left: 10px;
    color: ${props => props.theme.button.action.color};
    background: ${props => props.theme.button.action.background};
    border: 2px solid ${props => props.theme.button.action.borderColor};
    border-radius: 8px;

    @media screen and (max-width: 1299.98px) {
        padding: 8px 18px;
        font-size: 10px;
        line-height: 18px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.action.hover.color};
            background: ${props => props.theme.button.action.hover.background};
            border-color: ${props => props.theme.button.action.hover.borderColor};
        }

        &:focus-visible {
            outline: 8px solid ${props => props.theme.button.action.focus.outlineColor};
        }

        &:active {
            color: ${props => props.theme.button.action.active.color};
            background: ${props => props.theme.button.action.active.background};
            border-color: ${props => props.theme.button.action.active.borderColor};
        }
    }

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.action.loading.color};
        background-color: ${props.theme.button.action.loading.background};
        border-color: ${props.theme.button.action.loading.borderColor};
        opacity: 1 !important;
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const PrimaryActionButton = styled(ButtonComponent)<{ $style?: CSSObject; loading?: boolean }>`
    padding: 12px 22px;
    margin-left: 10px;
    color: ${props => props.theme.button.primary.color};
    background: ${props => props.theme.button.primary.background};
    border: 2px solid ${props => props.theme.button.primary.borderColor};
    border-radius: 8px;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([disabled]) {
        &:hover {
            color: ${props => props.theme.button.primary.hover.color};
            background: ${props => props.theme.button.primary.hover.background};
            border-color: ${props => props.theme.button.primary.hover.borderColor};
        }

        &:focus-visible {
            outline: 8px solid ${props => props.theme.button.primary.focus.outlineColor};
        }

        &:active {
            color: ${props => props.theme.button.primary.active.color};
            background: ${props => props.theme.button.primary.active.background};
            border-color: ${props => props.theme.button.primary.active.borderColor};
        }
    }

    ${props =>
        props.loading &&
        `
        color: ${props.theme.button.primary.loading.color};
        background: ${props.theme.button.primary.loading.background};
        border-color: ${props.theme.button.primary.loading.borderColor};
        opacity: 1 !important;
    `}

    ${props => props.$style && SortObjKeysAlphabetically(props.$style)};
`;

const MenuButton = styled(TextButton)`
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 0;
`;

const LoadingIcon = styled(SvgIconLoading)`
    margin-right: 10px;
    animation: ${keyframesRotate360} 1s linear infinite;
`;

export const Button = {
    Action: ActionButton,
    PrimaryAction: PrimaryActionButton,
    Primary: PrimaryButton,
    Secondary: SecondaryButton,
    Icon: IconButton,
    Text: TextButton,
    Menu: MenuButton,
};
