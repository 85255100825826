import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAssetFolder } from "@api/ApiTypes";
import { Asset } from "@api/graphql/types";

export interface ModalState {
    fileUploadModal: {
        show: boolean;
    };
    showModal: {
        show: boolean;
        asset?: Asset;
    };
    renameModal: {
        show: boolean;
        asset?: Asset;
    };
    deleteModal: {
        show: boolean;
        assets?: Asset[];
    };
    moveModal: {
        show: boolean;
        assets?: Asset[];
    };
    createFolderModal: {
        show: boolean;
    };
    renameFolderModal: {
        show: boolean;
        folder?: AnyAssetFolder;
    };
    moveFolderModal: {
        show: boolean;
        folder?: AnyAssetFolder;
    };
    deleteFolderModal: {
        show: boolean;
        folder?: AnyAssetFolder;
    };
    changePasswordModal: {
        show: boolean;
    };
}

const initialState: ModalState = {
    fileUploadModal: {
        show: false,
    },
    showModal: {
        show: false,
    },
    renameModal: {
        show: false,
    },
    deleteModal: {
        show: false,
    },
    moveModal: {
        show: false,
    },
    createFolderModal: {
        show: false,
    },
    renameFolderModal: {
        show: false,
    },
    moveFolderModal: {
        show: false,
    },
    deleteFolderModal: {
        show: false,
    },
    changePasswordModal: {
        show: false,
    },
};

const modalSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        toggleFileUploadModal(state) {
            state.fileUploadModal.show = !state.fileUploadModal.show;
        },
        toggleShowModal(state, { payload: asset }: PayloadAction<Asset | undefined>) {
            state.showModal.show = !state.showModal.show;
            state.showModal.asset = asset;
        },
        toggleRenameModal(state, { payload: asset }: PayloadAction<Asset | undefined>) {
            state.renameModal.show = !state.renameModal.show;
            state.renameModal.asset = asset;
        },
        toggleDeleteModal(state, { payload: assets }: PayloadAction<Asset[] | undefined>) {
            state.deleteModal.show = !state.deleteModal.show;
            state.deleteModal.assets = assets;
        },
        toggleMoveModal(state, { payload: assets }: PayloadAction<Asset[] | undefined>) {
            state.moveModal.show = !state.moveModal.show;
            state.moveModal.assets = assets;
        },
        toggleCreateFolderModal(state) {
            state.createFolderModal.show = !state.createFolderModal.show;
        },
        toggleRenameFolderModal(state, { payload: folder }: PayloadAction<AnyAssetFolder | undefined>) {
            state.renameFolderModal.show = !state.renameFolderModal.show;
            state.renameFolderModal.folder = folder;
        },
        toggleMoveFolderModal(state, { payload: folder }: PayloadAction<AnyAssetFolder | undefined>) {
            state.moveFolderModal.show = !state.moveFolderModal.show;
            state.moveFolderModal.folder = folder;
        },
        toggleDeleteFolderModal(state, { payload: folder }: PayloadAction<AnyAssetFolder | undefined>) {
            state.deleteFolderModal.show = !state.deleteFolderModal.show;
            state.deleteFolderModal.folder = folder;
        },
        toggleChangePasswordModal(state) {
            state.changePasswordModal.show = !state.changePasswordModal.show;
        },
    },
});

export const ModalActions = modalSlice.actions;

export const ModalReducer = modalSlice.reducer;
