import * as React from "react";
import { SVGProps } from "react";

const SvgIconUpload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={18}
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M1.66665 9.00033C1.33331 8.00033 1.33331 7.00033 1.33331 6.30457C1.33331 3.98578 3.40963 1.66699 5.97089 1.66699C7.68745 1.66699 9.18618 2.59961 9.98804 3.98578H10.6085C12.5294 3.98578 14.3333 5.33366 14.3333 7.00033C14.3333 7.66699 14.3333 8.33366 14 9.00033"
            stroke="currentColor"
            strokeWidth={1.7}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3885 10.7783L7.66633 8.16613L4.94411 10.7783"
            stroke="currentColor"
            strokeWidth={1.7}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M7.66632 8.85352L7.66632 16.1868" stroke="currentColor" strokeWidth={1.7} strokeLinecap="round" />
    </svg>
);

export default SvgIconUpload;
