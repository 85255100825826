import * as React from "react";
import { SVGProps } from "react";

const SvgFileTxt = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={65}
        height={85}
        viewBox="0 0 65 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect width={65} height={85} rx={10} fill="#063EF9" />
        <path
            d="M25.3643 58.415V62.435C25.3643 62.715 25.4293 62.92 25.5593 63.05C25.6993 63.17 25.9293 63.23 26.2493 63.23H27.2243V65H25.9043C24.1343 65 23.2493 64.14 23.2493 62.42V58.415H22.2593V56.69H23.2493V54.635H25.3643V56.69H27.2243V58.415H25.3643ZM34.0747 65L32.3647 62.42L30.8497 65H28.5997L31.3147 60.83L28.5697 56.69H30.9397L32.6347 59.255L34.1647 56.69H36.4147L33.6847 60.83L36.4447 65H34.0747ZM40.7803 58.415V62.435C40.7803 62.715 40.8453 62.92 40.9753 63.05C41.1153 63.17 41.3453 63.23 41.6653 63.23H42.6403V65H41.3203C39.5503 65 38.6653 64.14 38.6653 62.42V58.415H37.6753V56.69H38.6653V54.635H40.7803V56.69H42.6403V58.415H40.7803Z"
            fill="white"
        />
        <path d="M14 14H37M51 14H43" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M14 32H30M51 32H36" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M14 23H51" stroke="white" strokeWidth={2} strokeLinecap="round" />
        <path d="M14 41H51" stroke="white" strokeWidth={2} strokeLinecap="round" />
    </svg>
);

export default SvgFileTxt;
