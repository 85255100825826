import { useLoctool } from "@bigfish/react-loctool";
import React from "react";
import styled from "styled-components";
import { Flex } from "./Flex";
import SvgIconUpload from "./svg/IconUpload";
import { Text } from "./Text";

export const EmptyFolderInfo = ({ onClick }: { onClick: () => void }) => {
    const Loctool = useLoctool();

    return (
        <EmptyFolderInfoContainer>
            <Text
                as="p"
                variant="displayXSmall"
                $style={{
                    maxWidth: 330,
                    marginTop: 16,
                    color: "inherit",
                    textAlign: "center",
                    marginBottom: 30,
                }}
            >
                {Loctool.formatMessage({ id: "components.emptyfolder.title" })}
            </Text>
            <EmptyFolderContainer onClick={onClick}>
                <SvgIconUpload width={38} height={38} />
                <Text
                    as="p"
                    variant="textXSmall"
                    $style={{
                        maxWidth: 330,
                        marginTop: 16,
                        color: "inherit",
                        textAlign: "center",
                    }}
                >
                    {Loctool.formatMessage({ id: "components.dropzone.placeholder" })}
                </Text>
            </EmptyFolderContainer>
        </EmptyFolderInfoContainer>
    );
};

const EmptyFolderInfoContainer = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $alignItems: "center",
})`
    flex: 1;
    min-width: 420px;
    width: 50%;
    margin: 0 auto;
    justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmptyFolderContainer: any = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $alignItems: "center",
})`
    width: 100%;
    padding: 20px;
    color: ${props => props.theme.dropzone.color};
    background: ${props => props.theme.dropzone.background};
    border: 1px dashed ${props => props.theme.dropzone.borderColor};
    border-radius: 16px;

    &:hover {
        cursor: pointer;
    }
`;
