import * as React from "react";
import { SVGProps } from "react";

const SvgIconFolder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={14}
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M1.58325 5.33301C1.58325 3.67616 2.9264 2.33301 4.58325 2.33301H5.43181C6.14795 2.33301 6.84046 2.58918 7.38419 3.05524L7.74065 3.36078C8.28438 3.82683 8.97689 4.08301 9.69302 4.08301H11.4166C13.0734 4.08301 14.4166 5.42615 14.4166 7.08301V9.24968C14.4166 10.9065 13.0734 12.2497 11.4166 12.2497H4.58325C2.9264 12.2497 1.58325 10.9065 1.58325 9.24967V5.33301Z"
            stroke="currentColor"
            strokeWidth={1.5}
        />
    </svg>
);

export default SvgIconFolder;
