import {
    ControlledMenu as ControlledMenuInner,
    Menu as MenuInner,
    MenuDivider,
    MenuItem,
    SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { menuDividerSelector, menuItemSelector, menuSelector } from "@szhsin/react-menu/style-utils";
import styled, { keyframes } from "styled-components";

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

const Menu = styled(MenuInner)<{ sizeLarge?: boolean }>`
    ${menuSelector.name} {
        min-width: 160px;
        padding: ${props => (props.sizeLarge ? "30px" : "4px 16px")};
        background: ${props => props.theme.menu.background};
        border: 0;
        border-radius: 16px;
        box-shadow: ${props => props.theme.menu.boxShadow};
    }

    ${menuSelector.stateOpening} {
        animation: ${menuShow} 200ms;
    }

    ${menuSelector.stateClosing} {
        animation: ${menuHide} 200ms forwards;
    }

    ${menuItemSelector.name} {
        padding: 0;
        margin: 12px 0;
        border-radius: 0;
    }

    ${menuItemSelector.hover} {
        background-color: transparent;
    }

    ${menuDividerSelector.name} {
        margin: 12px 0;
        background: ${props => props.theme.menu.divider.color};
    }

    ${menuItemSelector.submenu} {
        position: relative;
    }

    .szh-menu__arrow {
        border: 0;
    }
`;

const ControlledMenu = styled(ControlledMenuInner)<{ sizeLarge?: boolean }>`
    ${menuSelector.name} {
        min-width: 160px;
        padding: ${props => (props.sizeLarge ? "30px" : "4px 16px")};
        background: ${props => props.theme.menu.background};
        border: 0;
        border-radius: 16px;
        box-shadow: ${props => props.theme.menu.boxShadow};
    }

    ${menuSelector.stateOpening} {
        animation: ${menuShow} 200ms;
    }

    ${menuSelector.stateClosing} {
        animation: ${menuHide} 200ms forwards;
    }

    ${menuItemSelector.name} {
        padding: 0;
        margin: 12px 0;
        border-radius: 0;
    }

    ${menuItemSelector.hover} {
        background-color: transparent;
    }

    ${menuDividerSelector.name} {
        margin: 12px 0;
        background: ${props => props.theme.menu.divider.color};
    }

    ${menuItemSelector.submenu} {
        position: relative;
    }

    .szh-menu__arrow {
        border: 0;
    }
`;

export { ControlledMenu, Menu, MenuDivider, MenuItem, SubMenu };
