import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetExportJob, AssetExportJobStatus } from "@api/graphql/types";
import { AppThunk } from "./store";
import { Api } from "@api/Api";
import { AlertActions } from "./alertSlice";

export type AssetExportState = {
    isPolling: boolean;
    jobs: AssetExportJob[];
};

const initialState: AssetExportState = {
    isPolling: false,
    jobs: [],
};

const assetExportSlice = createSlice({
    name: "assetExport",
    initialState,
    reducers: {
        setIsPolling(state, { payload: isPolling }: PayloadAction<boolean>) {
            state.isPolling = isPolling;
        },
        setJobs(state, { payload: jobs }: PayloadAction<AssetExportJob[]>) {
            state.jobs = jobs;
        },
    },
});

const createJob = (assetIds: string[]): AppThunk => async (dispatch, getState) => {
    const { jobs, isPolling } = getState().assetExport;

    try {
        const job = await Api.createAssetExport(assetIds);
        dispatch(AssetExportActions.setJobs([...jobs, job]));
        if (!isPolling) {
            dispatch(AssetExportActions.startPolling());
        }
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    }
};

const removeJob = (job: AssetExportJob): AppThunk => async (dispatch, getState) => {
    const { jobs, isPolling } = getState().assetExport;
    const isWaitingForDownload = job.status === AssetExportJobStatus.waiting_for_download;

    if (isWaitingForDownload) {
        try {
            await Api.markAssetExportDownloadedById(job.id);
        } catch (error) {
            dispatch(AlertActions.parseError(error as Error));
        }
        if (!isPolling) {
            dispatch(AssetExportActions.setJobs(jobs.filter(j => j.id !== job.id)));
        }
    } else {
        dispatch(AssetExportActions.setJobs(jobs.filter(j => j.id !== job.id)));
    }
};

const startPolling = (): AppThunk => (dispatch, getState) => {
    const updateJobs = async () => {
        const { jobs } = getState().assetExport;
        const jobIds = jobs.map(j => j.id);
        const newJobs = await (await Promise.allSettled(jobIds.map(id => Api.getAssetExportJobById(id))))
            .filter(response => response.status === "fulfilled")
            .map(response => (response as PromiseFulfilledResult<AssetExportJob>).value);
        const keepPolling = newJobs.some(job =>
            [AssetExportJobStatus.created, AssetExportJobStatus.in_progress].includes(job.status)
        );

        dispatch(AssetExportActions.setJobs(newJobs));

        if (keepPolling) {
            setTimeout(updateJobs, 1000);
        } else {
            dispatch(AssetExportActions.setIsPolling(false));
        }
    };

    dispatch(AssetExportActions.setIsPolling(true));
    updateJobs();
};

export const AssetExportActions = { ...assetExportSlice.actions, createJob, removeJob, startPolling };

export const AssetExportReducer = assetExportSlice.reducer;
