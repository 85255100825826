import * as React from "react";
import { SVGProps } from "react";

const SvgIconDisabled = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={16}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <circle cx={7.42857} cy={8.00016} r={6.41667} stroke="currentColor" strokeWidth={1.5} />
        <path
            d="M11.9597 3.71094L3.20966 12.4609"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconDisabled;
