/* eslint-disable prettier/prettier */

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeHU = {
    "common.cancel": "Mégsem",
    "common.close": "Bezárás",
    "common.delete": "Törlés",
    "common.done": "Kész",
    "common.loading": "Betöltés...",
    "common.move": "Áthelyezés",
    "common.save": "Mentés",
    "common.search": "Keresés",
    "common.unknown": "Ismeretlen",
    "common.upload": "Feltöltés",
    "components.accountRecoveryPage.emailSent": "Ellenőrizd a megadott e-mail címet",
    "components.accountRecoveryPage.expiredToken": "A link érvényessége lejárt, próbáld újra!",
    "components.accountRecoveryPage.form.email": "E-mail cím",
    "components.accountRecoveryPage.form.password": "Új jelszó",
    "components.accountRecoveryPage.lead": "Add meg az e-mail címet, ahová elküldhetjük a további teendőket.",
    "components.accountRecoveryPage.submit": "Küldés",
    "components.accountRecoveryPage.title": "Elfelejtett jelszó",
    "components.actionBar.clearAll": "Kijelölés törlése",
    "components.actionBar.delete": "Törlés",
    "components.actionBar.download": "Letöltés",
    "components.actionBar.insert": "Beillesztés",
    "components.actionBar.move": "Áthelyezés",
    "components.actionBar.selectAll": "Összes kijelölése",
    "components.actionBar.selected": "{number} kijelölve",
    "components.appBar.closeAppButton": "Alkalmazás bezárása",
    "components.appBar.createFolderButton": "Mappa hozzáadása",
    "components.appBar.menu.changePassword": "Jelszó megváltoztatása",
    "components.appBar.menu.greeting": "Szia, {name}",
    "components.appBar.menu.logOut": "Kijelentkezés",
    "components.appBar.menu.title": "Menü",
    "components.appBar.search": "Keresés a(z) {folderName} mappában...",
    "components.appBar.searchButton": "Keresés",
    "components.appBar.tabs.folders": "Mappák",
    "components.appBar.tabs.search": "Keresés",
    "components.assetDeleteModal.alert": "Ez a művelet nem vonható vissza.",
    "components.assetDeleteModal.deleteSuccess": "A fájl sikeresen törölve!",
    "components.assetDeleteModal.deleteSuccessMultiple": "A fájlok sikeresen törölve lettek!",
    "components.assetDeleteModal.description": "Biztosan törölni szeretnéd a fájlt?",
    "components.assetDeleteModal.descriptionMultiple": "Biztosan törölni szeretnéd a kijelölt fájlokat?",
    "components.assetDeleteModal.title": "Fájl törlése",
    "components.assetExport.close": "Bezárás",
    "components.assetExport.download": "Letöltés",
    "components.assetExport.error": "Hiba történt a letöltés közben",
    "components.assetExport.title": "Fáljok letöltése",
    "components.assetExport.waitingForDownload": "Letöltésre kész...",
    "components.assetMoveModal.back": "Vissza az előző mappába",
    "components.assetMoveModal.moveButtonLabel": "Áthelyezés ide: {folderName}",
    "components.assetMoveModal.moveSuccess": "A fájl(ok) áthelyezése sikeres!",
    "components.assetMoveModal.title": "Fálj(ok) áthelyezése",
    "components.assetMovePopup.back": "Vissza az előző mappába",
    "components.assetMovePopup.moveButtonLabel": "Áthelyezés ide: {folderName}",
    "components.assetMovePopup.success": "Áthelyezés sikeres",
    "components.assetMovePopup.triggerLabel": "Áthelyezés",
    "components.assetPreviewModal.copy": "URL másolása",
    "components.assetPreviewModal.copyError": "Másolás sikertelen",
    "components.assetPreviewModal.copySuccess": "URL a vágólapra másolva",
    "components.assetPreviewModal.createdAt": "Feltöltés dátuma",
    "components.assetPreviewModal.download": "Letöltés",
    "components.assetPreviewModal.fileSize": "Fájlméret",
    "components.assetPreviewModal.height": "Magasság",
    "components.assetPreviewModal.open": "Kép megnyitása",
    "components.assetPreviewModal.title": "Fájl előnézet",
    "components.assetPreviewModal.width": "Szélesség",
    "components.assetRenameModal.form.title": "Név",
    "components.assetRenameModal.title": "Fájl átnevezése",
    "components.assetRenameModal.updateSuccess": "A fájl sikeresen átnevezve!",
    "components.assetRenamePopup.inputLabel": "Új fájlnév",
    "components.assetRenamePopup.submitLabel": "Mentés",
    "components.assetRenamePopup.success": "Sikeres átnevezés",
    "components.assetRenamePopup.triggerLabel": "Átnevezés",
    "components.assets.listTable.columns.fileSize": "Fájlméret",
    "components.assets.listTable.columns.image": "Kép",
    "components.assets.listTable.columns.imageSize": "Képméret",
    "components.assets.listTable.columns.name": "Név",
    "components.assets.listTable.columns.options": "Opciók",
    "components.assets.listTable.columns.select": "Kijelölés",
    "components.assets.listTable.columns.type": "Típus",
    "components.assets.onCopySuccess": "Az URL sikeresen vágólapra másolva",
    "components.assets.options.copyUrl": "URL másolása",
    "components.assets.options.delete": "Törlés",
    "components.assets.options.download": "Letöltés",
    "components.assets.options.move": "Áthelyezés",
    "components.assets.options.rename": "Átnevezés",
    "components.assets.searchInLibrary": "Keresés a médiatárban",
    "components.assets.title.files": "Dokumentumok",
    "components.assets.title.images": "Képek",
    "components.assets.title.videos": "Videók",
    "components.changePasswordModal.confirmPassword": "Jelszó megerősítése",
    "components.changePasswordModal.currentPassword": "Jelenlegi jelszó",
    "components.changePasswordModal.newPassword": "Új jelszó",
    "components.changePasswordModal.success": "Jelszó sikeresen megváltoztatva",
    "components.changePasswordModal.title": "Jelszó megváltoztatása",
    "components.changePasswordModal.validationError": "Hibás jelszó",
    "components.dropzone.placeholder": "Húzd ide a feltölteni kívánt fájlokat, vagy kattints ide a fileok kiválasztásához.",
    "components.emptyfolder.title": "Ez a mappa még üres",
    "components.folderDeleteModal.deleteSuccess": "A mappa sikeresen törölve!",
    "components.folderDeleteModal.description": "Biztosan törölni szeretnéd a mappát? Ez a művelet nem vonható vissza és minden eleme törlésre kerül!",
    "components.folderDeleteModal.title": "Mappa törlése",
    "components.folderModal.createTitle": "Mappa létrehozása",
    "components.folderModal.form.name": "Mappa neve",
    "components.folderModal.messages.createSuccess": "A mappa sikeresen létrehozva!",
    "components.folderModal.messages.updateSuccess": "A mappa sikeresen módosítva!",
    "components.folderModal.updateTitle": "Mappa módosítása",
    "components.folderPage.assetsMaybeFiltered": "A típusszűrő aktív, előfordulhat, hogy emiatt nem jelenik meg találat.",
    "components.folderPage.clearAssetFilter": "Szűrés kikapcsolása",
    "components.folderPage.dropzone": "Fájlok feltöltése ebbe a mappába",
    "components.folderRenameModal.form.title": "Név",
    "components.folderRenameModal.title": "Mappa átnevezése",
    "components.folderRenameModal.updateSuccess": "A mappa sikeresen átnevezve!",
    "components.folders.options.delete": "Törlés",
    "components.folders.options.move": "Áthelyezés",
    "components.folders.options.rename": "Átnevezés",
    "components.folders.title": "Mappák",
    "components.loginPage.form.email": "E-mail cím",
    "components.loginPage.form.password": "Jelszó",
    "components.loginPage.login": "Bejelentkezés",
    "components.loginPage.recoverMyAccount": "Elfelejtettem a jelszavamat",
    "components.loginPage.title": "Welcome to Piklib",
    "components.optionsBar.folders": "Mappák",
    "components.optionsBar.options.createdAtASC": "Létrehozás szerint növekvő",
    "components.optionsBar.options.createdAtDESC": "Létrehozás szerint csökkenő",
    "components.optionsBar.options.fileSizeASC": "Fájlméret szerint növekvő",
    "components.optionsBar.options.fileSizeDESC": "Fájlméret szerint csökkenő",
    "components.optionsBar.options.titleASC": "Név szerint növekvő",
    "components.optionsBar.options.titleDESC": "Név szerint csökkenő",
    "components.optionsBar.options.updatedAtASC": "Módosítás szerint növekvő",
    "components.optionsBar.options.updatedAtDESC": "Módosítás szerint csökkenő",
    "components.optionsBar.refresh": "Frissítés",
    "components.optionsBar.toggleView": "Nézet váltás",
    "components.optionsBar.types.all": "Minden típus",
    "components.optionsBar.types.file": "Dokumentumok",
    "components.optionsBar.types.image": "Képek",
    "components.optionsBar.types.video": "Videó",
    "components.searchPage.noResults": "Nincs találat a következő kifejezésre:",
    "components.searchPage.search": "Keresés...",
    "components.uploadModal.error": "Hiba",
    "components.uploadModal.title": "Fájl feltöltése",
    "components.uploadModal.uploaded": "Feltölve",
    "error.api.ASSET_DIRECTORY_ALREADY_EXISTS": "Ilyen nevű mappa már létezik",
    "error.api.ASSET_DIRECTORY_INVALID_PARENT": "Hibás szülőmappa",
    "error.api.ASSET_DIRECTORY_NOT_EMPTY": "A mappa nem üres",
    "error.api.ASSET_DIRECTORY_NOT_FOUND": "A mappa nem található",
    "error.api.ASSET_DIRECTORY_TOO_DEEP": "Ezen a szinten nem hozható létre több mappa",
    "error.api.ASSET_EXPORT_JOB_IN_INVALID_STATUS": "A letöltési feladat státusza érvénytelen",
    "error.api.ASSET_EXPORT_JOB_NOT_FOUND": "A letöltési feladat nem található",
    "error.api.ASSET_NOT_FOUND": "A fájl nem található",
    "error.api.EMAIL_SERVICE_ERROR": "Hiba az email küldés során",
    "error.api.FILE_TOO_BIG": "A fájl túl nagy",
    "error.api.INTERNAL_SERVER_ERROR": "Ismeretlen hiba",
    "error.api.INVALID_EMBED_CONFIG": "A megadott beágyazási konfiguráció hibás",
    "error.api.INVALID_RESPONSE": "Ismeretlen hiba",
    "error.api.NETWORK_ERROR": "Hálózati hiba",
    "error.api.PROJECT_ID_MISSING_OR_NOT_ENOUGH_PERMISSION": "Hiányzó projekt vagy elégtelen jogosultság",
    "error.api.PROJECT_NAMESPACE_IS_MISSING": "Nem található projekt",
    "error.api.RECOVERY_TOKEN_INVALID_OR_EXPIRED": "Lejárt token",
    "error.api.REQUEST_TIMEOUT": "A szolgáltatás nem válaszolt időben, próbálkozzon újra",
    "error.api.STORAGE_SIZE_LIMIT_EXCEEDED": "A tárhely megtelt",
    "error.api.UNAUTHENTICATED": "Nincs bejelentkezve",
    "error.api.UNAUTHORIZED": "Nincs bejelentkezve",
    "error.api.UNKNOWN": "Ismeretlen hiba",
    "error.api.VALIDATION": "Validációs hiba",
    "error.api.WEAK_PASSWORD": "A jelszó minimális hossza 8 karakter, kisbetűt, nagybetűt és számot is tartalmaznia kell",
    "error.auth.description": "A médiatár nem használható, míg nem állítasz be azonosításhoz szükséges kulcsot.",
    "error.auth.login": "Hibás felhasználónév vagy jelszó",
    "error.auth.loginWithSignature": "Hibás bejelentkezési adatok",
    "error.auth.title": "Nem adtál meg azonosításhoz szükséges adatokat",
    "error.validation.invalidConfirmPassword": "Nem egyezik meg az új jelszóval",
    "error.validation.invalidEmail": "Érvénytelen email cím",
    "error.validation.invalidPassword": "A jelszó minimális hossza 8 karakter, kisbetűt, nagybetűt és számot is tartalmaznia kell",
    "error.validation.required": "A mező kitöltése kötelező",
    "warning.folderNotDeletable": "Csak üres mappát lehet törölni"
};
