import * as React from "react";
import { SVGProps } from "react";

const SvgIconFullScreenExpandResize = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M10.1429 2.43307L13.6593 2.43307L13.6593 5.96029"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M13.0083 3.00879L9.66711 6.3501" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        <path
            d="M13.5809 9.81015L13.5806 13.3264L10.0532 13.3261"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M13.0051 12.6755L9.66712 9.66748" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        <path
            d="M5.94137 13.3413L2.42495 13.3413L2.42495 9.81412"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.07739 12.6074L6.3337 9.66683" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        <path
            d="M2.41934 5.94376L2.41967 2.42747L5.94703 2.4278"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.15332 3.08008L6.40703 6.3335" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
);

export default SvgIconFullScreenExpandResize;
