export class Paths {
    public static readonly Home = "/";
    public static readonly Folder = (id: string): string => {
        return `/folder/${id}`;
    };
    public static readonly Search = "/search";
    public static readonly LoginAuthToken: string = "/loginAuthToken";
    public static readonly Login = "/login";
    public static readonly InitiateRecovery = "/recovery";
    public static readonly Recovery = "/recovery/:emailWithToken";
    public static readonly Sandbox = "/sandbox";
    public static readonly EmbeddedStartPage = "/embedded";
}

export class AppPath {
    public static readonly Home: string = Paths.Home;
    public static readonly Folder: string = Paths.Folder(":id");
    public static readonly Search: string = Paths.Search;
    public static readonly LoginAuthToken: string = Paths.LoginAuthToken;
    public static readonly Login: string = Paths.Login;
    public static readonly InitiateRecovery: string = Paths.InitiateRecovery;
    public static readonly Recovery: string = Paths.Recovery;
    public static readonly Sandbox: string = Paths.Sandbox;
    public static readonly EmbeddedStartPage: string = Paths.EmbeddedStartPage;
}
