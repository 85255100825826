import styled from "styled-components";

const SSkeletonPulse = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
    background: ${props => props.theme.skeleton.background};
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
    @keyframes pulse {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
`;

export const SSkeletonLine = styled(SSkeletonPulse)<{
    width?: string;
    height?: string;
}>`
    width: ${props => props.width || "20px"};
    height: ${props => props.height || "16px"};
    border-radius: 5px;
`;
