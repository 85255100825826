import SplitPane from "react-split-pane";
import styled from "styled-components";

export const StyledSplitPane = styled(SplitPane)`
    min-height: fit-content;
    height: unset !important;
    overflow: unset !important;
    position: relative !important;

    .Resizer {
        box-sizing: border-box;
        flex: 0 0 auto;
        width: 5px;
        margin-right: -5px;
        min-height: 100vh;
        background-clip: padding-box;
        opacity: 0;
        z-index: 1;
        cursor: col-resize;
    }

    .Pane1,
    .Pane2 {
        min-height: 100%;
    }
`;
