import * as React from "react";
import { SVGProps } from "react";

const SvgFileXlsx = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={65}
        height={85}
        viewBox="0 0 65 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <rect width={65} height={85} rx={10} fill="#00CC67" />
        <path
            d="M21.6338 65L19.9238 62.42L18.4088 65H16.1588L18.8738 60.83L16.1288 56.69H18.4988L20.1938 59.255L21.7238 56.69H23.9738L21.2438 60.83L24.0038 65H21.6338ZM27.9944 53.9V65H25.8944V53.9H27.9944ZM33.9992 65.135C33.3192 65.135 32.7092 65.015 32.1692 64.775C31.6292 64.525 31.1992 64.19 30.8792 63.77C30.5692 63.35 30.3992 62.885 30.3692 62.375H32.4842C32.5242 62.695 32.6792 62.96 32.9492 63.17C33.2292 63.38 33.5742 63.485 33.9842 63.485C34.3842 63.485 34.6942 63.405 34.9142 63.245C35.1442 63.085 35.2592 62.88 35.2592 62.63C35.2592 62.36 35.1192 62.16 34.8392 62.03C34.5692 61.89 34.1342 61.74 33.5342 61.58C32.9142 61.43 32.4042 61.275 32.0042 61.115C31.6142 60.955 31.2742 60.71 30.9842 60.38C30.7042 60.05 30.5642 59.605 30.5642 59.045C30.5642 58.585 30.6942 58.165 30.9542 57.785C31.2242 57.405 31.6042 57.105 32.0942 56.885C32.5942 56.665 33.1792 56.555 33.8492 56.555C34.8392 56.555 35.6292 56.805 36.2192 57.305C36.8092 57.795 37.1342 58.46 37.1942 59.3H35.1842C35.1542 58.97 35.0142 58.71 34.7642 58.52C34.5242 58.32 34.1992 58.22 33.7892 58.22C33.4092 58.22 33.1142 58.29 32.9042 58.43C32.7042 58.57 32.6042 58.765 32.6042 59.015C32.6042 59.295 32.7442 59.51 33.0242 59.66C33.3042 59.8 33.7392 59.945 34.3292 60.095C34.9292 60.245 35.4242 60.4 35.8142 60.56C36.2042 60.72 36.5392 60.97 36.8192 61.31C37.1092 61.64 37.2592 62.08 37.2692 62.63C37.2692 63.11 37.1342 63.54 36.8642 63.92C36.6042 64.3 36.2242 64.6 35.7242 64.82C35.2342 65.03 34.6592 65.135 33.9992 65.135ZM46.013 65L43.748 61.475L41.693 65H39.323L42.623 59.72L39.278 54.53H41.693L43.958 58.04L45.998 54.53H48.368L45.083 59.795L48.428 65H46.013Z"
            fill="white"
        />
        <path d="M51 41H14V14" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 36H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28 36H32" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37 36H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47 36H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M19 32H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M28 32H32" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M37 32H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.8} d="M47 32H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M19 28H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M37 28H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.6} d="M47 28H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M19 24H23" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M37 24H41" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.4} d="M47 24H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path opacity={0.2} d="M47 20H51" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SvgFileXlsx;
