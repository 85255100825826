import { Flex } from "@components/Flex";
import SvgIconCheck from "@components/svg/IconCheck";
import { showForSr } from "@theme/GlobalStyle";
import React from "react";
import styled from "styled-components";

interface CheckboxComponentProps {
    label: string;
    checked?: boolean;
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    onChange?: () => void;
}

export const CheckboxComponent = (props: CheckboxComponentProps) => {
    return (
        <StyledCheckbox>
            <CheckboxLabel>{props.label}</CheckboxLabel>
            <input
                type="checkbox"
                onChange={props.onChange}
                onClick={props.onClick}
                checked={props.checked}
                {...props}
            />
            <Checkmark>
                <SvgIconCheck width={14} height={14} />
            </Checkmark>
        </StyledCheckbox>
    );
};

export const Checkmark = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.checkbox.checkmark.color};
    background: ${props => props.theme.checkbox.background};
    border: 2px solid ${props => props.theme.checkbox.borderColor};
    border-radius: 50%;

    svg {
        display: none;
        margin-top: -1px;
        margin-left: -1px;
    }
`;

export const CheckboxLabel = styled.span`
    ${showForSr}
`;

export const StyledCheckbox = styled.label`
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        cursor: pointer;

        &:checked ~ ${Checkmark} {
            background: ${props => props.theme.checkbox.checked.background};

            svg {
                display: block;
            }
        }
    }
`;
