import * as React from "react";
import { SVGProps } from "react";

const SvgIconFilledFolder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M1 5C1 4.44772 1.44772 4 2 4H9.13008C9.36879 4 9.59962 4.08539 9.78087 4.24074L12.7191 6.75926C12.9004 6.91461 13.1312 7 13.3699 7H22C22.5523 7 23 7.44772 23 8V20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20V5Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconFilledFolder;
