import * as React from "react";
import { SVGProps } from "react";

const SvgIconCircleActionsAlertInfo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={25}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <g opacity={0.5}>
            <circle cx={12} cy={12.5} r={11} stroke="currentColor" strokeWidth={2} />
            <path d="M12 7.5V12.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16.5V17" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>
);

export default SvgIconCircleActionsAlertInfo;
