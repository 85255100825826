import { AssetExportJob, AssetExportJobStatus } from "@api/graphql/types";
import { LoctoolMessage, useLoctool } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { ModalList } from "@components/Modal";
import { ProgressBar } from "@components/ProgressBar";
import SvgIconArrowChevronDown from "@components/svg/IconArrowChevronDown";
import SvgIconDownload from "@components/svg/IconDownload";
import { Text } from "@components/Text";
import { AppPath } from "@pages/paths";
import { AssetExportActions } from "@redux/assetExportSlice";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { Color, zIndex } from "@theme/Theme";
import { download } from "@utils/Helpers";
import React, { useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";

export const AssetExportList = () => {
    const { jobs } = useAppSelector(state => state.assetExport);
    const isSearch = !!useRouteMatch(AppPath.Search)?.isExact;
    const { selectedAssets: selectedSearchAssets } = useAppSelector(state => state.search);
    const { selectedAssets: selectedFolderAssets } = useAppSelector(state => state.assets);
    const isActionBarVisible = useMemo(
        () => (isSearch ? selectedSearchAssets.length > 0 : selectedFolderAssets.length > 0),
        [isSearch, selectedSearchAssets.length, selectedFolderAssets.length]
    );

    return (
        <JobsContainer bottom={isActionBarVisible ? 100 : 20}>
            {jobs.slice(0, 5).map(job => (
                <AssetExportItem job={job} key={job.id} />
            ))}
        </JobsContainer>
    );
};

const AssetExportItem = ({ job }: { job: AssetExportJob }) => {
    const [isAssetListOpen, setIsAssetListOpen] = useState(false);
    const dispatch = useAppDispatch();
    const Loctool = useLoctool();

    const downloadExportedAssets = async (job: AssetExportJob) => {
        download(job.archiveURL!);
        dispatch(AssetExportActions.removeJob(job));
    };

    const renderFileList = () => (
        <ModalList>
            {job.assets.map(asset => (
                <Element
                    as="li"
                    key={asset.id}
                    $style={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: Color.sky500,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {asset.title}
                </Element>
            ))}
        </ModalList>
    );

    return (
        <JobCard key={job.id}>
            <Text as="h2" variant="linkMedium" $style={{ position: "relative", marginBottom: 15, textAlign: "center" }}>
                <LoctoolMessage id="components.assetExport.title" />
                <Button.Icon
                    label="Részletek"
                    icon={<SvgIconArrowChevronDown />}
                    onClick={() => setIsAssetListOpen(!isAssetListOpen)}
                    $style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: 24,
                        height: 24,
                        transform: isAssetListOpen ? "rotate(180deg)" : "none",
                    }}
                />
            </Text>

            {isAssetListOpen && renderFileList()}

            {[AssetExportJobStatus.created, AssetExportJobStatus.in_progress].includes(job.status) && (
                <Element $style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
                    <Text as="span" variant="linkXSmall" $style={{ minWidth: 50, marginRight: 10 }}>
                        {job.progressPercent}%
                    </Text>
                    <ProgressBar progressPercent={job.progressPercent} />
                </Element>
            )}
            {job.status === AssetExportJobStatus.error && (
                <>
                    <Text
                        as="p"
                        variant="linkXSmall"
                        $color={Color.chili500}
                        $style={{ marginTop: 15, marginBottom: 15, textAlign: "center" }}
                    >
                        <LoctoolMessage id="components.assetExport.error" />
                    </Text>
                    <Button.Secondary
                        label={Loctool.formatMessage({ id: "components.assetExport.close" })}
                        fullWidth
                        onClick={() => dispatch(AssetExportActions.removeJob(job))}
                    />
                </>
            )}
            {job.status === AssetExportJobStatus.waiting_for_download && (
                <>
                    <Text
                        as="p"
                        variant="linkXSmall"
                        $color={Color.lime500}
                        $style={{ marginTop: 15, marginBottom: 15, textAlign: "center" }}
                    >
                        <LoctoolMessage id="components.assetExport.waitingForDownload" />
                    </Text>
                    <Button.Primary
                        label={Loctool.formatMessage({ id: "components.assetExport.download" })}
                        startIcon={<SvgIconDownload width={16} height={16} />}
                        fullWidth
                        onClick={() => downloadExportedAssets(job)}
                        $style={{ marginBottom: 15 }}
                    />
                    <Button.Secondary
                        label={Loctool.formatMessage({ id: "common.cancel" })}
                        fullWidth
                        onClick={() => dispatch(AssetExportActions.removeJob(job))}
                    />
                </>
            )}
        </JobCard>
    );
};

const JobsContainer = styled(Flex.Container).attrs({
    $flexDirection: "column",
})<{ bottom: number }>`
    position: fixed;
    right: 20px;
    bottom: ${({ bottom }) => `${bottom}px`};
    width: 100%;
    max-width: 350px;
    z-index: ${zIndex.assetExport};
`;

const JobCard = styled.div`
    padding: 32px;
    background-color: ${props => props.theme.assetExport.background};
    border-radius: 24px;
    box-shadow: ${props => props.theme.assetExport.boxShadow};
`;
