import Cookies from "universal-cookie";

class Cookie {
    private static readonly keys = {
        locale: "locale",
        authToken: "authToken",
        refreshToken: "refreshToken",
        projectId: "projectId",
        viewOptions: "viewOptions",
    };

    private static instance: Cookies = new Cookies();

    public static getLocale(): string | null {
        return Cookie.instance.get(Cookie.keys.locale) || null;
    }

    public static setLocale(locale: string): void {
        Cookie.instance.set(Cookie.keys.locale, locale, { path: "/", sameSite: "lax" });
    }

    public static getAuthToken(): string | null {
        return Cookie.instance.get(Cookie.keys.authToken) || null;
    }

    public static setAuthToken(authToken: string | null): void {
        Cookie.instance.set(Cookie.keys.authToken, authToken, { path: "/", sameSite: "lax" });
    }

    public static clearAuthToken(): void {
        Cookie.instance.remove(Cookie.keys.authToken);
    }

    public static getRefreshToken(): string | null {
        return Cookie.instance.get(Cookie.keys.refreshToken) || null;
    }

    public static setRefreshToken(refreshToken: string | null): void {
        Cookie.instance.set(Cookie.keys.refreshToken, refreshToken, { path: "/", sameSite: "lax" });
    }

    public static clearRefreshToken(): void {
        Cookie.instance.remove(Cookie.keys.refreshToken);
    }

    public static getProjectId(): string | null {
        return Cookie.instance.get(Cookie.keys.projectId) || null;
    }

    public static setProjectId(projectId: string | null): void {
        Cookie.instance.set(Cookie.keys.projectId, projectId, { path: "/", sameSite: "lax" });
    }

    public static clearProjectId(): void {
        Cookie.instance.remove(Cookie.keys.projectId);
    }

    public static getViewOptions() {
        return Cookie.instance.get(Cookie.keys.viewOptions) ?? null;
    }

    public static setViewOptions(options: object) {
        Cookie.instance.set(Cookie.keys.viewOptions, options, { path: "/", sameSite: "lax" });
    }
}

export { Cookie };
