import { bp, Theme } from "@theme/Theme";
import { SortObjKeysAlphabetically } from "@utils/SortObjKeysAlphabetically";
import styled, { css, CSSObject } from "styled-components";

type TextProps = {
    variant: keyof typeof Theme.text;
    lineClamp?: number;
    $color?: string;
    $style?: CSSObject;
    $styleSmall?: CSSObject;
    $styleMedium?: CSSObject;
    $styleLarge?: CSSObject;
    $styleXLarge?: CSSObject;
    $styleXXLarge?: CSSObject;
};

export const Text = styled.div<TextProps>`
    color: ${props => props.$color || props.theme.text[props.variant].color || undefined};
    font-family: ${props => props.theme.text[props.variant].fontFamily || undefined};
    font-size: ${props => `${props.theme.text[props.variant].fontSize}px` || undefined};
    font-weight: ${props => props.theme.text[props.variant].fontWeight || undefined};
    line-height: ${props => `${props.theme.text[props.variant].lineHeight}px` || undefined};
    letter-spacing: ${props => `${props.theme.text[props.variant].letterSpacing}px` || undefined};

    ${({ lineClamp }) =>
        lineClamp &&
        `
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    `}

    ${({ lineClamp }) =>
        lineClamp === 1 &&
        `
        white-space: break-spaces;
        word-wrap: break-word;
    `}

    ${({ $style }) => ($style && Object.keys($style).length !== 0 ? SortObjKeysAlphabetically($style) : {})}

    ${({ $styleSmall }) =>
        $styleSmall && Object.keys($styleSmall).length !== 0
            ? ` ${bp.small} { ${css(SortObjKeysAlphabetically($styleSmall)).join("")} } `
            : {}}

    ${({ $styleMedium }) =>
        $styleMedium && Object.keys($styleMedium).length !== 0
            ? ` ${bp.medium} { ${css(SortObjKeysAlphabetically($styleMedium)).join("")} } `
            : {}}

    ${({ $styleLarge }) =>
        $styleLarge && Object.keys($styleLarge).length !== 0
            ? ` ${bp.large} { ${css(SortObjKeysAlphabetically($styleLarge)).join("")} } `
            : {}}

    ${({ $styleXLarge }) =>
        $styleXLarge && Object.keys($styleXLarge).length !== 0
            ? ` ${bp.xlarge} { ${css(SortObjKeysAlphabetically($styleXLarge)).join("")} } `
            : {}}

    ${({ $styleXXLarge }) =>
        $styleXXLarge && Object.keys($styleXXLarge).length !== 0
            ? ` ${bp.xxlarge} { ${css(SortObjKeysAlphabetically($styleXXLarge)).join("")} } `
            : {}}
`;
