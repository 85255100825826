import React from "react";
import { Route, Switch } from "react-router-dom";
import { AlertContainer } from "@components/Alert/AlertContainer";
import { PrivateRoute } from "@components/Routes/PrivateRoute";
import { EmbeddedStartPage } from "@pages/EmbeddedStartPage";
import { FolderPage } from "@pages/folder/FolderPage";
import { LoginAuthTokenPage } from "@pages/login/LoginAuthTokenPage";
import { LoginPage } from "@pages/login/LoginPage";
import { AppPath } from "@pages/paths";
import { SearchPage } from "@pages/search/SearchPage";
import { AccountRecoveryPage } from "@pages/accountRecovery/AccountRecoveryPage";
import { RootState } from "@redux/store";
import { connect, ConnectedProps } from "react-redux";
import { AuthActions, isLoggedIn } from "@redux/authSlice";
import { AssetExportActions } from "@redux/assetExportSlice";
import { AssetExportList } from "@components/AssetExport";
import { ChangePasswordModal } from "@components/ChangePasswordModal";
import { Log } from "@utils/Log";

Log.init();

class AppComponent extends React.Component<ReduxProps> {
    public componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.fetchAccount().then(() => {
                this.setLoaded();
            });
        } else {
            this.setLoaded();
        }
    }

    private setLoaded() {
        const loadStart = window.plLoadStart ?? Date.now();
        const timeout = Math.min(Math.max(3000 - (Date.now() - loadStart), 0), 3000);
        setTimeout(() => {
            const loadingEl = document.querySelector("#loading") as HTMLDivElement;
            const rootEl = document.querySelector("#root") as HTMLDivElement;
            loadingEl?.parentElement?.removeChild(loadingEl);
            rootEl.style.display = "block";
        }, timeout);
    }

    public render() {
        return (
            <>
                <Switch>
                    <PrivateRoute exact={true} path={AppPath.Home} component={FolderPage} />
                    <Route path={AppPath.LoginAuthToken} component={LoginAuthTokenPage} />
                    <Route path={AppPath.EmbeddedStartPage} component={EmbeddedStartPage} />
                    <Route path={AppPath.Login} component={LoginPage} />
                    <Route path={AppPath.Recovery} component={AccountRecoveryPage} />
                    <Route path={AppPath.InitiateRecovery} component={AccountRecoveryPage} />
                    <PrivateRoute exact={true} path={AppPath.Folder} component={FolderPage} />
                    <PrivateRoute exact={true} path={AppPath.Search} component={SearchPage} />
                </Switch>

                <AlertContainer />

                <AssetExportList />

                <ChangePasswordModal />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = {
    fetchAccount: AuthActions.fetchAccount,
    setJobs: AssetExportActions.setJobs,
    startPolling: AssetExportActions.startPolling,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const App = connector(AppComponent);
