import * as React from "react";
import { SVGProps } from "react";

const SvgIconArrowChevronDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M11.0403 5.83301L6.97849 9.89486L2.91664 5.83301"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconArrowChevronDown;
