import * as React from "react";
import { SVGProps } from "react";

const SvgIconArrowChevronBack = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={16}
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        focusable={false}
        {...props}
    >
        <path
            d="M9.35205 3.625L5.00007 7.97698L9.35205 12.329"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconArrowChevronBack;
