import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import { batch } from "react-redux";
import { Asset, AssetType, SearchListOptionsInput, SearchListSortField, SortOrder } from "@api/graphql/types";
import { Constants } from "@utils/Constants";
import { Api } from "@api/Api";
import { AppThunk, RootState } from "./store";
import { AppStateActions } from "./appStateSlice";
import { AlertActions } from "./alertSlice";

export interface SearchState {
    assets: Asset[];
    count: number;
    typeFilter: AssetType | null;
    maximumSelectableAsset?: number;
    assetListDisplay: boolean;
    selectedAssets: Asset[];
    assetOptions: SearchListOptionsInput;
}

const initialState: SearchState = {
    assets: [],
    count: 0,
    typeFilter: null,
    maximumSelectableAsset: 0,
    assetListDisplay: false,
    selectedAssets: [],
    assetOptions: {
        sortField: SearchListSortField.createdAt,
        control: {
            sortOrder: SortOrder.DESC,
            search: null,
            limit: Constants.assetPageSize,
            offset: 0,
        },
    },
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setAssets(state, { payload: assets }: PayloadAction<Asset[]>) {
            state.assets = assets;
        },
        setCount(state, { payload: count }: PayloadAction<number>) {
            state.count = count;
        },
        toggleSelectedAsset(state, { payload: asset }: PayloadAction<Asset>) {
            const { selectedAssets, maximumSelectableAsset = 0 } = state;

            if (maximumSelectableAsset > 1 && selectedAssets.length === maximumSelectableAsset) {
                return;
            }
            if (maximumSelectableAsset === 1) {
                state.selectedAssets = [asset];
            } else if (selectedAssets.find(({ id }) => id === asset.id)) {
                state.selectedAssets = selectedAssets.filter(({ id }) => id !== asset.id);
            } else {
                state.selectedAssets.push(asset);
            }
        },
        extendSelection(state, { payload: asset }: PayloadAction<Asset>) {
            const { assets, selectedAssets, typeFilter } = state;
            const selectedAssetIds = selectedAssets.map(a => a.id);
            const fromAsset = assets.find(a => a.id === asset.id || selectedAssetIds.includes(a.id));

            if (!fromAsset) {
                return;
            }

            const from = assets.indexOf(fromAsset);
            const to = assets.findIndex(a =>
                fromAsset.id === asset.id ? selectedAssetIds.includes(a.id) : a.id === asset.id
            );

            if (to) {
                const extendedSelection = assets.filter((a, index) => {
                    if (selectedAssetIds.includes(a.id)) {
                        return false;
                    }
                    if (typeFilter && a.assetType !== typeFilter) {
                        return false;
                    }
                    return index >= from && index <= to;
                });
                state.selectedAssets = [...selectedAssets, ...extendedSelection];
            } else if (!selectedAssetIds.includes(fromAsset.id)) {
                state.selectedAssets.push(fromAsset);
            }
        },
        selectAllAssets(state) {
            if (state.typeFilter) {
                state.selectedAssets = [...state.assets.filter(a => a.assetType === state.typeFilter)];
            } else {
                state.selectedAssets = [...state.assets];
            }
        },
        clearSelectedAssets(state) {
            state.selectedAssets = [];
        },
        setAssetSort(state, { payload }: PayloadAction<{ field: SearchListSortField; direction: SortOrder }>) {
            state.assetOptions.sortField = payload.field;
            state.assetOptions.control = {
                ...state.assetOptions.control,
                sortOrder: payload.direction,
            };
        },
        setAssetSearch(state, { payload: search }: PayloadAction<string>) {
            state.assetOptions.control = {
                ...state.assetOptions.control,
                search,
            };
        },
        setAssetOffset(state, { payload: offset }: PayloadAction<number>) {
            state.assetOptions.control = {
                ...state.assetOptions.control,
                offset,
            };
        },
        clearAssetOptions(state) {
            state.assetOptions = initialState.assetOptions;
        },
        toggleAssetListDisplay(state, { payload: assetListDisplay }: PayloadAction<boolean>) {
            state.assetListDisplay = assetListDisplay;
        },
        setMaximumSelectableAsset(state, { payload: amount }: PayloadAction<number>) {
            state.maximumSelectableAsset = amount;
        },
    },
});

const clearSearch = (): AppThunk => async dispatch => {
    batch(() => {
        dispatch(SearchActions.clearAssetOptions());
        dispatch(SearchActions.clearSelectedAssets());
        dispatch(SearchActions.setAssetSearch(""));
        dispatch(SearchActions.setAssets([]));
        dispatch(SearchActions.setCount(0));
    });
};

const searchInitialAssets = ({ search = "" } = {}): AppThunk => async (dispatch, getState: () => RootState) => {
    batch(() => {
        dispatch(SearchActions.clearAssetOptions());
        dispatch(SearchActions.clearSelectedAssets());
        dispatch(SearchActions.setAssetSearch(search));
        dispatch(AppStateActions.toggleLoadingBar(true));
    });

    try {
        const { assetOptions } = getState().search;
        const response = await Api.getAssets(null, assetOptions, true);

        batch(() => {
            dispatch(SearchActions.setAssets(response.result));
            dispatch(SearchActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const refreshSearchResults = (): AppThunk => async (dispatch, getState: () => RootState) => {
    const assetOptions = produce(getState().search.assetOptions, draft => {
        draft.control!.offset = 0;
        draft.control!.limit = (getState().search.assetOptions?.control?.offset ?? 0) + Constants.assetPageSize;
    });

    dispatch(AppStateActions.toggleLoadingBar(true));

    try {
        const response = await Api.getAssets(null, assetOptions, true);

        batch(() => {
            dispatch(SearchActions.setAssets(response.result));
            dispatch(SearchActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

const fetchMoreSearchResults = (): AppThunk => async (dispatch, getState: () => RootState) => {
    const { assets, count, assetOptions } = getState().search;

    if (count === assets.length) {
        return;
    }

    dispatch(AppStateActions.toggleLoadingBar(true));

    try {
        const response = await Api.getAssets(null, assetOptions, true);

        batch(() => {
            dispatch(SearchActions.setAssets([...assets, ...response.result]));
            dispatch(SearchActions.setCount(response.count));
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AppStateActions.toggleLoadingBar(false));
    }
};

export const SearchActions = {
    ...searchSlice.actions,
    searchInitialAssets,
    refreshSearchResults,
    fetchMoreSearchResults,
    clearSearch,
};

export const SearchReducer = searchSlice.reducer;
